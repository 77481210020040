import React, {ReactNode} from 'react';
import Collapse from "./Collapse";
import DateVisual from "./DateVisual";
import faq from "../types/faq";
import faqCategory from "../types/faqCategory";
import person from "../types/Person";
import classNames from "classnames";
import Loader from "./Loader";

const accordionDefaultMode = 'default';
type accordionMode = typeof accordionDefaultMode | 'slim' | 'faq' | 'news' | 'team';

type accordionProps = {
  tagline?: string,
  title: ReactNode
  faq?: faq
  person?: person
  mode?: accordionMode
  loading?: boolean
  onOpen?: () => void
  isOpen?: boolean
  wrapperRef?: React.Ref<HTMLDivElement>
  children?: ReactNode
}

type accordionState = {
  open: boolean,
}

class Accordion extends React.Component<accordionProps, accordionState> {
  constructor(props: accordionProps) {
    super(props);

    this.state = {
      open: !!props.isOpen
    };

    if (props.isOpen && props.onOpen)
      props.onOpen();

    this.onToggleAccordion = this.onToggleAccordion.bind(this);
  }

  open() {
    if (this.props.onOpen)
      this.props.onOpen();
    this.setState({
      open: true
    });
  }

  onToggleAccordion(e: React.MouseEvent) {
    //@ts-ignore
    if (e.target.tagName === 'INPUT' || (e.target.tagName === 'LABEL' && e.target.getAttribute('for')))
      return;
    e.preventDefault();
    if (!this.state.open)
      this.open();
    else
      this.setState({open: false});
  }

  render() {
    let classes = classNames({
      'accordion': true,
      ['accordion--' + this.props.mode]: this.props.mode && this.props.mode !== accordionDefaultMode
    });

    return (
      <div className={classes} role="button" aria-expanded={this.state.open} ref={this.props.wrapperRef}>
        <div className="accordion__head" onClick={this.onToggleAccordion}>
          {this.props.tagline &&
            <div className="accordion__tagline">
              <DateVisual utc={this.props.tagline}/>
            </div>
          }
          <div className="accordion__title">
            {this.props.title}
          </div>
          {this.props.faq?.categories &&
            this.props.faq?.categories.map((category: faqCategory, i_) => {
              return (
                <div key={i_} className="accordion__category">
                  <span>{category.title}</span>
                  {
                    // @ts-ignore
                    i_ + 1 < this.props.faq?.categories?.length &&
                    <span>,</span>
                  }
                </div>
              )
            })
          }
          <div className="accordion__toggle accordion__toggle--plus"></div>
          <div className="accordion__toggle accordion__toggle--minus"></div>
        </div>
        <Collapse collapsed={!this.state.open} animationTime={300}>
          <div className="accordion__body">
            {this.props.children}
          </div>
        </Collapse>
        {this.props.loading &&
          <Loader wrapperClassName="accordion__loader"/>
        }
      </div>
    );
  }
}

export default React.forwardRef<HTMLDivElement, accordionProps>((props, ref: React.Ref<HTMLDivElement>) => (
  <Accordion wrapperRef={ref} {...props}/>
));
