import React from "react";
import CloseIcon from "./CloseIcon";

type createUserProps = {
  showPopup: boolean,
  onClickCloseIcon: () => void,
  id?: string
}

class PopUp extends React.Component<createUserProps, {}> {
  render() {
    return (
      this.props.showPopup && (
        <>
          <div className="bg__overlay"></div>
          <div id={this.props.id} className="popup__container">
            <div className="popup__wrapper white-bg">
              <div onClick={this.props.onClickCloseIcon} className="popup__icon--close">
                <CloseIcon/>
              </div>
              <div className="popup__scroll">
                {this.props.children}
              </div>
            </div>
          </div>
        </>
      )
    )
  }
}

export default PopUp;
