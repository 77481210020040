import {addressNameProps} from "../types/address";

class AddressService {
  static getAddressFullNameString(address: addressNameProps): string {
    if(!address){
      return '';
    }

    let addressString = address.name;

    if (address.additionalOne) {
      if (addressString)
        addressString += " ";
      addressString += address.additionalOne;
    }

    if (address.additionalTwo) {
      if (addressString)
        addressString += " ";
      addressString += address.additionalTwo;
    }

    if (address.street) {
      if (addressString)
        addressString += " - ";
      addressString += address.street;
    }

    if (address.zip) {
      if (addressString)
        addressString += " - ";
      addressString += address.zip;
    }

    if (address.city) {
      if (addressString) {
        if (address.zip)
          addressString += " ";
        else
          addressString += " - ";
      }
      addressString += address.city;
    }

    if (address.country) {
      if (addressString)
        addressString += " - ";
      addressString += address.country;
    }

    return addressString;
  }
}

export default AddressService;