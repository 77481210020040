import React, {ChangeEvent} from 'react';
import InputText from "../InputText";
import Button from "../Button";
import HtmlSelect from "../HtmlSelect";

type groupFormsState = {
  dropdownValue: string,
  id: any
};

class GroupForms extends React.Component<{}, groupFormsState> {
  constructor(props: {}) {
    super(props);

    this.onChangeInput=this.onChangeInput.bind(this);
    this.onChangeSelect=this.onChangeSelect.bind(this);

    this.state = {
      id: 'user',
      dropdownValue: "1"
    };
  }

  onChangeInput(e: ChangeEvent<HTMLInputElement>) {
    this.setState({
      id: e.target.value
    })
  }
  onChangeSelect(e: ChangeEvent<HTMLSelectElement>) {
    this.setState({
      dropdownValue: e.target.value
    })
  }

  render() {
    return (
      <div>
        <h1>Form elements</h1>
        <InputText id="email1" placeholder={'input'} onChange={this.onChangeInput} required />
        <InputText id="email2" placeholder={'E-Mail Adress *'} onChange={this.onChangeInput} required mode={'error'} value="input false" />
        <InputText placeholder={'search (disabled)'} onChange={this.onChangeInput} disabled />
        <InputText id="search" placeholder={'search'} onChange={this.onChangeInput} mode={'highlighted'} />

        <div className="form-group">
          <HtmlSelect onChange={this.onChangeSelect}>
            <option value="0">Sort by: Buy date</option>
            <option value="1">Sort by: Title length</option>
            <option value="2">Sort by: Random</option>
          </HtmlSelect>
        </div>
        <div className="form-group">
          <Button mode="invert">Support request</Button>
        </div>
        <div className="form-group">
          <Button>show details</Button>
        </div>
        <div className="form-group">
          <input id="check1" className="input__checkbox" type="checkbox"/>
          <label htmlFor="check1">Look at this checkbox</label>
        </div>
        <div className="form-group">
          <input id="check2" defaultChecked={true} className="input__checkbox" type="checkbox"/>
          <label htmlFor="check2">This checkbox' amazing</label>
        </div>
        <div className="form-group">
          <input id="radio1" className="input__radio" type="radio"/>
          <label htmlFor="radio1">Look at this radiobox</label>
        </div>
        <div className="form-group">
          <input id="radio1" className="input__radio" type="radio"/>
          <label htmlFor="radio1">This radiobox' amazing</label>
        </div>
      </div>
    )
  }
}

export default GroupForms;
