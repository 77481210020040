import React from "react";
import AnimateHeight from "react-animate-height";
import classNames from "classnames";

type collapseProps = {
  collapsed: boolean
  className?: string,
  animationTime?: number
}

class Collapse extends React.Component<collapseProps, {}> {
  render() {
    let classes=classNames({
      'show': !this.props.collapsed,
      'AnimateHeight': true
    });

    return (
      <AnimateHeight className={this.props.className} height={this.props.collapsed?0:'auto'} duration={this.props.animationTime}>
        <div className={classes}>
          { this.props.children }
        </div>
      </AnimateHeight>
    )
  }
}

export default Collapse;
