import {tableHeadLabels} from "../../types/table";
import React from "react";

export const COMPANY_LIST_TABLE_HEADERS = {
  companyId: 'PTW Company ID',
  active: 'Company active',
  internalId: 'Internal Company ID',
  name: 'Company Name'
};

export const ADDRESS_TABLE_HEADER_LABELS: tableHeadLabels = [
  <>Company Name</>,
  <>Street</>,
  <>City</>,
  <>Country</>
];