import {components, MenuProps} from 'react-select';
import {OptionType, GroupTypeDefault} from './SelectTypes';
import classNames from "classnames";
import React from "react";

export default function Menu(props: MenuProps<OptionType, true, GroupTypeDefault>) {
  let {children, className, ...selectMenuProps} = props;
  let containerClassName = classNames({
    [className + '']: !!className,
    [selectMenuProps.selectProps.classNamePrefix + '__menu--' + selectMenuProps.menuPlacement]: !!selectMenuProps.menuPlacement
  });

  return (
    <components.Menu className={containerClassName} {...selectMenuProps}>
      <>
        {children}
        {selectMenuProps.selectProps.conformSelectionBtn &&
          selectMenuProps.selectProps.conformSelectionBtn
        }
      </>
    </components.Menu>
  );
}