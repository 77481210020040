import React from "react";
import {Route, Switch} from "react-router";
import BackButton from "../BackButton";
import Tabs from "../Tabs";
import DeviceTabComponents from "./DeviceTabComponents";
import DeviceTabHistory from "./DeviceTabHistory";
import {RouteChildrenProps, match} from "react-router-dom";
import device from "../../types/device";
import NotFound404 from "../ErrorPages/NotFound404";
import DeviceHeader from "./DeviceHeader";
import Button from "../Button";
import TabContent from "../TabContent";
import Loader from "../Loader";
import DeviceTabDownloads from "./DeviceTabDownloads";
import SalesmanContact from "../SalesmanContact";
import PopUp from "../PopUp";
import user from "../../types/user";
import DeviceTabCertificates from "./DeviceTabCertificates";
import ApiContext from "../Context/ApiContext";
import "react-datepicker/dist/react-datepicker.css";
import PageService, {PAGE_PRODUCT_ADRESSES} from "../../services/PageService";
import DeviceService from "../../services/DeviceService";
import AddressService from "../../services/AddressService";

type deviceDetailParams = {
  deviceId?: string
  product?: string
}

interface deviceDetailProps extends RouteChildrenProps {
  match: match<deviceDetailParams>
  user: user | undefined
}

type deviceDetailState = {
  device?: device
  loaded: boolean
  showPopup: boolean
}

class DeviceDetail extends React.Component<deviceDetailProps, deviceDetailState> {
  constructor(props: deviceDetailProps) {
    super(props);

    this.state = {
      device: undefined,
      loaded: false,
      showPopup: false,
    };

    this.onClickCancel = this.onClickCancel.bind(this);
    this.onClickContact = this.onClickContact.bind(this);
    this.getDevice = this.getDevice.bind(this);
  }

  componentDidMount() {
    this.getDevice();
  }

  getDevice() {
    if (this.props.match.params.deviceId) {
      let deviceId = parseInt(this.props.match?.params?.deviceId);
      this.context.getDevice(deviceId).then((device: device) => {
        this.setState({
          device: device,
          loaded: true
        });
        DeviceService.saveCurrentDeviceConfig(device);
      })
    }
  }

  onChangeDate() {
    this.getDevice();
  }

  onClickContact() {
    this.setState({
      showPopup: true,
    });
  }

  onClickCancel() {
    this.setState({
      showPopup: false
    });
  }

  render() {
    const overviewPageConfig = PageService.getCurrentDeviceOverviewPageConfig();

    return (
      <div>
        <Switch>
          <Route path={overviewPageConfig.path + '/:deviceId/:componentId'}>
            <h1>Subproduct Page</h1>
          </Route>
          <Route>
            <PopUp onClickCloseIcon={this.onClickCancel} showPopup={this.state.showPopup}>
              <SalesmanContact user={this.props.user} device={this.state.device} deviceRequest={true} onClickCancel={this.onClickCancel}/>
            </PopUp>
            <div className="container">
              <div className="products-detail">
                {this.state.loaded
                  ? <>
                    {this.state.device
                      ? <>
                        <BackButton to={overviewPageConfig.path}>
                          Back to {overviewPageConfig.title}
                        </BackButton>
                        <div className="contentbox">
                          <div className="productbox">
                            <DeviceHeader device={this.state.device} companyProps={(this.props.user && this.state.device.address && PageService.isAllowedForUser(PAGE_PRODUCT_ADRESSES, this.props.user)) ? {
                              companyId: this.state.device.address.company.companyId,
                              internalId: this.state.device.address.company.internalId,
                              address: AddressService.getAddressFullNameString(this.state.device.address)
                            } : undefined}/>
                            <div className="productbox__body white-bg">
                              <div className="row">
                                <div className="col-12 col-lg-6">
                                  <h1 className={'productbox__title productbox__title' + (this.state.device.software ? '--software' : '--gear')}>{this.state.device?.description}</h1>
                                </div>
                                <div className="col-12 col-lg-6">
                                  <div className="productbox__contact__request">
                                    <Button onClick={this.onClickContact} mode="invert">Support request</Button>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-12 col-lg-6 col-xxl-8">
                                  <p>{this.state.device.longDescription}</p>
                                </div>
                                <div className="col-12 col-lg-6 col-xxl-4 products-detail__graybox">
                                  <div className="products-detail__graybox--inner">
                                    {this.state.device?.deviceId && (
                                      <div className="device__info">
                                        <div className="device__info--label">
                                          Device ID:
                                        </div>
                                        <div>
                                          {this.state.device.deviceId}
                                        </div>
                                      </div>
                                    )}

                                    {this.state.device.resourceNumber && (
                                      <div className="device__info">
                                        <div className="device__info--label">
                                          Article No.:
                                        </div>
                                        <div>
                                          {this.state.device.resourceNumber}
                                        </div>
                                      </div>
                                    )}

                                    {this.state.device.software &&
                                      <>
                                        <br/>

                                        {this.state.device.licenceOwner &&
                                          <div className="device__info">
                                            <div className="device__info--label">
                                              Owner:
                                            </div>
                                            <div>
                                              {this.state.device.licenceOwner}
                                            </div>
                                          </div>
                                        }

                                        {this.state.device.licenceCity &&
                                          <div className="device__info">
                                            <div className="device__info--label">
                                              City/company:
                                            </div>
                                            <div>
                                              {this.state.device.licenceCity}
                                            </div>
                                          </div>
                                        }

                                        {this.state.device.softwareKey && (
                                          <div className="device__info">
                                            <div className="device__info--label">
                                              Software Key:
                                            </div>
                                            <div>
                                              {this.state.device.softwareKey}
                                            </div>
                                          </div>
                                        )}

                                        {this.state.device.softwareVersion && (
                                          <div className="device__info">
                                            <div className="device__info--label">
                                              Software Version:
                                            </div>
                                            <div>
                                              {this.state.device.softwareVersion}
                                            </div>
                                          </div>
                                        )}

                                        {this.state.device.softwareRevision && (
                                          <div className="device__info">
                                            <div className="device__info--label">
                                              CD Revision:
                                            </div>
                                            <div>
                                              {this.state.device.softwareRevision}
                                            </div>
                                          </div>
                                        )}

                                        {this.state.device.serialNumber && (
                                          <div className="device__info">
                                            <div className="device__info--label">
                                              Serial No:
                                            </div>
                                            <div>
                                              {this.state.device.serialNumber}
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    }
                                  </div>
                                </div>
                              </div>
                              <Tabs>
                                {(!this.state.device.software && this.state.device.calibration) &&
                                  <TabContent identifier="calibration" title="Calibration">
                                    <DeviceTabCertificates
                                      onChangeDate={this.onChangeDate.bind(this)}
                                      deviceId={this.state.device.id}
                                      user={this.props.user}
                                    />
                                  </TabContent>
                                }
                                {this.state.device.componentCount > 0 &&
                                  <TabContent identifier="components" title="Components">
                                    <DeviceTabComponents deviceId={this.state.device.id} overviewPageConfig={overviewPageConfig}/>
                                  </TabContent>
                                }
                                {this.state.device.historyCount > 0 &&
                                  <TabContent identifier="history" title="History">
                                    <DeviceTabHistory isSoftware={this.state.device?.software} deviceId={this.state.device.id}/>
                                  </TabContent>
                                }
                                {this.state.device.downloadCount > 0 &&
                                  <TabContent identifier="downloads" title="Documents">
                                    <DeviceTabDownloads deviceId={this.state.device.id}/>
                                  </TabContent>
                                }
                              </Tabs>
                            </div>
                          </div>
                        </div>
                      </>
                      : <NotFound404/>
                    }
                  </>
                  : <Loader mode="detail"/>
                }
              </div>
            </div>
          </Route>
        </Switch>
      </div>
    )
  }
}

DeviceDetail.contextType = ApiContext;

export default DeviceDetail;
