import React from 'react'
import {RouteComponentProps} from 'react-router-dom';
import Login from "../Login";
import {Route} from "react-router-dom";
import templateMode from "../../types/templateMode";
import user from "../../types/user";
import PageService, {
  PAGE_MY_PRODUCTS,
  PAGE_PRODUCT_ADRESSES,
  pageId
} from "../../services/PageService";
import DeviceService from "../../services/DeviceService";
import {AlertManager, withAlert} from "react-alert";
import NotAllowed403 from "../ErrorPages/NotAllowed403";

type userComponent = React.ComponentType<{ user: user }>

export type AuthRouteComponent =
  React.ComponentType<RouteComponentProps<any>>
  | React.ComponentType<any>
  | userComponent;

type AuthRouteProps = {
  isAuth: boolean
  exact?: boolean
  path: string
  component: AuthRouteComponent
  setMode: (mode: templateMode) => void
  user: user | undefined
  pageId: pageId
  mode?: templateMode
  salesmanContactId?: number
  alert: AlertManager
};


class AuthRoute extends React.Component<AuthRouteProps> {
  updateMode() {
    if (!this.props.isAuth)
      this.props.setMode('login');
    else
      this.props.setMode(this.props.mode ?? 'default');
  }

  componentDidMount = () => this.updateMode();
  componentDidUpdate = () => this.updateMode();

  render() {
    const pageConfigs = PageService.pagesConfigurations;
    let Component = this.props.component;

    if (this.props.isAuth && (!this.props.user || !PageService.isAllowedForUser(this.props.pageId, this.props.user)))
      Component = NotAllowed403;

    if (!(this.props.path.includes(pageConfigs[PAGE_MY_PRODUCTS].path) || this.props.path.includes(pageConfigs[PAGE_PRODUCT_ADRESSES].path)))
      DeviceService.deleteCurrentDeviceConfig();

    return (
      <Route
        exact={this.props.exact}
        path={this.props.path}
        render={(props: RouteComponentProps) =>
          this.props.isAuth
            ? <Component {...props} user={this.props.user}/>
            : <Login/>
        }
      />
    )
  }
}

export default withAlert<AuthRouteProps>()(AuthRoute);
