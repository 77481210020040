class EnvironmentService {
  static isDevelopment(): boolean {
    return process.env.NODE_ENV==='development';
  }
  static isStaging(): boolean {
    return process.env.NODE_ENV==='test';
  }
  static loginTestEnabled(): boolean {
    if(!EnvironmentService.isDevelopment())
      return false;

    if(process.env.REACT_APP_TEST_LOGIN_ENABLED!=='1')
      return false;

    if(!process.env.REACT_APP_TEST_LOGIN_SECRET)
      return false;

    return true;
  }
}

export default EnvironmentService;
