import React, {ReactNode} from 'react';

export interface tabContentProps {
  identifier: string,
  title: string,
  children: ReactNode;
}

class TabContent extends React.Component<tabContentProps> {
}

export default TabContent;
