import React, {
} from 'react';
import Loader from "./Loader";
import news from "../types/news";
import LoadingIndicator from "./LoadingIndicator";
import Accordion from "./Accordion";
import ApiContext from "./Context/ApiContext";

type prodcutOverviewState = {
  news: news[],
  loaded: boolean,
  loadedMore: boolean,
  newsMax: number,
  newsCount: number,
  limit: number,
  offset: number,
  newsLength: number

}

class Dashboard extends React.Component<{}, prodcutOverviewState> {
  constructor(props: {}) {
    super(props);

    this.state = {
      news: [],
      loaded: false,
      loadedMore: true,
      newsMax: 5,
      newsCount: 5, // has to be equal to limit, is set @componentDidMount
      limit: 5,
      offset: 0,
      newsLength: 5
    };

    this.handleNewsRequest = this.handleNewsRequest.bind(this);
  }

  componentDidMount(): void {
    this.setState({
      newsCount: this.state.limit
    });
    this.handleNewsRequest();
  }

  handleNewsRequest() {
    let params= {
      limit: this.state.limit,
      offset: this.state.offset,
    };

    this.context.getNews(params).then((devicesObj: {count: number, data: news[]}) => {
      this.setState({
        news: devicesObj?this.state.news.concat(devicesObj.data):[],
        loaded: true,
        newsLength: devicesObj?devicesObj.count:0,
        loadedMore: true,
      })
    }, () => {
      this.setState( {
        loaded: true,
        loadedMore: true
      })
    });
  }

  onClickLoadMore() {
    this.setState({
      loadedMore: false,
      offset: this.state.offset+this.state.limit,
      newsCount: this.state.newsCount+this.state.limit
    }, this.handleNewsRequest);
  }

  render() {
    return (
      <div id="newsoverview" className="container">
        <h1 className="news__overview__title">Newsboard</h1>
          <div className="contentbox">
            { this.state.loaded ? (
              <>
                { this.state.news.map((news, i_) => {
                  return (
                    <div key={i_}>
                      <Accordion tagline={news.date} title={news.title} mode="news">
                      { news.teaserImages.length > 0 ? (
                        <>
                          <div className="col-6">
                            { news.teaserImages.map((teaserImage, i) => {
                              return (
                                <div key={i} className="news__teaserimage__wrapper">
                                  <img alt={teaserImage.title} className="news__teaserimage" src={teaserImage.source} />
                                  <p className="news__teaserimage--description">
                                    {teaserImage.caption}
                                  </p>
                                </div>
                              )})
                            }
                          </div>
                          <div className="col-6">
                            { news.teaser && (
                              <div className="news__teaser">
                                {news.teaser}
                              </div>
                            )}
                            <div dangerouslySetInnerHTML={{__html: news.text}} className="rte-content news__text"></div>
                          </div>
                        </>
                      ) : (
                        <div className="col-12">
                          <div dangerouslySetInnerHTML={{__html: news.text}} className="rte-content news__text"></div>
                        </div>
                      )}
                    </Accordion>
                  </div>
                  )
                })}
              </>
            ) : (
              <Loader mode="overview"/>
            )
          }
          { this.state.news.length > 0 && (
              this.state.newsCount < this.state.newsLength &&
              <div className="loading__indicator__wrapper">
                <div onClick={this.onClickLoadMore.bind(this)}>
                  <LoadingIndicator loading={!this.state.loadedMore}
                                    text="Show more"/>
                </div>
              </div>
            )
          }
        </div>
      </div>
    )
  }
}

Dashboard.contextType = ApiContext;

export default Dashboard;
