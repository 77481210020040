import React, {ChangeEvent} from 'react';
import classNames from "classnames";

const checkboxDefaultMode = 'checkbox';
type checkboxMode = typeof checkboxDefaultMode | 'switch';

type checkboxProps = {
  label?: string | JSX.Element
  defaultvalue?: boolean
  checked?: boolean
  id?: string
  disabled?: boolean
  required?: boolean
  type?: string
  name?: string
  onChangeState: (e: ChangeEvent<HTMLInputElement>) => void
  className?: string
  mode?: checkboxMode,
  addFormGroup?: boolean
}

class Checkbox extends React.Component<checkboxProps> {
  static defaultProps = {
    mode: checkboxDefaultMode,
    addFormGroup: true
  }

  constructor(props: checkboxProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e: ChangeEvent<HTMLInputElement>) {
    this.props.onChangeState(e);
  }

  render() {
    let {className, defaultvalue, label, mode, onChangeState, addFormGroup, ...defaultProps} = this.props;
    let classes = classNames({
      ['input__' + mode]: true,
      [className + '']: !!className
    });

    const checkbox = (
      <div className="input__checkboxwrapper">
        <input
          type="checkbox"
          checked={this.props.checked}
          defaultChecked={this.props.defaultvalue}
          onChange={this.handleChange}
          className={classes}
          {...defaultProps}
        />
        <label htmlFor={this.props.id}>
          {label && <span className="label__text">{label}</span>}
        </label>
      </div>
    );

    return (
      addFormGroup
        ? (
          <div className="form-group">
            {checkbox}
          </div>
        )
        : checkbox
    )
  }
}

export default Checkbox;
