import React, {RefObject} from "react";
import device from "../../types/device";
import DeviceHeader from "./DeviceHeader";
import Button from "../Button";
import {Link} from "react-router-dom";
import DeviceService from "../../services/DeviceService";

type deviceProps = {
  device: device,
  path: string
}

class DeviceEntry extends React.Component<deviceProps, {}> {
  wrapper: RefObject<HTMLDivElement>;

  constructor(props: deviceProps) {
    super(props);
    this.wrapper = React.createRef<HTMLDivElement>();
    this.state = {};
  }

  componentDidMount() {
    let currentDeviceId = DeviceService.getCurrentDeviceId();
    if (this.wrapper.current && currentDeviceId && currentDeviceId === this.props.device.id.toString()) {
      this.wrapper.current.scrollIntoView({behavior: 'smooth'});
      DeviceService.deleteCurrentDeviceConfig();
    }
  }

  render() {
    return (
      <>
        <div className="productbox" data-device-id={this.props.device.id} ref={this.wrapper}>
          <Link className="productbox__link" to={{pathname: this.props.path + '/' + this.props.device.id}}>
            <DeviceHeader device={this.props.device}/>
            <div className="productbox__body white-bg">
              <div className="row">
                {this.props.device.software ? (
                  <div
                    className="productbox__title productbox__title--software col-12 col-xxl-7">
                    {this.props.device.description}
                  </div>
                ) : (
                  <div
                    className="productbox__title productbox__title--gear col-12 col-xxl-7">
                    {this.props.device.description}
                  </div>
                )}

                <div
                  className="productbox__additionaltext col-12 col-sm">
                  {this.props.device.serialNumber ? (
                    <>Serial No. {this.props.device.serialNumber}</>
                  ) : (
                    this.props.device.softwareVersion && (
                      <>Software Version: {this.props.device.softwareVersion}</>
                    )
                  )}
                </div>
                <div className="productbox__actions col-12 col-sm-auto">
                  <div className="productbox__detail">
                    <Button className="button">Show details</Button>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </>
    );
  }
}

export default DeviceEntry;