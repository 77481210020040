import React from "react";
import {Redirect} from "react-router-dom";
import PageService, {PAGE_HOME} from "../../services/PageService";
import {AlertManager, withAlert} from "react-alert";

type notAllowed403Props = {
  alert: AlertManager
}

class NotAllowed403 extends React.Component<notAllowed403Props> {
  componentDidMount() {
    this.props.alert.info('403: Access denied');
  }

  render() {
    return (
      <div className="container text-center mt-5">
        <div className="contentbox">
          <Redirect to={PageService.pagesConfigurations[PAGE_HOME].path}/>
          <div className="h2 mt-4 mb-4">403 - You are not allowed to see this content!</div>
        </div>
      </div>
    )
  }
}

export default withAlert<notAllowed403Props>()(NotAllowed403);