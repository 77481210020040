import React from "react";
import {Link, LinkProps} from "react-router-dom";
import classNames from "classnames";

interface TabLinkProps extends LinkProps {
  identifier: string,
  isDefault?: boolean
}

class TabLink extends React.Component<TabLinkProps, {}> {
  static defaultProps={
    isDefault: false
  };

  render() {
    let {children, identifier, isDefault, ...linkProps} = this.props;

    let classes={};
    if(window.location.hash) {
      classes={
        'active': window.location.hash.substr(1)===identifier
      }
    } else {
      classes={
        'active': this.props.isDefault
      }
    }

    return (
      <li className={classNames(classes)}>
        <Link {...linkProps}>
          {children}
        </Link>
      </li>
    );
  }
}

export default TabLink;
