import React from "react";
import deviceHistory from "../../types/deviceHistory";
import Table from "../Table";
import {tableData, tableHeadLabels} from "../../types/table";
import DateVisual from "../DateVisual";
import Loader from "../Loader";
import ApiContext from "../Context/ApiContext";

type deviceTabHistoryProps = {
  isSoftware?: boolean
  deviceId: number
}

type deviceTabHistoryState = {
  history: deviceHistory[],
  loaded: boolean
}

class DeviceTabHistory extends React.Component<deviceTabHistoryProps, deviceTabHistoryState> {
  constructor(props: deviceTabHistoryProps) {
    super(props);

    this.state = {
      loaded: false,
      history: []
    }
  }

  componentDidMount(): void {
    if(this.props.deviceId) {
      this.context.getHistory(this.props.deviceId).then((historyList_: deviceHistory[]) => {
        this.setState({
          loaded: true,
          history: historyList_
        })
      })
    }
  }

  render() {
    let headLabels: tableHeadLabels = [
      <>Art. No.</>,
      <>Valid from - until</>,
      <>Order No.</>,
      <>{this.props.isSoftware?'Version':'Serial No.'}</>,
      <>Event</>,
      <>Description</>,
      <>Delivery note </>
    ];

    let tData: tableData = [];
    this.state.history.forEach((historyEntry_) => {
      tData.push([
        <>{ historyEntry_.resourceNumber }</>,
        <><DateVisual utc={historyEntry_.validFrom}/> – {historyEntry_.validUntil?<DateVisual utc={historyEntry_.validUntil}/>:'now'}</>,
        <>{ historyEntry_.orderNumber }</>,
        <>{ this.props.isSoftware?historyEntry_.softwareVersion:historyEntry_.serialNumber }</>,
        <>{ historyEntry_.actionType }</>,
        <>{ historyEntry_.actionDescription }</>,
        <>
          { historyEntry_.deliveryNote?.file?.uri &&
            <a className="link-download" rel="noopener noreferrer" target="_blank" href={historyEntry_.deliveryNote.file.uri}>
              <span>Download Link</span>
            </a>
          }
        </>
      ]);
    });

    return (
      this.state.loaded ? (
        <Table headLabels={headLabels} data={tData}/>
        ) : (
        <Loader mode="inverted" className="history" />
      )
    )
  }
}

DeviceTabHistory.contextType = ApiContext;

export default DeviceTabHistory;
