import React from "react";
import {Link, NavLink} from "react-router-dom";
import AuthContext from "./Context/AuthContext";
import EnvironmentService from "../services/EnvironmentService";
import PageService, {
  PAGE_MY_PRODUCTS,
  PAGE_PRODUCT_ADRESSES,
  PAGE_SERVICE_CENTER,
  PAGE_UI
} from "../services/PageService";
import user from "../types/user";

type headerProps = {
  user?: user,
}

class Navigation extends React.Component<headerProps,{}> {
  render() {
    return (
      <AuthContext.Consumer>
        {({isAuth}) => (
          <div>
            {isAuth ? (
              <nav id="main-navigation">
                <ul>
                  {
                    (this.props.user && PageService.isAllowedForUser(PAGE_PRODUCT_ADRESSES, this.props.user)) &&
                    <li>
                      <Link to={PageService.pagesConfigurations[PAGE_PRODUCT_ADRESSES].path}>
                        {PageService.pagesConfigurations[PAGE_PRODUCT_ADRESSES].title}
                      </Link>
                    </li>
                  }
                  <li>
                    <NavLink to={PageService.pagesConfigurations[PAGE_MY_PRODUCTS].path}>
                      <span>{PageService.pagesConfigurations[PAGE_MY_PRODUCTS].title}</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={PageService.pagesConfigurations[PAGE_SERVICE_CENTER].path}>
                      <span>{PageService.pagesConfigurations[PAGE_SERVICE_CENTER].title}</span>
                    </NavLink>
                  </li>
                  {
                    EnvironmentService.isDevelopment() &&
                    <li>
                      <NavLink to={PageService.pagesConfigurations[PAGE_UI].path}>
                        <span>{PageService.pagesConfigurations[PAGE_UI].title}</span>
                      </NavLink>
                    </li>
                  }
                </ul>
              </nav>
            ) : (
              <div></div>
            )}
          </div>
        )}
      </AuthContext.Consumer>
    )
  }
}

export default Navigation;
