import React from "react";
import {Link, LinkProps} from "react-router-dom";

class BackButton extends React.Component<LinkProps> {
  render() {
    return (
      <Link className="link link--back" {...this.props}>{ this.props.children }</Link>
    )
  }
}

export default BackButton;
