import React, {ChangeEvent} from 'react';
import classNames from "classnames";

type inputProps = {
  id?: string
  mode? : string
  placeholder?: string
  disabled?: boolean
  value?: string
  required?: boolean
  category?: string
  name?: string
  onChangeState: (e: ChangeEvent<HTMLTextAreaElement>) => void
}

class TextArea extends React.Component<inputProps, {}> {
  constructor(props: inputProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e: ChangeEvent<HTMLTextAreaElement>) {
    this.props.onChangeState(e);
  }

  render() {
    let classes=classNames({
      'form-control': true,
      'form-control--normal': this.props.mode === 'normal',
      'form-control--highlighted': this.props.mode === 'highlighted',
      'form-control--white': this.props.mode === 'white',
      'form-control--error': this.props.mode === 'error',
      'form-control--login': this.props.mode === 'login',
    });

    return (
      <div className="form-group">
        <textarea data-category={this.props.category} id={this.props.id} name={this.props.name} required={this.props.required} disabled={this.props.disabled} onChange={this.handleChange} className={classes} placeholder={this.props.placeholder} value={this.props.value}/>
      </div>
    )
  }
}

export default TextArea;
