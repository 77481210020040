import React, {
  ChangeEvent,
  FormEvent,
} from "react";
import Button from "./Button";
import TextArea from "./Textarea";
import Loader from "./Loader";
import device from "../types/device";
import user from "../types/user";
import person from "../types/Person";
import DateVisual from "./DateVisual";
import ApiContext from "./Context/ApiContext";

type salesmanProps = {
  onClickCancel: () => void,
  deviceRequest: boolean,
  device?: device,
  user?: user,
  contacts?: person[],
}

type salesmanStates = {
  message: string,
  emailWasSent: boolean,
  isValid: boolean,
  loaded: boolean,
  receiver: string,
  contacts: person[],
  contact: person|null
}

class SalesmanContact extends React.Component<salesmanProps, salesmanStates> {
  constructor(props: salesmanProps) {
    super(props);
    this.state = {
      message: '',
      emailWasSent: false,
      isValid: false,
      loaded: false,
      receiver: '',
      contacts: [],
      contact: null
    };

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
  }

  isContact(contact: object): contact is person {
    return (contact as person).id!==undefined;
  }

  componentDidMount() {
    let params = {
      'sort[type]': 'DESC'
    }

    if(this.props.deviceRequest)
      Object.assign(params, {'filter[type]':'Support'});

    this.context.getAllCompanyContacts(this.props.user?.company?.id, params).then((contactObj: {count: number, data: person[]}) => {
      let receiver='';
      let contacts=contactObj.data;

      if(contacts.length === 1 && contacts[0].id) {
        receiver=contacts[0].id.toString();
      }

      this.setState({
        contacts: contacts,
        loaded: true,
        receiver: receiver
      })
    });
  }

  onChangeInput(e: ChangeEvent<HTMLTextAreaElement>) {
    e.preventDefault();
    this.setState({
      message: e.target.value
    });
  }

  onClickSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.setState({
      loaded: false
    });

    let params = {
      message: this.state.message,
      device: this.props.device ? this.props.device : undefined
    };

    this.context.sendMessageToContact(this.state.receiver, params).then(() => {
      this.setState({
        emailWasSent: true,
        loaded: true
      }, () => {
        this.setState({
          loaded: true,
        })
      });
    }).catch(() => {
      this.setState({
        loaded: true
      })
    });
  }

  onChangeSelect(e: ChangeEvent<HTMLSelectElement>) {
    e.preventDefault();
    let receiver = this.state.receiver;

    if (e && e.target.id === 'receiver')
      receiver = e.target.value;

    this.setState({
      receiver: receiver,
    })
  }

  onClickCancel() {
    this.props.onClickCancel();
  }

  render() {
    return (
      !this.state.loaded ? (
          <Loader className="success" mode="inverted"/>
        ) :

        !this.state.emailWasSent ? (

          <div className="container customer__request popup__form">
            <h2 className="popup__form__title">Contact Customer Service</h2>
            <div className="popup__form__text">
              <span className="h4">Product Support</span>
              <span>
              PTW is proud to give comprehensive support for all products manufactured and distributed by us. To enable us to do this quickly and efficiently, please {this.props.deviceRequest && <>add the description of the problem</> }
            </span>
              <ul>
                {!this.props.deviceRequest &&
                <>
                  <li>
                    note type number, serial number and version number of the
                    product
                  </li>
                  <li>
                    add the description of the problem
                  </li>
                </>
                }
              </ul>

              {!this.props.deviceRequest &&
              <>
                <br/>
                <h4>Privacy</h4>
                <p>
                  Privacy is important for us.<br/>
                  If you send us patient data for trouble shooting please
                  anonymize the data before you send it.<br/>
                  You can find free software tools to anonymize the data in the
                  internet.
                </p>
              </>
              }
            </div>

            { this.props.deviceRequest &&
            <div className="form-row--with__label">
              <h3 className="form__row__label">Product:</h3>
              <span>
                DeviceID: {this.props.device?.deviceId}<br/>
                Name: {this.props.device?.description}<br/>
                Buy date: <DateVisual utc={this.props.device?.dateOfPurchase} /><br/>
                Order No: {this.props.device?.orderNumber}<br/>
                Serial No: {this.props.device?.serialNumber}
              </span>
            </div>
            }

            <form onSubmit={this.onClickSubmit}>
              <div className="form-row--with__label">
                <h3 className="form__row__label">Service contact *</h3>
                <div className="form-group">
                  <select required value={this.state.receiver} className="form-control" id="receiver" onChange={this.onChangeSelect.bind(this)} disabled={this.state.contacts.length <= 1}>
                    <option key="0" value="">Please choose</option>
                    { this.state.contacts?.map((contact, i_) => {
                      return (
                        <option key={i_+1} value={contact.id}>{contact.type} - {contact.firstName} {contact.lastName}</option>
                      )
                    })}
                  </select>
                </div>
              </div>

              <h3 className="form__row__label">Message *</h3>

              <TextArea required onChangeState={this.onChangeInput}>{this.state.message}</TextArea>

              <div className="form-row form__bottom">
                <Button className="cancel__btn" type="button" onClick={this.onClickCancel} mode="invert">Cancel</Button>
                <Button className="submit__btn">Send</Button>
              </div>

            </form>
          </div>
        ) : (
          <div className="container popup__form">
            <div className="popup__form__confirmation">
              <h2>Thank you!</h2>
              <h4>Your email was sent</h4>
            </div>
            <div className="form-row">
              <Button className="cancel__btn" type="button" onClick={this.onClickCancel} mode="invert">close</Button>
            </div>
          </div>
        )
    )
  }
}

SalesmanContact.contextType = ApiContext;

export default SalesmanContact;
