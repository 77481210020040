import {components, ValueContainerProps} from 'react-select';
import {OptionType, GroupTypeDefault} from './SelectTypes';
import React from "react";

export default function ValueContainer(props: ValueContainerProps<OptionType, true, GroupTypeDefault>) {
  let {children, ...valueContainerProps} = props;

  return (
    <components.ValueContainer {...valueContainerProps} isMulti={false} hasValue={false}>
      {children}
    </components.ValueContainer>
  );
}