import React from "react";
import DateVisual from "../DateVisual";
import Loader from "../Loader";
import device from "../../types/device";
import calibrationCertificate from "../../types/calibrationCertificate";
import Button from "../Button";
import ApiContext from "../Context/ApiContext";
import DatePickerComponent from "../DatePicker";
import calibration from "../../types/calibration";
import user from "../../types/user";

type deviceTabCertificatesProps = {
  isSoftware?: boolean
  deviceId: number
  onChangeDate: () => void
  user?: user
}

type deviceTabCertificatesState = {
  certificates: calibrationCertificate[],
  loaded: boolean
  device: device | undefined
  nextCalibrationDate: Date | string | null
  showDatePicker: boolean
  showDateLabel: boolean
  calibration: calibration | undefined
  userDate: boolean
  noCalibration: boolean
  showDateInfo: boolean
}

class DeviceTabCertificates extends React.Component<deviceTabCertificatesProps, deviceTabCertificatesState> {
  constructor(props: deviceTabCertificatesProps) {
    super(props);

    this.state = {
      loaded: false,
      certificates: [],
      device: undefined,
      nextCalibrationDate: null,
      showDatePicker: false,
      showDateLabel: true,
      calibration: undefined,
      userDate: false,
      noCalibration: false,
      showDateInfo: false
    }

    this.onChangeDate = this.onChangeDate.bind(this);
    this.onSetNewUserDate = this.onSetNewUserDate.bind(this);
    this.getCalibration = this.getCalibration.bind(this);
    this.getDevice = this.getDevice.bind(this);
  }

  componentDidMount(): void {
    if (this.props.deviceId) {
      this.getCalibration(this.getDevice);
      this.context.getCalibrationCertificates(this.props.deviceId).then((calibrationsObj: {data: calibrationCertificate[]}) => {
        this.setState({
          certificates: calibrationsObj.data
        })
      })
    }
  }

  getCalibration(userfunc_?: (userDate: boolean | undefined) => void, load?: boolean) {
    this.context.getCalibration(this.props.deviceId)
      .then((calibrationObj: { count: number, data: calibration[] }) => {
        const data = calibrationObj.data[0];

        // has calibration
        if (data) {
          this.setState({
            calibration: calibrationObj.data[0],
            loaded: !!load,
            userDate: !!calibrationObj.data[0].userCalibrationDate,
            noCalibration: false,
            showDateInfo: true
          }, () => {
            if (userfunc_) {
              userfunc_(!!calibrationObj.data[0].userCalibrationDate);
            }
          });
        } else {
          this.setState({
            noCalibration: true,
            loaded: true
          })
        }
      })
      .catch(() => {
        this.setState({
          showDateInfo: true,
          noCalibration: true
        });
      });
  }

  getDevice(userDate_: boolean = false) {
    this.context.getDevice(this.props.deviceId).then((device: device) => {
      this.setState({
        device: device,
        nextCalibrationDate: device.calibration?.userCalibrationDate ? device.calibration?.userCalibrationDate : device.calibration?.calibrationDate,
        loaded: true,
        userDate: userDate_
      })
    })
  }

  onChangeDate(startDate_: Date) {
    this.setState({
      nextCalibrationDate: startDate_ ? startDate_.toDateString() : startDate_
    })
  }

  onSetNewUserDate() {
    const params = {
      userCalibrationDate: this.state.nextCalibrationDate
    }
    this.setState({
      loaded: false,
    }, () => {
      if (this.state.noCalibration && this.props.deviceId) {
        this.context.createCalibration(this.props.deviceId, params).then(() => {
          this.getCalibration(this.getDevice);
          // update device header Date
          this.props.onChangeDate();
        })
      } else {
        this.context.updateCalibration(this.state.device?.calibration?.id, params).then(() => {
          this.getCalibration(this.getDevice);
          // update device header Date
          this.props.onChangeDate();
        });
      }
    });
  }

  render() {
    return (
      this.state.loaded
        ? <>
          <div className="calibration__date__wrapper">
            <h4 className="device__certificate__headline">Next action</h4>
            {this.state.showDateInfo
              ? <div className="form-group">
                {(this.props.user?.company && this.state.device && this.props.user.company.id === this.state.device.address?.company.id)
                  ? <div className="date__picker__wrapper">
                    <DatePickerComponent
                      startDate={this.state.nextCalibrationDate}
                      onChangeDate={this.onChangeDate}
                    />
                    <div>
                      <Button onClick={this.onSetNewUserDate} className="save__btn" type="button">Save changes</Button>
                    </div>
                  </div>
                  : <div className="date__static">
                    {this.state.nextCalibrationDate
                      ? <DateVisual utc={this.state.nextCalibrationDate}/>
                      : <>N/A</>
                    }
                  </div>
                }
              </div>
              : <div className="date__static">N/A</div>
            }
            {(this.state.showDateInfo && this.state.userDate && this.state.calibration?.user)
              ? <span className="calibration__date__wrapper--info">
                  Calibration date set by {this.state.calibration?.user?.firstName} {this.state.calibration?.user?.lastName} at <DateVisual utc={this.state.calibration?.updatedAt}/>
                </span>
              : this.state.certificates.length > 0 &&
              <b className="download__information">
                The date shown is automatically calculated by the system based on the last available calibration certificate in the system. <br/>
                This is a recommended date for the next action procedure. <br/>
                The date can be changed by the user at any time.
              </b>
            }
          </div>
          {this.state.certificates.length > 0 &&
            <div className="bg--grey">
              <h4 className="device__certificate__headline">Calibration Certificate</h4>
              {this.state.certificates?.map((certificate, i_) => {
                return (
                  <div key={i_}>
                    <div className="row">
                      <div className="col-12">
                        <a href={certificate?.downloadUrl} className="download" target="_blank" rel="noopener noreferrer" title="Download">
                          <div className="row">
                            <div className="col-12 col-sm-auto download__entry__format">
                              <div className="format--pdf"></div>
                            </div>
                            <div className="col download__content__wrapper">
                              <div className="download__title">
                                <DateVisual utc={certificate.dateOfCalibration}/><br/>
                                {certificate.title}
                              </div>
                            </div>
                            <div className="col-12 col-sm-auto">
                              <div className="download__link"></div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          }
        </>
        : <Loader mode="inverted" className="downloads"/>
    )
  }
}

DeviceTabCertificates.contextType = ApiContext;

export default DeviceTabCertificates;
