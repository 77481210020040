import device from "../types/device";
import {CURRENT_DEVICE_ADDRESS_STORE_ID, CURRENT_DEVICE_STORE_ID} from "../components/Device/constants";

export default class DeviceService {
  static saveCurrentDeviceConfig(deviceToStore: device) {
    localStorage.setItem(CURRENT_DEVICE_STORE_ID, deviceToStore.id.toString());
    if(deviceToStore.address){
      localStorage.setItem(CURRENT_DEVICE_ADDRESS_STORE_ID, deviceToStore.address.id.toString());
    }
  }

  static deleteCurrentDeviceConfig() {
    localStorage.removeItem(CURRENT_DEVICE_STORE_ID);
    localStorage.removeItem(CURRENT_DEVICE_ADDRESS_STORE_ID);
  }

  static getCurrentDeviceId(): string | null {
    return localStorage.getItem(CURRENT_DEVICE_STORE_ID);
  }

  static getCurrentDeviceAddressId(): string | null {
    return localStorage.getItem(CURRENT_DEVICE_ADDRESS_STORE_ID);
  }
}