import React from "react";
import classNames from "classnames";
import buttonTypes from "../types/button";

interface buttonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  mode?: buttonTypes
  disabled?: boolean
}

class Button extends React.Component<buttonProps> {
  render() {
    let {mode, className, ...defaultProps} = this.props;
    let buttonClasses = classNames({
      'button': true,
      'button button--moreinv': mode === 'invert',
      [className + '']: !!className
    });

    return (
      <button className={buttonClasses} {...defaultProps}>{this.props.children}</button>
    )
  }

  static defaultOptions = {
    mode: "default"
  };
}

export default Button;
