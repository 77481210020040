import React from "react";
import ApiContext from "../Context/ApiContext";
import BackButton from "../BackButton";
import Button from "../Button";
import {company, companyRelation} from "../../types/company";
import Table from "../Table";
import {tableData, tableHeadLabels} from "../../types/table";
import Autosuggest, {autosuggestSuggestion} from "../Autosuggest";
import user from "../../types/user";
import {AlertManager, withAlert} from "react-alert";
import Loader from "../Loader";
import PageService, {PAGE_COMPANY_ADMINISTRATION, PAGE_HOME} from "../../services/PageService";
import RelationDelete from "./RelationDelete";
import {SORT_DIRECTION_DESC} from "../../types/filter";

type companyAdministrationState = {
  company?: company
  companyRelations?: companyRelation[]
  addressTableData?: tableData
  companySearchResults: company[]
  companySuggestions: autosuggestSuggestion[]
  companySuggestLoading: boolean
  companyRelationsLoading: boolean
  addRelationSearchResults: company[]
  addRelationSuggestions: autosuggestSuggestion[]
  addRelationSuggestLoading: boolean
  companyToAdd?: company
};
type companyAdministrationProps = {
  user: user | undefined
  alert: AlertManager
};

class CompanyAdministration extends React.Component<companyAdministrationProps, companyAdministrationState> {
  constructor(props: companyAdministrationProps) {
    super(props);

    this.state = {
      addressTableData: [],
      companySearchResults: [],
      companySuggestions: [],
      companySuggestLoading: false,
      addRelationSearchResults: [],
      addRelationSuggestions: [],
      addRelationSuggestLoading: false,
      companyRelationsLoading: false
    }

    this.onRelationDeleted = this.onRelationDeleted.bind(this);
    this.searchForCompany = this.searchForCompany.bind(this);
    this.onSelectCompany = this.onSelectCompany.bind(this);
    this.searchForSubsidiary = this.searchForSubsidiary.bind(this);
    this.onSelectSubsidiary = this.onSelectSubsidiary.bind(this);
    this.addRelation = this.addRelation.bind(this);
  }

  onRelationDeleted(relation: companyRelation) {
    let relations = this.state.companyRelations;
    if (relations) {
      relations.find((rel, index) => {
        if (rel.id === relation.id) {
          relations?.splice(index, 1);
          return true;
        }
        return false;
      });
    }
    this.setState({
      companyRelations: relations
    });
  }

  searchForCompany(sWord: string, hasMinCharakters: boolean) {
    this.setState({
      companySuggestLoading: hasMinCharakters,
      companySuggestions: []
    }, () => {
      if (hasMinCharakters) {
        this.context.getCompanies({
          'filter[companies][search]': sWord.trim()
        })
          .then((result: { count: number, data: company[] }) => {
            this.setState({
              companySearchResults: result.data,
              companySuggestLoading: false,
              companySuggestions: this.getSuggestionsFromCompanies(result.data)
            }, () => {
              this.setState({
                companySuggestLoading: false
              });
            });
          })
          .catch(() => {
            this.setState({companySuggestLoading: false});
          })
        ;
      }
    });
  }

  onSelectCompany(suggestionId?: number | string) {
    if (suggestionId) {
      let selectedCompany = this.state.companySearchResults.find(company => {
        return company.id === suggestionId;
      });
      this.setState({
        company: selectedCompany,
        companyRelationsLoading: true,
        addRelationSuggestions: []
      }, () => {
        if (this.state.company) {
          this.context.getCompanyRelations(this.state.company.id, {'sort[created]': SORT_DIRECTION_DESC})
            .then((result: { count: number, data: companyRelation[] }) => {
              this.setState({
                companyRelations: result.data,
                companyRelationsLoading: false
              });
            })
            .catch(() => {
              this.setState({companyRelationsLoading: false});
            })
          ;
        }
      });
    } else {
      this.setState({
        company: undefined,
        companyRelationsLoading: false,
        addRelationSuggestions: [],
        companyRelations: undefined,
        companySuggestions: []
      });
    }
  }

  searchForSubsidiary(sWord: string, hasMinCharakters: boolean) {
    this.setState({
      addRelationSuggestLoading: hasMinCharakters,
      addRelationSuggestions: [],
      companyToAdd: undefined
    }, () => {
      if (hasMinCharakters) {
        this.context.getCompanies({
          'filter[companies][search]': sWord.trim(),
          'exclude-companies-related-to': this.state.company?.id
        })
          .then((result: { count: number, data: company[] }) => {
            this.setState({
              addRelationSearchResults: result.data,
              addRelationSuggestLoading: false,
              addRelationSuggestions: this.getSuggestionsFromCompanies(result.data)
            });
          })
          .catch(() => {
            this.setState({addRelationSuggestLoading: false});
          })
        ;
      }
    });
  }

  getSuggestionsFromCompanies(companies: company[]): autosuggestSuggestion[] {
    let suggestions: autosuggestSuggestion[] = [];
    if (companies.length > 0) {
      companies.forEach(company => {
        suggestions.push({
          id: company.id,
          text: company.name
        })
      });
    }
    return suggestions;
  }

  onSelectSubsidiary(suggestionId?: number | string) {
    if (suggestionId) {
      let selectedSubsidiary = this.state.addRelationSearchResults.find(subsidiary => subsidiary.id === suggestionId);
      if (selectedSubsidiary)
        this.setState({companyToAdd: selectedSubsidiary});
    } else {
      this.setState({
        companyToAdd: undefined,
        addRelationSuggestions: []
      });
    }
  }

  getSubsidiaryTableData(): tableData {
    let tableData: tableData = [];
    if (this.state.companyRelations) {
      this.state.companyRelations.forEach(companyRelation => {
        tableData.push([
          <>{companyRelation.relatedCompany.companyId}</>,
          <>{companyRelation.relatedCompany.name}</>,
          <>
            {companyRelation.created === 'manually' &&
              <RelationDelete
                relation={companyRelation}
                className="address__delete"
                onDeleted={this.onRelationDeleted}
              />
            }
          </>,
        ])
      });
    }
    return tableData;
  }

  addRelation() {
    if (this.state.company && this.state.companyToAdd) {
      this.setState({
        companyRelationsLoading: true
      }, () => {
        this.context.addCompanyRelation(this.state.company?.id, this.state.companyToAdd?.id)
          .then((relation: companyRelation) => {
            this.props.alert.success('Added');
            let relations = this.state.companyRelations ?? [];
            relations.unshift(relation);
            this.setState({
              companyRelations: relations
            }, () => {
              this.setState({companyRelationsLoading: false});
            });
          })
          .catch(() => {
            this.setState({companyRelationsLoading: false});
          })
        ;
      });
    }
  }

  render() {
    const addressTableLabels: tableHeadLabels = [
      <>PTW Company ID</>,
      <>Company Name</>,
      <></>
    ];

    return (
      <div className="container company-administration" id="company-administration">
        <h1 className="pagetitle">{PageService.pagesConfigurations[PAGE_COMPANY_ADMINISTRATION].title}</h1>
        <BackButton to={PageService.pagesConfigurations[PAGE_HOME].path}>
          Back to {PageService.pagesConfigurations[PAGE_HOME].title}
        </BackButton>
        <div className="contentbox">
          <div className="row companysearch">
            <div className="col-12 col-sm-6 col-md-4">
              <Autosuggest
                id="search-for-company"
                mode="highlighted"
                placeholder="Search Company"
                loading={this.state.companySuggestLoading}
                suggestions={this.state.companySuggestions}
                onSearch={this.searchForCompany}
                onSubmit={this.onSelectCompany}
                addResetBtn={true}
              />
            </div>
          </div>

          {this.state.company &&
            <div className="company">
              <div className="company__header">
                <div className="header-col--id">{this.state.company.companyId}</div>
                <div>{this.state.company.name}</div>
              </div>
              <div className="company__body">
                <div className="company__add">
                  <div className="add__label">Company</div>
                  <div className="input-group">
                    <Autosuggest
                      id="search-for-subsidiary"
                      mode="white-bordered"
                      placeholder="Search Company"
                      loading={this.state.addRelationSuggestLoading}
                      suggestions={this.state.addRelationSuggestions}
                      onSearch={this.searchForSubsidiary}
                      onSubmit={this.onSelectSubsidiary}
                      addResetBtn={true}
                    />
                    <Button
                      onClick={this.addRelation}
                      disabled={!this.state.companyToAdd}
                    >
                      Add Company
                    </Button>
                  </div>
                </div>
                {this.state.companyRelations &&
                  <Table headLabels={addressTableLabels} data={this.getSubsidiaryTableData()} className="company__addresses"/>
                }
                {this.state.companyRelationsLoading &&
                  <Loader mode="inverted" wrapperClassName="company__loader"/>
                }
              </div>
            </div>
          }

        </div>
      </div>
    )
  }
}

CompanyAdministration.contextType = ApiContext;

export default withAlert<companyAdministrationProps>()(CompanyAdministration);