import {stringKeyObject} from "../types/common";
import errorMessagesMapping from "../types/errorMessages/errorMessagesMapping";
import errorMessagesValidationLabelsMapping from "../types/errorMessages/errorMessagesValidationLabelsMapping";
import {API_ENDPOINTS} from "./apiEndpoints";

export const errorMessagesLogin: errorMessagesMapping = {
  500: {
    default: 'An unknown error occurred while logging in. Please contact an administrator or try again later.'
  },
  401: {
    default: 'The login was not successful. Please check your login details.'
  }
};

export const createUserForCompanyLabels: errorMessagesValidationLabelsMapping = {
  email: 'Email'
};

export const createUserForCompanyErrors: errorMessagesMapping = {
  400: {
    email: 'The entered email address is already in use.'
  }
};

export const errorMessagesRegistrationsLabels: errorMessagesValidationLabelsMapping = {
  email: 'Email'
};

export const errorMessagesRegistrationsErrors: errorMessagesMapping = {
  400: {
    email: 'The entered email address is already in use. If you have forgotten your password, you can create a new one by clicking "Forgot your password".'
  }
};

export const errorMessagesUpdateUserLabels: errorMessagesValidationLabelsMapping = {
  email: 'Email'
};

export const ERROR_MESSAGES_BY_ENDPOINT: stringKeyObject<errorMessagesMapping> = {
  [API_ENDPOINTS.testLogin]: errorMessagesLogin,
  [API_ENDPOINTS.createUser]: errorMessagesRegistrationsErrors,
  [API_ENDPOINTS.createUserForCompany]: createUserForCompanyErrors,
}

export const ERROR_MESSAGE_LABELS_BY_ENDPOINT: stringKeyObject<errorMessagesValidationLabelsMapping> = {
  [API_ENDPOINTS.createUser]: errorMessagesRegistrationsLabels,
  [API_ENDPOINTS.updateUser]: errorMessagesUpdateUserLabels,
  [API_ENDPOINTS.createUserForCompany]: createUserForCompanyLabels
}