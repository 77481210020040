import {stringKeyObject} from "../types/common";

export function getValueByRegexKeyFromObject<ObjectValueType>(stringToCheck: string, matchKeyObject: stringKeyObject<ObjectValueType>, replace: string): ObjectValueType | undefined {
  let result: ObjectValueType | undefined;
  if (matchKeyObject.hasOwnProperty(stringToCheck)) {
    result = matchKeyObject[stringToCheck];
  } else {
    for (let urlKey in matchKeyObject) {
      let urlToCheck = urlKey.replace(replace, '(.*?)');
      let matches = stringToCheck.match(urlToCheck);
      if (matches && matches[1]) {
        result = matchKeyObject[urlKey.replace(matches[1], replace)];
        break;
      }
    }
  }
  return result;
}