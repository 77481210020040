import React from "react";

class GroupLists extends React.Component {
  render () {
    return (
      <div>
        <h1>Lists</h1>
        <div className="row">
          <div className="col-12 col-sm-6 rte__content">
            <ul>
              <li>Nulla consequat massa quis enim.</li>
              <li>Donec vitae orci sed dolor rutrum auctor.</li>
              <li>Praesent blandit laoreet nibh.</li>
              <li>Morbi mattis ullamcorper velit.</li>
              <li>Cras varius.</li>
            </ul>
          </div>
          <div className="col-12 col-sm-6 rte__content">
            <ol>
              <li>Nulla consequat massa quis enim.</li>
              <li>Donec vitae orci sed dolor rutrum auctor.</li>
              <li>Praesent blandit laoreet nibh.</li>
              <li>Morbi mattis ullamcorper velit.</li>
              <li>Cras varius.</li>
            </ol>
          </div>
        </div>
      </div>
    )
  }
}

export default GroupLists;
