import React from "react";

interface dateVisualProps {
  utc?: string | Date
}

class DateVisual extends React.Component<dateVisualProps> {
  constructor(props: dateVisualProps) {
    super(props);

    this.getDateString=this.getDateString.bind(this);
  }

  getDateString(): string {
    if(this.props.utc) {
      let dateObj=new Date(this.props.utc);
      let oneDigitMonth=((dateObj.getMonth()+1)<10);
      let oneDigitDay=(dateObj.getDate()<10);
      if(dateObj.toString()==='Invalid Date')
        return '';
      return dateObj.getFullYear()+'-'+(oneDigitMonth?'0':'')+(dateObj.getMonth()+1)+'-'+(oneDigitDay?'0':'')+dateObj.getDate();
    } else {
      return ''
    }
  }

  render() {
    return (
      <>
        { this.props.utc &&
          this.getDateString()
        }
      </>
    )
  }
}

export default DateVisual;
