import {positions, transitions} from "react-alert";

const alertDefaultOptions = {
  position: positions.BOTTOM_RIGHT,
  timeout: 5000,
  offset: '30px',
  transition: transitions.FADE
};

export default alertDefaultOptions;
