import userStatus, {
  USER_STATUS_ACTIVE,
  USER_STATUS_DELETED,
  USER_STATUS_INACTIVE,
  USER_STATUS_VALIDATED,
  USER_STATUS_NEW,
  USER_STATUS_VALIDATION_FAILED
} from "../types/userStatus";
import {statusDotColor} from "../components/StatusDot";

class UserStatusService {
  static status = {
    [USER_STATUS_NEW]: 'New',
    [USER_STATUS_VALIDATION_FAILED]: 'Data import failed',
    [USER_STATUS_VALIDATED]: 'Validated',
    [USER_STATUS_ACTIVE]: 'Active',
    [USER_STATUS_INACTIVE]: 'Inactive',
    [USER_STATUS_DELETED]: 'Deleted',
  }

  static getLabelforStatus(status_: userStatus): string {
    if (UserStatusService.status[status_])
      return UserStatusService.status[status_];
    return 'undefined state';
  }

  static getStatusDotColor(status_: userStatus): statusDotColor {
    if (status_ === USER_STATUS_ACTIVE)
      return 'green';
    return 'red';
  }
}

export default UserStatusService;
