import React from "react";

class GroupTypo extends React.Component {
  render () {
    return (
      <div>
        <h1>Font sizes</h1>
        <div>
          <span className="typo-lg">Text large -&gt; Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</span>
          <hr/>
          <span>Text normal -&gt;Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</span>
          <hr/>
          <span className="text--md">Text force normal -&gt;Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</span>
          <hr/>
          <span className="text--sm">Text small -&gt; Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</span>
        </div>
      </div>
    )
  }
}

export default GroupTypo;
