import React from "react";

class MyAccount extends React.Component {
  render() {
    return (
      <div>
        <h1>My Account</h1>
      </div>
    )
  }
}

export default MyAccount;
