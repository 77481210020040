import React from "react";
import user from "../../types/user";

let AuthContextDefaults = {
  isAuth: false,
  authFinished: false,
  user: undefined,
};

type AuthContextProps = {
  isAuth: boolean,
  authFinished: boolean,
  user: user | undefined,
};

const AuthContext = React.createContext<AuthContextProps>(AuthContextDefaults);

export default AuthContext;
