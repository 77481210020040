import React from 'react';
import ApiContext from "./Context/ApiContext";
import FaqCategory from "./FaqCategory";
import faqCategory from "../types/faqCategory";
// linter says match is not used
// eslint-disable-next-line
import {HashRouter, NavLink, Route, match} from "react-router-dom";
import Loader from "./Loader";
import BackButton from "./BackButton";
import * as H from "history";

interface faqOverviewState {
  rootCategoriesLoaded: boolean,
  rootCategories: faqCategory[]
  activeRootCategoryId: string,
  defaultCategoryActive: boolean,
}

class FaqOverview extends React.Component<{}, faqOverviewState> {
  constructor(props: {}) {
    super(props);

    this.state = {
      rootCategoriesLoaded: false,
      rootCategories: [],
      activeRootCategoryId: '',
      defaultCategoryActive: true,
    }

    this.getLinkpathForFaqCategory=this.getLinkpathForFaqCategory.bind(this);
  }

  componentDidMount(): void {
    this.context.getFaqRootCategories().then((faqCategoriesObj: {count: number, data: faqCategory[]})=>{
      this.setState({
        rootCategoriesLoaded: true,
        rootCategories: faqCategoriesObj.data
      }, () => {
      })
    });
  }

  getLinkpathForFaqCategory(faqCategory: faqCategory): string {
    return faqCategory.title
      .trim()
      .toLocaleLowerCase()
      .replace(' ', '-')
      .replace('_', '-')
      .replace('/', '')
  }

  customIsNavActive(match: match | null, location: H.Location, index: number): boolean {
    if(index===0 && this.state.defaultCategoryActive) {
      return true;
    }
    return !!match;
  }

  onClickNavLink(e: React.MouseEvent<HTMLAnchorElement>, category: faqCategory) {
    this.setState({
      activeRootCategoryId: category.title,
      defaultCategoryActive: false
    })
  }

  render() {
    return (
      <div className={"faqOverview container"}>
        <BackButton to="/">
          Back to home
        </BackButton>
        { this.state.rootCategoriesLoaded ? (
          <>
            {
              !this.state.rootCategories ? (
                <h3>No FAQs found.</h3>
              ) : (
                <>
                  {
                    this.state.rootCategories.length === 1 ? (
                      <FaqCategory defaultCategory={this.state.rootCategories[0]}/>
                    ) : (
                      <HashRouter hashType="noslash">
                        <div className={"faqOverview__navigation"}>
                          <ul>
                            {
                              this.state.rootCategories.map((category, index) => {
                                return (
                                  <li key={index}>
                                    <NavLink isActive={(match, location) => this.customIsNavActive(match, location, index)} onClick={(e) => this.onClickNavLink(e, category)} to={this.getLinkpathForFaqCategory(category)}>
                                      {category.title}
                                    </NavLink>
                                  </li>
                                )
                              })
                            }
                          </ul>
                        </div>
                        <div className={"faqOverview__category"}>
                          {
                            this.state.rootCategories.map((category, i) => {
                              let paths=['/' + this.getLinkpathForFaqCategory(category)];

                              if(i===0) {
                                paths.push('/')
                                return (
                                  <Route key={i} exact path={paths}>
                                    <div className="tab-navigation__tab">
                                      <FaqCategory defaultCategory={category}/>
                                    </div>
                                  </Route>
                                )
                              }

                              return (
                                <Route key={i} path={paths}>
                                  <div className="tab-navigation__tab">
                                    <FaqCategory defaultCategory={category}/>
                                  </div>
                                </Route>
                              )
                            })
                          }
                        </div>
                      </HashRouter>
                    )
                  }
                </>
              )
            }
          </>
        ) : (
          <Loader mode="overview"/>
        )}
      </div>
    )
  }
}

FaqOverview.contextType = ApiContext;

export default FaqOverview;
