import React from "react";
import {Link} from "react-router-dom";
import Logo from "./Logo";
import Button from "./Button";
import PopUp from "./PopUp";
import SalesmanContact from "./SalesmanContact";
import user from "../types/user";
import person from "../types/Person";
import ApiContext from "./Context/ApiContext";
import AuthContext from "./Context/AuthContext";
import EnvironmentService from "../services/EnvironmentService";
import PageService, {
  PAGE_COMPANY_ADMINISTRATION,
  PAGE_COMPANY_OVERVIEW,
  PAGE_MY_ACCOUNT,
  PAGE_MY_TEAM,
  PAGE_USER_MANAGEMENT,
  PAGE_HOME
} from "../services/PageService";

type headerProps = {
  user?: user,
}

type headerStates = {
  showPopup: boolean
  contactsofTypeSales: person[]
  contactsofTypeSupport: person[]
}

class Header extends React.Component<headerProps, headerStates> {
  constructor(props: headerProps) {
    super(props);

    this.state = {
      showPopup: false,
      contactsofTypeSales: [],
      contactsofTypeSupport: []
    };
    this.onClickCancel = this.onClickCancel.bind(this);
    this.onClickLogout = this.onClickLogout.bind(this);
  }

  componentDidMount() {
    this.context.getAllCompanyContacts(this.props.user?.company?.id, {'filter[type][eq]': 'Sales'}).then((contactObj: { count: number, data: person[] }) => {
      this.setState({
        contactsofTypeSales: contactObj.data
      })
    });
    this.context.getAllCompanyContacts(this.props.user?.company?.id, {'filter[type][eq]': 'Support'}).then((contactObj: { count: number, data: person[] }) => {
      this.setState({
        contactsofTypeSupport: contactObj.data
      })
    });
  }

  onClickLogout() {
    if (EnvironmentService.loginTestEnabled()) {
      this.context.logoutTest();
    } else {
      this.context.logout();
    }
  }

  onClickContact() {
    this.setState({
      showPopup: true
    });
  }

  onClickCancel() {
    this.setState({
      showPopup: false
    });
  }

  render() {
    return (
      <>
        <PopUp onClickCloseIcon={this.onClickCancel} showPopup={this.state.showPopup}>
          <SalesmanContact user={this.props.user} deviceRequest={false} onClickCancel={this.onClickCancel}/>
        </PopUp>
        <header id="header">
          <Link to={PageService.pagesConfigurations[PAGE_HOME].path}>
            <div id="logo">
              <Logo/>
            </div>
          </Link>

          <div className="header__actions">

            <AuthContext.Consumer>
              {({isAuth, user}) => (
                <>
                  {isAuth && (
                    <>
                      <div className="header__action">
                        <Link className="action__link action__link--contact" to="#">
                          <span>Contact</span>
                        </Link>
                        <div className="action__content action__content--overlay contact">
                          <div className="upper__wrapper">
                            <h2 className="action__content--overlay__header">Customer service</h2>
                            <div className="upper__wrapper__contacts">
                              {this.state.contactsofTypeSupport.map((contact, i_) => {
                                return (
                                  <div className="sales__manager__contact" key={i_}>
                                    <h3 className="sales__manager__contact--name inverted">{contact.firstName} {contact.lastName}</h3>
                                    <h5>{contact.contactDomain}</h5>
                                    <a className="action__content__label icon icon1" href={'tel:' + contact.phone}>{contact.phone}</a>
                                    <a className="action__content__label icon mail" href={'mailto:' + contact.email}>{contact.email}</a>
                                  </div>
                                )
                              })}
                              <div>
                                <Button className="cancel__btn" type="button" onClick={this.onClickContact.bind(this)} mode="invert">Contact form</Button>
                              </div>
                            </div>
                          </div>
                          {this.state.contactsofTypeSales.length > 0 &&
                            <div className="lower__wrapper">
                              <h2 className="action__content--overlay__header">Sales</h2>
                              <div className="lower__wrapper__contacts">
                                {this.state.contactsofTypeSales.map((contact, i_) => {
                                  return (
                                    <div className="sales__manager__contact" key={i_}>
                                      <h3 className="sales__manager__contact--name">{contact.firstName} {contact.lastName}</h3>
                                      <h5>{contact.contactDomain}</h5>
                                      <a className="action__content__label inverted icon icon-red icon1" href={'tel:' + contact.phone}>{contact.phone}</a>
                                      <a className="action__content__label inverted icon icon-red mail" href={'mailto:' + contact.email}>{contact.email}</a>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          }
                        </div>
                      </div>


                      <div className="header__action header__action--user">
                        <Link className="action__link action__link--user" to="#">
                        <span className="user__name">
                          Hello {user ? (
                          user.firstName || user.lastName ? (
                            <>
                              {user.firstName} {user.lastName}
                            </>
                          ) : (
                            <>User</>
                          )
                        ) : 'User'}
                        </span>
                        </Link>
                        <div className="action__content action__content--contextmenu">
                          <ul>
                            {false &&
                              <li>
                                <Link to={PageService.pagesConfigurations[PAGE_MY_ACCOUNT].path}>
                                  {PageService.pagesConfigurations[PAGE_MY_ACCOUNT].title}
                                </Link>
                              </li>
                            }
                            {
                              (this.props.user && PageService.isAllowedForUser(PAGE_MY_TEAM, this.props.user)) &&
                              <li>
                                <Link to={PageService.pagesConfigurations[PAGE_MY_TEAM].path}>
                                  {PageService.pagesConfigurations[PAGE_MY_TEAM].title}
                                </Link>
                              </li>
                            }
                            {
                              (this.props.user && PageService.isAllowedForUser(PAGE_USER_MANAGEMENT, this.props.user)) &&
                              <li>
                                <Link to={PageService.pagesConfigurations[PAGE_USER_MANAGEMENT].path}>
                                  {PageService.pagesConfigurations[PAGE_USER_MANAGEMENT].title}
                                </Link>
                              </li>
                            }
                            {
                              (this.props.user && PageService.isAllowedForUser(PAGE_COMPANY_OVERVIEW, this.props.user)) &&
                              <li>
                                <Link to={PageService.pagesConfigurations[PAGE_COMPANY_OVERVIEW].path}>
                                  {PageService.pagesConfigurations[PAGE_COMPANY_OVERVIEW].title}
                                </Link>
                              </li>
                            }
                            {
                              (this.props.user && PageService.isAllowedForUser(PAGE_COMPANY_ADMINISTRATION, this.props.user)) &&
                              <li>
                                <Link to={PageService.pagesConfigurations[PAGE_COMPANY_ADMINISTRATION].path}>
                                  {PageService.pagesConfigurations[PAGE_COMPANY_ADMINISTRATION].title}
                                </Link>
                              </li>
                            }
                            <li>
                              <button className="button-reset" onClick={this.onClickLogout}>Logout</button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </AuthContext.Consumer>
          </div>
        </header>
      </>
    );
  }
}

Header.contextType = ApiContext;

export default Header;
