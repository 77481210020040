import React from "react";
import {table} from "../types/table";
import classNames from "classnames";

interface tableProps extends table {
  className?: string
}

class Table extends React.Component<tableProps> {
  render() {
    const className = classNames({
      'table-wrapper': true,
      [this.props.className + '']: !!this.props.className
    });

    return (
      <div className={className}>
        <table className="table">
          <thead>
          <tr>
            {
              this.props.headLabels.map((el_, i_) => {
                return (
                  <td key={i_}>{el_}</td>
                )
              })
            }
          </tr>
          </thead>
          <tbody>
          {
            this.props.data.map((row_, i_) => {
              return (
                <tr key={i_}>
                  {
                    row_.map((col_, i_) => {
                      return (
                        <td key={i_}>
                          {col_}
                        </td>
                      )
                    })
                  }
                </tr>
              )
            })
          }
          </tbody>
        </table>
      </div>
    )
  }
}

export default Table;
