import React from "react";
import ApiContext from "./Context/ApiContext";
import {RouteComponentProps} from "react-router-dom";
import LocationService from "../services/LocationService";
import {AxiosResponse} from "axios";
import Loader from "./Loader";
import user from "../types/user";

class AuthorizeEndpoint extends React.Component<RouteComponentProps> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.redirectToErrorUrl = this.redirectToErrorUrl.bind(this);
  }

  redirectToErrorUrl(response_: AxiosResponse<{ url: string }>) {
    if (response_ && response_.data && response_.data.url) {
      window.location.replace(response_.data.url);
    }
  }

  componentDidMount() {
    let searchObj = LocationService.searchToObject();

    if (searchObj.error && searchObj.error_description) {
      this.context.getErrorUrl(searchObj.error, searchObj.error_description)
        .then((response_: AxiosResponse<{ url: string }>) => {
          this.redirectToErrorUrl(response_);
        }, (error: { response: AxiosResponse }) => {
          this.redirectToErrorUrl(error.response);
        })
      return;
    }

    this.context.authorize(window.location.search)
      .then(() => {
        this.context.getUser().then((response: user | undefined) => {
          this.props.history.replace('/');
          window.location.reload();
        });
      })
      .catch((response_: AxiosResponse)=>{
        this.context.logout();
      });
  }

  render() {
    return <Loader wrapperClassName="loader--page"/>;
  }
}

AuthorizeEndpoint.contextType = ApiContext;

export default AuthorizeEndpoint;
