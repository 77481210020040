import React from "react";
import {DateRange, Range} from "react-date-range";
import * as moment from "moment-timezone";

type daterangePickerProps = {
  showPopup: boolean,
  changeDateRange: (startDate_: Date, endDate_: Date) => void,
  onClearDatePicker: () => void,
  onSetDatePicker: () => void;
  startDate: Date | undefined,
  endDate: Date | undefined,
}

type daterangePickerStates = {
  startDate?: moment.Moment | Date | string,
  endDate?: moment.Moment | Date | string,
  key: string,
  updateDatePicker: boolean
}

class DaterangePicker extends React.Component<daterangePickerProps, daterangePickerStates> {
  constructor(props: daterangePickerProps) {
    super(props);

    this.state = {
      startDate: this.props.startDate ? new Date(this.props.startDate) : new Date(),
      endDate: this.props.endDate ? new Date(this.props.endDate) : new Date(),
      key: 'selection',
      updateDatePicker: true
    }

    this.onClearButton = this.onClearButton.bind(this);
  }

  handleSelect(obj: { selection: Range }) {
    const start = obj.selection.startDate;
    const end = obj.selection.endDate;

    this.setState({
      startDate: start,
      endDate: end,
    });

    if (start && end) {
      // @ts-ignore
      this.props.changeDateRange(obj.selection.startDate, obj.selection.endDate);
    }
  }

  onClearButton() {
    this.setState({
      startDate: new Date(),
      endDate: new Date(),
      updateDatePicker: false
    }, this.props.onClearDatePicker);
  }

  render() {
    return (
      this.props.showPopup &&
      <div className="daterange__calendar">
        <DateRange
          //@ts-ignore
          onChange={this.handleSelect.bind(this)}
          ranges={[this.state]}
          twoStepChange={true}
          showDateDisplay={false}
          rangeColors={['#E42618']}
        />
        <div className="daterange__buttons">
          <button onClick={this.onClearButton} className="button button--moreinv">Clear</button>
          <button onClick={this.props.onSetDatePicker} className="button button--moreinv">Set</button>
        </div>
      </div>
    )
  }
}

export default DaterangePicker;
