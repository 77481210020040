import React from "react";
import './Dropdown.scss';
import classNames from "classnames";

type dropdownStates = {
  isOpen: boolean
  dropdownButtonRef?: React.Ref<HTMLInputElement>
  dropdownTop: number
  dropdownLeft: number
  dropdownWidth: number
}

type dropdownProps = {
  buttonText: string | undefined
  className?: string,
  onClickReset?: () => void
  showReset?: boolean
}

const DROPDOWN_OFFSET = 6;

class Dropdown extends React.Component<dropdownProps, dropdownStates> {
  private dropdownWrapperRef: React.RefObject<HTMLDivElement>;

  constructor(props: dropdownProps) {
    super(props);

    this.state = {
      isOpen: false,
      dropdownTop: 0,
      dropdownLeft: 0,
      dropdownWidth: 0
    }

    this.dropdownWrapperRef = React.createRef<HTMLDivElement>();

    this.toggleMulitSelectVisibility = this.toggleMulitSelectVisibility.bind(this);
    this.clickedSelect = this.clickedSelect.bind(this);
    this.onWindowUpdate = this.onWindowUpdate.bind(this);
  }

  componentDidMount() {
    document.body.addEventListener('click', this.clickedSelect);
    window.addEventListener('scroll', this.onWindowUpdate, {passive: true});
    window.addEventListener('resize', this.onWindowUpdate, {passive: true});
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.clickedSelect);
    window.removeEventListener('scroll', this.onWindowUpdate);
    window.removeEventListener('resize', this.onWindowUpdate);
  }

  onWindowUpdate() {
    if (this.state.isOpen)
      this.updateDropdownPosition();
  }

  updateDropdownPosition(callback?: () => void) {
    if (this.dropdownWrapperRef.current) {
      let buttonBoundings = this.dropdownWrapperRef.current.getBoundingClientRect();
      this.setState({
        dropdownTop: buttonBoundings.height + buttonBoundings.top + DROPDOWN_OFFSET,
        dropdownLeft: buttonBoundings.left,
        dropdownWidth: buttonBoundings.width
      }, callback);
    }
  }

  showDropdown() {
    this.updateDropdownPosition(() => {
      this.setState({
        isOpen: true
      });
    });
  }

  hideDropdown() {
    this.setState({
      isOpen: false
    });
  }

  clickedSelect(event: MouseEvent) {
    if (this.state.isOpen)
      this.updateDropdownPosition();

    // @ts-ignore
    if (this.dropdownWrapperRef.current && !this.dropdownWrapperRef.current.contains(event.target))
      this.hideDropdown();
  }

  toggleMulitSelectVisibility() {
    if (this.state.isOpen)
      this.hideDropdown();
    else
      this.showDropdown();
  }

  render() {
    let dropdownClassName = classNames({
      'dropdown__wrapper': true,
      'form-group': true,
      'has-reset': this.props.showReset && this.props.onClickReset,
      [this.props.className + '']: !!this.props.className
    });

    return (
      <div ref={this.dropdownWrapperRef} className={dropdownClassName}>
        <button className="form-control" onClick={this.toggleMulitSelectVisibility}>{this.props.buttonText}</button>
        {(this.props.showReset && this.props.onClickReset) &&
          <span onClick={this.props.onClickReset} className="resetFilter"></span>
        }
        <div className="dropdownchild__wrapper" style={{
          top: this.state.dropdownTop,
          left: this.state.dropdownLeft,
          width: this.state.dropdownWidth
        }}>
          {this.state.isOpen &&
            <>{this.props.children}</>
          }
        </div>
      </div>
    )
  }
}


export default Dropdown;