import React from "react";

class GroupHeadlines extends React.Component {
  render () {
    return (
      <div>
        <h1>Headlines</h1>
        <div>
          <h1>Headline H1</h1>
          <span className="h1">Fake Headline H1</span>
        </div>
        <div>
          <h2>Headline H2</h2>
          <span className="h2">Fake Headline H2</span>
        </div>
        <div>
          <h3>Headline H3</h3>
          <span className="h3">Fake Headline H3</span>
        </div>
      </div>
    )
  }
}

export default GroupHeadlines;
