import React from "react";
import ApiContext from "../Context/ApiContext";
import user from "../../types/user";
import Checkbox from "../Checkbox";
import {AlertManager, withAlert} from "react-alert";
import {companyRelation} from "../../types/company";

type checkboxData = {
  id: number,
  companyId: string;
  companyName: string,
  name: string,
  email: string,
  allowed: boolean
}

type companyUserPopupContentState = {
  activeUserIds: number[],
  toggleAllActive: boolean,
  loaded: boolean,
  users: user[],
  activeUserIdsLenght: number,
  checkboxesData: checkboxData[],
  passivUserIds: number[]
}

type companyUserPopupContentProps = {
  companyRelation: companyRelation,
  alert: AlertManager
}

class CompanyUserPopupContent extends React.Component<companyUserPopupContentProps, companyUserPopupContentState> {
  private toggleAllButtonRef: any;

  constructor(props: companyUserPopupContentProps) {
    super(props);

    this.toggleAllButtonRef = React.createRef();

    this.state = {
      activeUserIds: [],
      passivUserIds: [],
      toggleAllActive: true,
      loaded: false,
      users: this.props.companyRelation.users,
      activeUserIdsLenght: 0,
      checkboxesData: []
    }

    this.getAllUserIds = this.getAllUserIds.bind(this);
    this.handelUserSwitch = this.handelUserSwitch.bind(this);
  }

  async componentDidMount() {
    let activeUsersIds = this.getAllUserIds(this.props.companyRelation.users);

    this.setState({
      activeUserIds: activeUsersIds,
      activeUserIdsLenght: activeUsersIds.length
    });

    var passiveUsers: user[] = [];
    var checkboxesData: checkboxData[] = [];

    if (this.props.companyRelation.users.length) {
      this.props.companyRelation.users.forEach((user) => {
        let userData = {
          id: user.id,
          companyId: this.props.companyRelation.company.companyId,
          companyName: this.props.companyRelation.company.name,
          name: user.firstName + ' ' + user.lastName,
          email: user.email,
        };
        if (!user.allowed) {
          passiveUsers.push(user);
          checkboxesData.push({
            ...userData,
            allowed: false
          })
        } else {
          checkboxesData.push({
            ...userData,
            allowed: true
          })
        }
      });
    }

    // save all passiv user ids
    let passivUserIds: number[] = [];
    if (passiveUsers.length) {
      passiveUsers.forEach((passivCopmany) => {
        passivUserIds.push(passivCopmany.id);
      });
    }

    this.setState({
      passivUserIds: passivUserIds,
      checkboxesData: checkboxesData,
    });
  }

  getAllUserIds(users: user[]): number[] {
    let allUserIds: number[] = [];
    users.forEach((user) => {
      allUserIds.push(user.id)
    })

    return allUserIds;
  }

  toggleAllSwitch() {
    // toggleAll is active and user wants to set it to passiv with click
    if (this.state.toggleAllActive) {
      // now set passiv

      let checkboxesData = this.state.checkboxesData;
      let checkboxesBackupToggleStates: boolean[] = [];
      let passivUserIds = this.state.passivUserIds;

      checkboxesData.forEach((checkbox) => {
        checkboxesBackupToggleStates.push(checkbox.allowed);
        checkbox.allowed = false;
        passivUserIds.push(checkbox.id);
      });

      this.setState({
        checkboxesData: checkboxesData,
        toggleAllActive: false,
      })

      this.context.deleteAllUserFromCompanyRelation(this.props.companyRelation.id).then(() => {
        this.setState({
          passivUserIds: passivUserIds,
        })
      }).catch(() => {
        this.props.alert.error("Failed to deactivate all users. Resetet all toggles");
        let checkboxesreset = this.state.checkboxesData;

        checkboxesreset.forEach((checkbox, index) => {
          checkbox.allowed = checkboxesBackupToggleStates[index];
        })

        this.setState({
          toggleAllActive: true,
          checkboxesData: checkboxesreset
        });
      });
    } else {
      // get all users to send them and trun toggleAll to positiv
      let allUserIds: number[] = [];
      let checkboxesData = this.state.checkboxesData;
      let checkboxesBackupToggleStates: boolean[] = [];

      checkboxesData.forEach((checkbox, index) => {
        checkboxesBackupToggleStates.push(checkbox.allowed);
        allUserIds.push(checkbox.id);
        checkboxesData[index].allowed = true;
      });

      this.setState({
        checkboxesData: checkboxesData,
        toggleAllActive: true,
      });

      this.context.activateUserFormCompanyRelation(this.props.companyRelation.company.id, this.props.companyRelation.relatedCompany.id, {
        users: allUserIds
      })
        .then(() => {
          this.setState({
            passivUserIds: [],
          })
        }).catch(() => {
        this.props.alert.error("Failed to activate all users. Resetet all toggles");
        let checkboxesreset = this.state.checkboxesData;

        checkboxesreset.forEach((checkbox, index) => {
          checkbox.allowed = checkboxesBackupToggleStates[index];
        })

        this.setState({
          toggleAllActive: false,
          checkboxesData: checkboxesreset
        });
      });
    }
  }


  handelUserSwitch(userId: number, isActive: boolean) {
    if (isActive) {
      // now set to passiv
      let checkboxDataIndex = this.state.checkboxesData.findIndex(x => x.id === userId);
      let checkboxData = this.state.checkboxesData;
      checkboxData[checkboxDataIndex].allowed = false;
      this.setState({
        checkboxesData: checkboxData
      });

      this.context.deleteUserFromCompanyRelationByUser(this.props.companyRelation.id, userId)
        .then(() => {
          let passivUserIds = this.state.passivUserIds;
          passivUserIds.push(userId);

          this.setState({
            passivUserIds: passivUserIds,
          })
        })
        .catch(() => {
          this.props.alert.error("Failed to deactivate user: " + checkboxData[checkboxDataIndex].name + '. Toggle state was resetet');
          checkboxData[checkboxDataIndex].allowed = true;

          this.setState({
            checkboxesData: checkboxData
          });
        });
    } else {
      //now set to active
      let checkboxDataIndex = this.state.checkboxesData.findIndex(x => x.id === userId);
      let checkboxData = this.state.checkboxesData;
      checkboxData[checkboxDataIndex].allowed = true;

      this.setState({
        checkboxesData: checkboxData
      });

      this.context.activateUserFormCompanyRelation(this.props.companyRelation.company.id, this.props.companyRelation.id, {
        users: [userId]
      })
        .then(() => {
          let passivUserIds = this.state.passivUserIds;
          let index = passivUserIds.indexOf(userId);
          passivUserIds.slice(index, 1);

          this.setState({
            passivUserIds: passivUserIds,
          })
        })
        .catch(() => {
          this.props.alert.error("Failed to activate user: " + checkboxData[checkboxDataIndex].name + '. Toggle state was resetet');
          checkboxData[checkboxDataIndex].allowed = false;

          this.setState({
            checkboxesData: checkboxData
          });
        });
    }
  }

  render() {
    return (
      <>
        {/*Can´t use normal table components because checkboxes won't work*/}
        <div className="company__users">
          <table className="table">
            <thead>
            <tr>
              <td>Company ID</td>
              <td>Company name</td>
              <td>Name</td>
              <td>E-Mail</td>
              <td>
                <Checkbox key="1337" defaultvalue={this.state.toggleAllActive} mode="switch"
                          id={"toggleAllSwitch"}
                          className={this.state.toggleAllActive ? 'active' : 'passiv'} addFormGroup={false}
                          onChangeState={() => this.toggleAllSwitch()}/>
              </td>
            </tr>
            </thead>
            <tbody>
            {this.state.checkboxesData.map((checkboxData, index) => {
              return (
                <tr key={'company-' + index}>
                  <td>{checkboxData.companyId}</td>
                  <td>{checkboxData.companyName}</td>
                  <td>{checkboxData.name}</td>
                  <td>{checkboxData.email}</td>
                  <td>
                    <Checkbox
                      mode="switch"
                      defaultvalue={checkboxData.allowed}
                      onChangeState={() => this.handelUserSwitch(checkboxData.id, checkboxData.allowed)}
                      addFormGroup={false}
                      className={checkboxData.allowed ? 'active' : 'passiv'}
                      id={'company-switch-' + checkboxData.id}
                    />
                  </td>
                </tr>
              );
            })}
            </tbody>
          </table>
        </div>
      </>
    )
  }
}

CompanyUserPopupContent.contextType = ApiContext;

export default withAlert<companyUserPopupContentProps>()(CompanyUserPopupContent);