import React from 'react';
import ReactSelect, {components as reactSelectComponents} from 'react-select';
import {SelectProps, OptionType, GroupTypeDefault} from './SelectTypes';
import Container from './Container';
import ValueContainer from './ValueContainer';
import Menu from './Menu';
import OptionCheckbox from './OptionCheckbox';
import IndicatorSeparator from './IndicatorSeparator';
import IndicatorsContainer from './IndicatorsContainer';
import MultiValue from './MultiValue';
import './Select.scss';
import classNames from "classnames";
import Dropdown from "../Dropdown/Dropdown";
import Loader from "../Loader";
import {ActionMeta, ValueType} from "react-select/src/types";

const INPUT_PLACEHOLDER_DEFAULT = 'Search';

export default function Select(props: SelectProps<OptionType, true, GroupTypeDefault>) {
  let {
    selectLabel,
    styles,
    loading,
    onClickReset,
    showReset,
    onChange,
    placeholder,
    ...selectProps
  } = props;
  let selectClassName = classNames({
    'select': true,
    'select--default': true,
    'nosearch': !selectProps.isMulti && (!selectProps.options || selectProps.options.length < 5),
    [selectProps.className + '']: !!selectProps.className
  });

  const customStyles = {
    ...styles,
    control: () => {
      return {};
    },
    valueContainer: () => {
      return {};
    },
    dropdownIndicator: () => {
      return {};
    },
    menu: () => {
      return {};
    },
    menuList: () => {
      return {};
    },
    option: () => {
      return {};
    },
    multiValue: () => {
      return {};
    },
    multiValueLabel: () => {
      return {};
    },
    input: () => {
      return {};
    },
    singleValue: () => {
      return {};
    },
    placeholder: () => {
      return {};
    }
  };

  let customSelectProps = {
    hideSelectedOptions: false,
    ...selectProps,
    components: {
      SelectContainer: Container,
      ValueContainer: ValueContainer,
      Menu: Menu,
      Option: selectProps.isMulti ? OptionCheckbox : reactSelectComponents.Option,
      IndicatorSeparator: IndicatorSeparator,
      IndicatorsContainer: IndicatorsContainer,
      MultiValue: MultiValue,
    },
    closeMenuOnSelect: !selectProps.isMulti,
    className: selectClassName,
    classNamePrefix: 'select',
    isClearable: false,
    styles: customStyles,
    autoFocus: true,
    placeholder: placeholder ?? INPUT_PLACEHOLDER_DEFAULT,
    onChange: (newValue: ValueType<OptionType, true>, actionMeta: ActionMeta<OptionType>, triggeredByBtn = false) => {
      if (onChange)
        onChange(newValue, actionMeta);
      if (!selectProps.isMulti)
        document.body.dispatchEvent(new Event('click'));
    }
  }

  function getDefaultLabel(): string {
    if (selectLabel)
      return selectLabel;
    if (!selectProps.isMulti && selectProps.value) {
      if (Array.isArray(selectProps.value))
        return selectProps.value[0].label;
      else if (typeof selectProps.value === 'object' && selectProps.value.hasOwnProperty('label'))
        // @ts-ignore
        return selectProps.value.label;
    }
    return '';
  }

  function getSelectLabel(): string {
    if (!selectProps.isMulti) {
      if (selectProps.value && typeof selectProps.value === 'object' && selectProps.value.hasOwnProperty('label'))
        // @ts-ignore
        return selectProps.value.label;
      return getDefaultLabel();
    } else if (selectLabel) {
      if (selectProps.value && selectProps.value.length > 0)
        return selectLabel + ' (' + selectProps.value.length + ')';
      return selectLabel;
    }
    return '';
  }

  return (
    <Dropdown
      buttonText={getSelectLabel()}
      className="isSelect"
      onClickReset={onClickReset}
      showReset={showReset}
    >
      {loading
        ? <Loader mode="inverted"/>
        : <ReactSelect
          {...customSelectProps}
          mode={'default'}
        />
      }
    </Dropdown>
  );
}

Select.defaultProps = {
  mode: 'default'
}