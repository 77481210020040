import React from "react";
import {getMonth, getYear} from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

type datePickerProps = {
  onChangeDate: (startDate_: Date) => void,
  startDate: Date | string | null | undefined,
}

class DatePickerComponent extends React.Component<datePickerProps, {}> {
  constructor(props: datePickerProps) {
    super(props);

    this.renderCustomHeader = this.renderCustomHeader.bind(this);
  }

  renderCustomHeader(params: {
    date: Date;
    changeYear(year: number): void;
    changeMonth(month: number): void;
    decreaseMonth(): void;
    increaseMonth(): void;
    prevMonthButtonDisabled: boolean;
    nextMonthButtonDisabled: boolean;
    decreaseYear(): void;
    increaseYear(): void;
    prevYearButtonDisabled: boolean;
    nextYearButtonDisabled: boolean;
  }) {
    const year = (new Date()).getFullYear();
    const years = Array.from(new Array(20), (val, index) => index + year);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];

    return (
      <div
        style={{
          margin: 10,
          display: "flex",
          justifyContent: "center"
        }}
      >
        <button className="date__picker__month--previous" onClick={params.decreaseMonth} disabled={params.prevMonthButtonDisabled}>
        </button>
        <select
          className="date__picker__year--select"
          value={getYear(params.date)}
          onChange={({target: {value}}) => params.changeYear(Number(value))}
        >
          {years.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>

        <select
          className="date__picker__month--select"
          value={months[getMonth(params.date)]}
          onChange={({target: {value}}) =>
            params.changeMonth(months.indexOf(value))
          }
        >
          {months.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>

        <button className="date__picker__month--next" onClick={params.increaseMonth} disabled={params.nextMonthButtonDisabled}>
        </button>
      </div>
    )
  };

  render() {
    return (
      <div className="date__calendar">
        <div className="date__buttons">
          {this.props.startDate
            ? <DatePicker
              selected={new Date(this.props.startDate)}
              onChange={this.props.onChangeDate}
              dateFormat={'Y-M-d'}
              showMonthDropdown
              isClearable
              showYearDropdown
              renderCustomHeader={this.renderCustomHeader}
              popperProps={{strategy: 'fixed'}}
            />
            : <DatePicker
              className="date__picker__placeholder"
              onChange={this.props.onChangeDate}
              dateFormat={'Y-M-d'}
              placeholderText={'Please click to set next action date.'}
              renderCustomHeader={this.renderCustomHeader}
              popperProps={{strategy: 'fixed'}}
            />
          }
        </div>
      </div>
    )
  }
}

export default DatePickerComponent;
