import React from "react";
import deviceDownload from "../../types/deviceDownload";
import Loader from "../Loader";
import DownloadEntry from "../DownloadEntry";
import ApiContext from "../Context/ApiContext";

type deviceTabDownloadsProps = {
  isSoftware?: boolean
  deviceId: number
}

type deviceTabDownloadsState = {
  downloads: deviceDownload[],
  loaded: boolean
}

class DeviceTabDownloads extends React.Component<deviceTabDownloadsProps, deviceTabDownloadsState> {
  constructor(props: deviceTabDownloadsProps) {
    super(props);

    this.state = {
      loaded: false,
      downloads: []
    }
  }

  componentDidMount(): void {
    if(this.props.deviceId) {
      this.context.getDownloads(this.props.deviceId).then((devicesObj: {count: number, data: deviceDownload[]}) => {
        this.setState({
          loaded: true,
          downloads: devicesObj.data
        })
      })
    }
  }

  render() {
    return (
      this.state.loaded ? (
        <>
          <b className="download__information">
            All documents are displayed in the latest, current version. Please compare the serial number mentioned in the document with the serial number of your product. <br/>
            If you need a previous version for your product, please contact your support contact.
          </b>
          { this.state.downloads.map((downloadEntry_, i_) => {
            return (
              <div key={i_} className="row">
                <div className="white-bg col-12">
                  { downloadEntry_?.file?.uri ? (
                    <a href={downloadEntry_?.file?.uri} className="download" target="_blank" rel="noopener noreferrer" title="Download">
                      <DownloadEntry download={downloadEntry_} />
                    </a>
                    ) : (
                      <div className="download no__link">
                        <DownloadEntry download={downloadEntry_} />
                      </div>
                    )
                  }
                </div>
              </div>
            )
            })
          }
        </>
        ) : (
        <Loader mode="inverted" className="downloads" />
      )
    )
  }
}

DeviceTabDownloads.contextType = ApiContext;

export default DeviceTabDownloads;
