export const USER_STATUS_NEW = 'new';
export const USER_STATUS_VALIDATED = 'validated';
export const USER_STATUS_VALIDATION_FAILED = 'validation_failed';
export const USER_STATUS_ACTIVE = 'active';
export const USER_STATUS_INACTIVE = 'inactive';
export const USER_STATUS_DELETED = 'deleted';

type userStatus =
  typeof USER_STATUS_NEW |
  typeof USER_STATUS_VALIDATED |
  typeof USER_STATUS_VALIDATION_FAILED |
  typeof USER_STATUS_ACTIVE |
  typeof USER_STATUS_INACTIVE |
  typeof USER_STATUS_DELETED;

export default userStatus;
