import React from "react";
import classNames from "classnames";

export type inputMode = 'normal' | 'highlighted' | 'white' | 'error' | 'login' | 'white-bordered';

interface inputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  mode?: inputMode
  category?: string
  // @ts-ignore
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void
  onClickReset?: () => void
  addFormGroup?: boolean
  inputRef?: React.Ref<HTMLInputElement>
  showReset?: boolean
}

class InputText extends React.Component<inputProps> {
  static defaultProps = {
    addFormGroup: true
  }

  render() {
    const {
      mode,
      className,
      category,
      type,
      onClickReset,
      addFormGroup,
      inputRef,
      showReset,
      ...inputProps
    } = this.props;
    const classes = {
      'form-control': true,
      ['form-control--' + this.props.mode]: !!this.props.mode,
      [className + '']: !!className,
      'has-reset': showReset && !!onClickReset,
    };

    let input = (
      <>
        <input
          onKeyDown={this.props.onKeyDown}
          data-category={category}
          type={type ?? 'text'}
          className={classNames(classes)}
          ref={inputRef}
          {...inputProps}
        />
        {(showReset && onClickReset) &&
          // @ts-ignore
          <span onClick={onClickReset} className="resetFilter"></span>
        }
      </>
    );

    return (
      <>
        {addFormGroup
          ? <div className="form-group searchFilterWrapper">
            {input}
          </div>
          : input
        }
      </>
    )
  }
}

// export default InputText;

export default React.forwardRef<HTMLInputElement, inputProps>((props, ref: React.Ref<HTMLInputElement>) => (
  <InputText inputRef={ref} {...props}/>
));