import React, {RefObject} from 'react'
import InputText, {inputMode} from './InputText'
import classNames from 'classnames'
import Loader from './Loader'
import {TYPING_DELAY} from '../types/filter'

export interface autosuggestSuggestion {
  id: number | string
  text: string
}

interface autosuggestState {
  showDropdown: boolean
  hasFocus: boolean
  selectedSuggestionIndex: number
  typingTimeout?: ReturnType<typeof setTimeout>,
  wasSelected: boolean
}

interface autosuggestProps {
  id?: string
  className?: string
  mode?: inputMode
  addFormGroup?: boolean
  placeholder?: string
  minCharacters?: number
  loading: boolean
  suggestions?: autosuggestSuggestion[]
  onSearch: (sWord: string, hasMinCharakters: boolean) => void
  onSubmit: (suggestionId?: number | string) => void
  addResetBtn?: boolean
  typingDelay?: number
}

export default class Autosuggest extends React.Component<autosuggestProps, autosuggestState> {
  static defaultProps = {
    addFormGroup: true,
    minCharacters: 3,
    typingDelay: TYPING_DELAY,
  }
  static dropdownOffset = 6
  static dropdownClass = 'autosuggest__dropdown'

  input: RefObject<HTMLInputElement>
  dropdown: RefObject<HTMLDivElement>

  constructor(props: autosuggestProps) {
    super(props)

    // init state
    this.state = {
      showDropdown: false,
      hasFocus: false,
      selectedSuggestionIndex: 0,
      wasSelected: false,
    }

    // create refs
    this.input = React.createRef<HTMLInputElement>()
    this.dropdown = React.createRef<HTMLDivElement>()

    // bind this
    this.onChange = this.onChange.bind(this)
    this.onKeyUp = this.onKeyUp.bind(this)
    this.updateDropdownPosition = this.updateDropdownPosition.bind(this)
    this.updateShowDropdown = this.updateShowDropdown.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onClickInput = this.onClickInput.bind(this)
    this.hasMinCharLength = this.hasMinCharLength.bind(this)
    this.onDocumentMouseDown = this.onDocumentMouseDown.bind(this)
    this.onClickReset = this.onClickReset.bind(this)
  }

  componentDidMount() {
    let scrollContainer = window
    if (this.input.current && this.input.current.closest('.popup__scroll'))
      scrollContainer = this.input.current.closest('.popup__scroll')
    scrollContainer.addEventListener('scroll', this.updateDropdownPosition, {passive: true})
    window.addEventListener('resize', this.updateDropdownPosition, {passive: true})
    window.addEventListener('mousedown', this.onDocumentMouseDown, {passive: true})
    this.updateShowDropdown()
  }

  componentWillUnmount() {
    let scrollContainer = window
    if (this.input.current && this.input.current.closest('.popup__scroll'))
      scrollContainer = this.input.current.closest('.popup__scroll')
    scrollContainer.removeEventListener('scroll', this.updateDropdownPosition)
    window.removeEventListener('resize', this.updateDropdownPosition)
    window.removeEventListener('mousedown', this.onDocumentMouseDown)
  }

  onDocumentMouseDown(event: MouseEvent) {
    // @ts-ignore
    if (!((this.input.current && event.target === this.input.current) || event.target.closest('.' + Autosuggest.dropdownClass)))
      this.setState({hasFocus: false}, this.updateShowDropdown)
  }

  hasMinCharLength(): boolean {
    if (!this.props.minCharacters || !this.input.current)
      return true
    return !!(this.input.current.value && this.input.current.value.length >= this.props.minCharacters)
  }

  updateDropdownPosition() {
    if (this.input.current && this.dropdown.current) {
      const popupElement = this.input.current.closest('.popup__wrapper')
      const inputBoundings = this.input.current.getBoundingClientRect()
      this.dropdown.current.style.width = this.input.current.offsetWidth + 'px'
      let droptownTop = inputBoundings.top + this.input.current.offsetHeight + Autosuggest.dropdownOffset
      let droptownLeft = inputBoundings.left
      if (popupElement) {
        const popupBoundings = popupElement.getBoundingClientRect()
        droptownTop -= popupBoundings.top
        droptownLeft -= popupBoundings.left
      }
      this.dropdown.current.style.top = droptownTop + 'px'
      this.dropdown.current.style.left = droptownLeft + 'px'
    }
  }

  updateShowDropdown() {
    if (this.state.hasFocus && this.hasMinCharLength())
      this.setState({showDropdown: true}, this.updateDropdownPosition)
    else
      this.setState({showDropdown: false})
  }

  onChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.updateShowDropdown()
    if (this.hasMinCharLength()) {
      if (this.state.typingTimeout)
        clearTimeout(this.state.typingTimeout)
      let value = event.target.value
      this.setState({
        typingTimeout: setTimeout(() => {
          this.props.onSearch(value, true)
          this.setState({typingTimeout: undefined})
        }, this.props.typingDelay === undefined ? TYPING_DELAY : this.props.typingDelay),
      })
    } else {
      this.props.onSearch(event.target.value, false)
    }
  }

  onKeyUp(event: React.KeyboardEvent<HTMLInputElement>) {
    if (this.state.showDropdown && this.props.suggestions && this.props.suggestions.length && !this.props.loading && this.dropdown.current) {
      let newIndex: number | undefined

      switch (event.key) {
        case 'Enter':
          event.preventDefault()
          this.setState({
            hasFocus: false,
            wasSelected: true,
          }, () => {
            this.updateShowDropdown()
            if (this.props.suggestions && this.props.suggestions.length) {
              this.props.onSubmit(this.props.suggestions[this.state.selectedSuggestionIndex].id)
              if (this.input.current) {
                this.input.current.blur()
                this.input.current.value = this.props.suggestions[this.state.selectedSuggestionIndex].text
              }
            } else if (!this.hasMinCharLength()) {
              this.props.onSubmit()
            }
          })
          break
        case 'ArrowUp':
          event.preventDefault()
          if (this.state.selectedSuggestionIndex > 0)
            newIndex = this.state.selectedSuggestionIndex - 1 // go one entry up
          else if (this.state.selectedSuggestionIndex === 0 && this.props.suggestions.length > 1)
            newIndex = this.props.suggestions.length - 1 // jump to last entry
          break
        case 'ArrowDown':
          event.preventDefault()
          if (this.props.suggestions.length > this.state.selectedSuggestionIndex + 1)
            newIndex = this.state.selectedSuggestionIndex + 1 // go one entry down
          else if (this.state.selectedSuggestionIndex === this.props.suggestions.length - 1)
            newIndex = 0 // go to first entry
          break
      }

      if (newIndex !== undefined && newIndex !== this.state.selectedSuggestionIndex) {
        this.setState({selectedSuggestionIndex: newIndex})
      }
    }
  }

  onClickInput() {
    this.setState({hasFocus: true}, this.updateShowDropdown)
  }

  onFocus() {
    this.setState({hasFocus: true}, this.updateShowDropdown)
  }

  onBlur() {
    this.updateShowDropdown()
  }

  onClickEntry(suggestionIndex: number, suggestionId: number | string) {
    this.setState({
      selectedSuggestionIndex: suggestionIndex,
      showDropdown: false,
      hasFocus: false,
      wasSelected: true,
    }, () => {
      this.updateShowDropdown()
      if (this.input.current && this.props.suggestions)
        this.input.current.value = this.props.suggestions[this.state.selectedSuggestionIndex].text
    })
    this.props.onSubmit(suggestionId)
  }

  onClickReset() {
    this.setState({
      wasSelected: false,
      selectedSuggestionIndex: 0,
      showDropdown: false,
      hasFocus: false,
    }, () => {
      if (this.input.current)
        this.input.current.value = ''
      this.props.onSubmit()
    })
  }

  render() {
    const className = classNames({
      'autosuggest': true,
      'form-group': this.props.addFormGroup,
      [this.props.className + '']: !!this.props.className,
    })

    return (
      <div className={className}>
        <InputText
          id={this.props.id}
          mode={this.props.mode}
          addFormGroup={false}
          placeholder={this.props.placeholder}
          ref={this.input}
          onChange={this.onChange}
          onKeyUp={this.onKeyUp}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onClick={this.onClickInput}
          autoComplete="off"
          onClickReset={this.onClickReset}
          showReset={this.props.addResetBtn && this.state.wasSelected}
        />
        {this.state.showDropdown &&
          <div className={Autosuggest.dropdownClass} ref={this.dropdown}>
            {this.props.loading
              ? <Loader mode="inverted"/>
              : (this.props.suggestions && this.props.suggestions.length)
                ? <ul className="autosuggest__resultlist nolist">
                  {this.props.suggestions.map((suggestion, index) => {
                    let entryClassName = 'autosuggest__result'
                    if (this.state.selectedSuggestionIndex === index)
                      entryClassName += ' selected'
                    return <li key={index} className={entryClassName} onClick={event => this.onClickEntry(index, suggestion.id)}>{suggestion.text}</li>
                  })}
                </ul>
                : this.state.typingTimeout
                  ? <Loader mode="inverted"/>
                  : <>No results</>
            }
          </div>
        }
      </div>
    )
  }
}