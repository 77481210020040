import {stringKeyObject} from "../types/common";
import {
  USER_ROLE_ADMIN,
  USER_ROLE_COMPANY_ADMIN,
  USER_ROLE_USER,
  USER_ROLE_SERVICE_USER,
  USER_ROLE_SUBSIDIARY_ADMIN,
  USER_ROLE_SUBSIDIARY,
  USER_ROLE_REPRESENTATIVE_ADMIN,
  USER_ROLE_REPRESENTATIVE
} from "../types/userRole";

class UserRoleService {
  static roles: stringKeyObject = {
    [USER_ROLE_ADMIN]: 'admin',
    [USER_ROLE_COMPANY_ADMIN]: 'company admin',
    [USER_ROLE_USER]: 'user',
    [USER_ROLE_SERVICE_USER]: 'service user',
    [USER_ROLE_SUBSIDIARY_ADMIN]: 'subsidiary admin',
    [USER_ROLE_SUBSIDIARY]: 'subsidiary ',
    [USER_ROLE_REPRESENTATIVE_ADMIN]: 'representative admin',
    [USER_ROLE_REPRESENTATIVE]: 'representative'
  }

  static editUserCompanyRoles: string[] = [
    USER_ROLE_SERVICE_USER,
    USER_ROLE_SUBSIDIARY_ADMIN,
    USER_ROLE_REPRESENTATIVE_ADMIN
  ]

  static getRoleLabel(role: string): string {
    return UserRoleService.roles.hasOwnProperty(role) ? UserRoleService.roles[role] : role;
  }

  static getRoleLabelsByArray(roles: string[]): string {
    let roleLabels: string[] = [];
    roles.forEach(role => {
      roleLabels.push(UserRoleService.getRoleLabel(role));
    });
    return roleLabels.join(', ');
  }
}

export default UserRoleService;
