import React from 'react'
import user from "../../types/user";
import ApiContext from "../Context/ApiContext";
import AuthContext from "../Context/AuthContext";

type AuthProviderStates = {
  isAuth: boolean,
  authFinished: boolean,
  user: user | undefined,
}

class AuthProvider extends React.Component<{}, AuthProviderStates> {
  constructor(props: {}) {
    super(props);
    this.state = {
      isAuth: false,
      authFinished: false,
      user: undefined,
    };
  }

  componentDidMount() {
    this.context.getUser().then((response: user | undefined) => {
        if(response) {
          // get support salesman ID for requests out of device
          // const params = {
          //   'filter[type][eq]':'Support'
          // };

          this.setState({
            isAuth: true,
            authFinished: true,
            user: response
          });
        } else {
          this.setState({
            authFinished: true,
          });
        }
      }).catch(() => {
        this.setState({
          authFinished: true,
        });
      })
  }

  render() {
    return (
      <AuthContext.Provider
        value={{
          isAuth: this.state.isAuth,
          user: this.state.user,
          authFinished: this.state.authFinished
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}

AuthProvider.contextType = ApiContext;

export default AuthProvider;
