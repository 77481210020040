import {components, OptionProps} from 'react-select';
import {OptionType, GroupTypeDefault} from './SelectTypes';
import SelectionButton from "./SelectionButton";
import React from "react";

export default function OptionCheckbox(props: OptionProps<OptionType, true, GroupTypeDefault>) {
  let {children, ...optionProps} = props;

  return (
    <components.Option {...optionProps}>
      <SelectionButton
        mode="checkbox"
        label={children}
        dimensions={optionProps.selectProps.mode === 'filter' ? 'sm' : 'md'}
        checked={optionProps.isSelected}
        disabled={optionProps.isDisabled}
        onChange={() => false}
        id={props.innerProps.id + '-checkbox'}
      />
    </components.Option>
  );
}