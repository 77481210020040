import {selectionButtonProps} from "./SelectionButtonTypes";
import classNames from "classnames";
import React, {useRef, useState} from "react";

function SelectionButton(props: selectionButtonProps) {
  let inputRef = useRef<HTMLInputElement>(null);
  let [isChecked, setIsChecked] = useState(props.checked ?? props.defaultChecked);
  let {mode, dimensions, label, ...defaultProps} = props;
  defaultProps.type = mode === 'radio' ? 'radio' : 'checkbox';

  const inputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(inputRef.current?.checked);

    if (props.onChange) {
      props.onChange(e);
    }
  };

  const inputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (inputRef.current)
      e.target.checkValidity();
    if (props.onBlur)
      props.onBlur(e);
  };

  const onClickLabel = (e: React.MouseEvent<HTMLLabelElement>) => {
    if (inputRef.current) {
      setIsChecked(inputRef.current.checked);
    }
  };

  let className = classNames({
    'selection-btn': true,
    ['selection-btn--' + mode]: true,
    ['selection-btn--' + dimensions]: true,
    'is-checked': isChecked
  });

  return (
    <div className={className}>
      <input
        onChange={e => inputChanged(e)}
        onBlur={e => inputBlur(e)}
        ref={inputRef}
        {...defaultProps}
      />
      <label htmlFor={props.id} className="selection-btn__label" onClick={event => onClickLabel(event)}>
        <span className="selection-btn__box"></span>
        <span>{label}</span>
      </label>
    </div>
  );
}

SelectionButton.defaultProps = {
  mode: 'checkbox',
  dimensions: 'lg'
};

export default SelectionButton;