import React from "react";
import deviceDownload from "../types/deviceDownload";
import SizeVisual from "./SizeVisual";

interface dateVisualProps {
  download: deviceDownload
}

class DownloadEntry extends React.Component<dateVisualProps> {
  render() {
    const download = this.props.download;
    return (
      <div className="row">
        <div className="col-12 col-sm-auto download__entry__format">
          <div className="format--pdf"></div>
        </div>
        <div className="col download__content__wrapper">
          { download?.file?.uri ? (
            <div className="download__title">
              { download?.file?.title ? (
                <>{ download?.file?.title } </>
              ): (
                <> { download?.file?.name } </>
              )}
              ({download?.file?.languageLabel? download.file.languageLabel+', ':('')}<SizeVisual decimals={1} bytes={download?.file?.size} />)
            </div>
          ) : (
            <span className="download__not__found">
              The requested document (document no. { download?.documentNumber }) could not be found.
              Please contact your local support to order this document.
            </span>
          )}
        </div>
        <div className="col-12 col-sm-auto">
          <div className="download__link"></div>
        </div>
      </div>
    )
  }
}

export default DownloadEntry;
