import React from "react";

class Logo extends React.Component {
  render() {
    return (
      <svg version='1.1' width="150" height="53" viewBox="0 0 150 53" >
        <g fillRule={'evenodd'} clipRule={'evenodd'} fill={'#E30613'}>
          <polygon className="st0" points="85.53,0 98.29,0 91.86,7.91 85.53,0 	"/>
          <path fill={'#fff'} className="st1" d="M58.23,9.5h9.29c6.08,0,10.02,3.06,10.02,9.18c0,6.03-4.55,9.18-10.34,9.18h-5.77v14.01h-3.2V9.5L58.23,9.5z
            M61.43,25.08h3.54c4.82,0,9.37-0.56,9.37-6.4c0-4.08-2.32-6.4-6.4-6.4h-6.51V25.08L61.43,25.08z M92.96,41.87h2.91V12.28h10.47
            V9.5H92.96V41.87L92.96,41.87z M140.13,41.87h-3.8l-8.47-28.76h-0.09l-8.61,28.76h-3.85L106.94,9.5h3.29l7.06,28.76h0.09
            l8.57-28.76h3.85l8.43,28.76h0.09l7.2-28.76h3.06L140.13,41.87L140.13,41.87z M90.79,9.5H75.67v2.78h12.05v29.59h3.07V9.5
            L90.79,9.5z"/>
        </g>
        <g fillRule={'evenodd'} clipRule={'evenodd'}>
          <path fill={'#E30B17'} className="st2" d="M0,44.48V23.61h3.16v2.93c0.65-1.02,1.53-1.84,2.61-2.47c1.09-0.62,2.33-0.93,3.71-0.93
            c1.55,0,2.81,0.32,3.8,0.96c0.99,0.64,1.69,1.54,2.09,2.69c1.65-2.44,3.8-3.66,6.45-3.66c2.07,0,3.66,0.57,4.78,1.72
            s1.67,2.91,1.67,5.3v14.33h-3.52V31.33c0-1.42-0.11-2.43-0.34-3.06c-0.23-0.62-0.65-1.12-1.25-1.5c-0.6-0.38-1.31-0.57-2.12-0.57
            c-1.47,0-2.69,0.49-3.66,1.46c-0.97,0.98-1.45,2.54-1.45,4.69v12.13H12.4V30.92c0-1.57-0.29-2.75-0.86-3.54
            c-0.58-0.79-1.52-1.18-2.83-1.18c-1,0-1.92,0.26-2.76,0.79c-0.84,0.52-1.46,1.29-1.84,2.3c-0.38,1.01-0.57,2.46-0.57,4.36v10.83H0z
            "/>
          <path fill={'#E30B17'} className="st2" d="M33.37,52.52l-0.39-3.32c0.77,0.21,1.45,0.31,2.02,0.31c0.79,0,1.42-0.13,1.89-0.39
            c0.47-0.26,0.86-0.63,1.16-1.1c0.22-0.35,0.58-1.23,1.08-2.63c0.07-0.2,0.17-0.49,0.31-0.87l-7.92-20.91h3.81l4.34,12.09
            c0.56,1.53,1.07,3.14,1.51,4.83c0.41-1.62,0.89-3.21,1.45-4.76l4.46-12.17h3.54l-7.94,21.23c-0.85,2.29-1.51,3.87-1.99,4.74
            c-0.63,1.17-1.35,2.02-2.16,2.57c-0.81,0.54-1.78,0.82-2.91,0.82C34.97,52.95,34.21,52.81,33.37,52.52z"/>
        </g>
      </svg>
    )
  }
}

export default Logo;
