import React from "react";
import {FOOTER_LINKS} from "../static/footerLinks";
import {Link} from "react-router-dom";
import classNames from "classnames";

type footerStates = {
  mobileOpen: boolean
}

class Footer extends React.Component<{}, footerStates> {
  constructor(props: {}) {
    super(props);

    this.state = {
      mobileOpen: false
    };

    this.onClickMobileOpen=this.onClickMobileOpen.bind(this);
  }

  onClickMobileOpen() {
    this.setState({
      mobileOpen: !this.state.mobileOpen
    })
  }

  render() {
    let footerClasses = classNames({
      'footer--open': this.state.mobileOpen
    });

    return (
      <footer className={footerClasses}>
        <div className="footer__inner">
          <Link to="#" className="footer__toggle" onClick={this.onClickMobileOpen}>
            <span className="open">...</span>
            <span className="close">+</span>
          </Link>
          <nav id="navigation-footer">
            <ul>
              {
                FOOTER_LINKS.map((el_, i_) => {
                  return (
                    <li key={i_}>
                      <a rel="noopener noreferrer" href={el_.url} target="_blank">
                        <span>{el_.label}</span>
                      </a>
                    </li>
                  )
                })
              }
            </ul>
          </nav>
        </div>
      </footer>
    );
  }
}

export default Footer;
