import {AlertComponentPropsWithStyle} from "react-alert";
import React from "react";

const AlertTemplate = ({style, options, message, close}: AlertComponentPropsWithStyle) => (
  <div style={style} className={"alert alert--" + options.type}>
    <div className="alert__message">
      {message}
    </div>
    <button className="alert__close" onClick={close}>
      <span>Close</span>
    </button>
  </div>
);

export default AlertTemplate;
