import React, {ChangeEvent, RefObject} from "react";
import ApiContext from "../Context/ApiContext";
import {company, companyRelation} from "../../types/company";
import InputText from "../InputText";
import Button from "../Button";
import Loader from "../Loader";
import {AlertManager, withAlert} from "react-alert";

interface updateInternalIdProps {
  companyRelation: companyRelation
  alert: AlertManager
  onIdUpdated?: (updatedCompanyRelation: companyRelation) => void
}

interface updateInternalIdState {
  loading: boolean
  companyRelation: companyRelation
  company: company,
  btnSaveDisabled: boolean
}

class UpdateInternalId extends React.Component<updateInternalIdProps, updateInternalIdState> {
  input: RefObject<HTMLInputElement>;

  constructor(props: updateInternalIdProps) {
    super(props);

    this.state = {
      loading: false,
      companyRelation: this.props.companyRelation,
      company: this.props.companyRelation.relatedCompany,
      btnSaveDisabled: true
    }

    this.input = React.createRef<HTMLInputElement>();

    this.updateRelatedCompany = this.updateRelatedCompany.bind(this);
    this.onInputKeyDown = this.onInputKeyDown.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  updateRelatedCompany() {
    if (this.input.current && this.input.current.value !== this.state.company.internalId) {
      let newId = this.input.current.value;
      this.setState({loading: true}, () => {
        if (this.input.current && newId !== this.state.company.internalId) {
          this.context.updateCompany(this.state.company.id, {
            internalId: newId
          }).then((updatedCompany: company) => {
            let updatedCompanyRelation = this.state.companyRelation;
            updatedCompanyRelation.relatedCompany = updatedCompany;
            this.setState({
              company: updatedCompany,
              companyRelation: updatedCompanyRelation,
              loading: false
            }, () => {
              if (this.props.onIdUpdated)
                this.props.onIdUpdated(updatedCompanyRelation);
              this.props.alert.success('Internal Company ID successfully saved');
            });
          }).catch(() => {
            if (this.input.current)
              this.input.current.value = this.state.company.internalId;
            this.setState({
              loading: false
            });
          });
        }
      });
    }
  }

  onInputKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter')
      this.updateRelatedCompany();
  }

  onInputChange(event: ChangeEvent<HTMLInputElement>) {
    this.setState({btnSaveDisabled: event.target.value === this.state.company.internalId});
  }

  render() {
    return (
      <div className="company__internal-id">
        <div className="internal-id__title">Internal Customer Number</div>
        {this.state.company
          ? <div className="input-group">
            <InputText
              id={'company-' + this.state.company.companyId + '_internal-id'}
              defaultValue={this.state.company.internalId}
              mode="white-bordered"
              inputRef={this.input}
              onKeyDown={this.onInputKeyDown}
              onChange={this.onInputChange}
            />
            <Button onClick={this.updateRelatedCompany} disabled={this.state.btnSaveDisabled}>Save</Button>
          </div>
          : <>Company couldn't be loaded. Please try again or contact an administrator.</>}
        {this.state.loading &&
          <Loader mode="inverted"/>
        }
      </div>
    );
  }
}

UpdateInternalId.contextType = ApiContext;

export default withAlert<updateInternalIdProps>()(UpdateInternalId);