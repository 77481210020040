import React from "react";
import classNames from "classnames";

const statusDotColorDefault = 'orange';
export type statusDotColor = typeof statusDotColorDefault | 'red' | 'green';

interface statusDotProps {
  color?: statusDotColor
  title?: string
}

export class StatusDot extends React.Component<statusDotProps> {
  render() {
    let {color} = this.props;
    if (!color)
      color = statusDotColorDefault;
    let className = classNames({
      'status-dot': true,
      ['status-dot--' + color]: true
    });

    return (
      <span className={className} title={this.props.title}></span>
    );
  }
}