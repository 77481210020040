import React from "react";

class NotFound404 extends React.Component {
  render() {
    return (
      <div className="container text-center mt-5">
        <div className="contentbox">
          <div className="h2 mt-4 mb-4">404 - Page not found!</div>
        </div>
      </div>
    )
  }
}

export default NotFound404;
