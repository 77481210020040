import React from "react";
import address, {companyAddress} from "../../types/address";
import Checkbox from "../Checkbox";
import UpdateInternalId from "./UpdateInternalId";
import Table from "../Table";
import Accordion from "../Accordion";
import {COMPANY_LIST_TABLE_HEADERS} from "./constants";
import {tableData} from "../../types/table";
import {ADDRESS_TABLE_HEADER_LABELS} from "./constants";
import {companyRelation} from "../../types/company";
import ApiContext from "../Context/ApiContext";
import PopUp from "../PopUp";
import CompanyUserPopupContent from "./CompanyUserPopupContent";
import {apiResultCountable} from "../Provider/ApiProvider";
import Loader from "../Loader";

type companyRelationListAccordionProps = {
  companyRelation: companyRelation
  index: number
};

type companyRelationListAccordionState = {
  addresses: companyAddress[]
  addressesLoaded: boolean
  companyRelation: companyRelation
  addressTableCols?: tableData
  loading: boolean
  showPopup: boolean
};

class CompanyRelationListAccordion extends React.Component<companyRelationListAccordionProps, companyRelationListAccordionState> {
  constructor(props: companyRelationListAccordionProps) {
    super(props);

    this.state = {
      addresses: [],
      addressesLoaded: false,
      companyRelation: this.props.companyRelation,
      loading: false,
      showPopup: false
    }

    this.updateRelation = this.updateRelation.bind(this);
    this.loadAddresses = this.loadAddresses.bind(this);
  }

  loadAddresses() {
    if (!this.state.addressesLoaded) {
      this.context.getAddressesByCompany(this.props.companyRelation.relatedCompany.id)
        .then((response: apiResultCountable<address>) => {
          let addressTableCols: tableData = [];
          if (response.data && response.data.length) {
            response.data.forEach(address => {
              addressTableCols.push([
                <>{address.name}</>,
                <>{address.street}</>,
                <>{address.zip} {address.city}</>,
                <>{address.country}</>
              ]);
            });
          }
          this.setState({
            addresses: response.data,
            addressTableCols: addressTableCols,
            addressesLoaded: true
          })
        })
        .catch(() => {
          this.setState({addressesLoaded: true})
        })
      ;
    }
  }

  onSwitchRelationActive(isActive: boolean, relation: companyRelation) {
    relation.active = isActive;
    this.setState({
      loading: true,
      companyRelation: relation
    }, () => {
      this.context.updateCompanyRelation(relation.company.id, relation.id, {
        active: isActive,
      })
        .then((updatedRelation: companyRelation) => {
          this.updateRelation(updatedRelation, () => {
            if (this.state.companyRelation.active) {
              this.setState({
                showPopup: true
              });
            }
          });
        })
        .catch(() => {
          relation.active = !isActive;
          this.setState({
            companyRelation: relation,
            loading: false
          });
        });
    });
  }

  updateRelation(updatedRelation: companyRelation, callback?: () => void) {
    this.setState({
      companyRelation: updatedRelation,
      loading: false
    }, callback);
  }

  render() {
    const company = this.state.companyRelation.relatedCompany;

    return (
      <>
        <PopUp showPopup={this.state.showPopup} onClickCloseIcon={() => this.setState({
          showPopup: false
        })}>
          <div className="container">
            <div className="h1 text-center" style={{marginBottom: '30px'}}>{company.name}</div>
            <div className="popup__userlist">
              {!this.state.loading
                ? (this.state.companyRelation.users && this.state.companyRelation.users.length)
                  ? <CompanyUserPopupContent companyRelation={this.state.companyRelation}/>
                  :
                  <div className="text-center">There are no users available for "{this.state.companyRelation.company.name}"</div>
                : <Loader mode="inverted"/>
              }
            </div>
          </div>
        </PopUp>
        <Accordion
          mode="slim"
          loading={this.state.loading}
          onOpen={this.loadAddresses}
          title={
            <div className={'company__propertylist' + (company.importMessage ? ' has-error' : '')}>
              <div className="list__col list__col--cid"
                   data-title={COMPANY_LIST_TABLE_HEADERS.companyId}>{company.companyId ?? '-'}</div>
              <div className="list__col list__col--active" data-title={COMPANY_LIST_TABLE_HEADERS.active}>
                <Checkbox
                  mode="switch"
                  id={'company-' + company.companyId + '-active-state-'+ this.state.companyRelation.type}
                  checked={this.state.companyRelation.active}
                  onChangeState={e => this.onSwitchRelationActive(e.target.checked, this.state.companyRelation)}
                />
              </div>
              <div className="list__col list__col--iid"
                   data-title={COMPANY_LIST_TABLE_HEADERS.internalId}>{company.internalId ?? '-'}</div>
              <div className="list__col list__col--name"
                   data-title={COMPANY_LIST_TABLE_HEADERS.name}>
                {company.name ?? '-'}
              </div>
            </div>
          }>
          <UpdateInternalId
            companyRelation={this.state.companyRelation}
            onIdUpdated={this.updateRelation}
          />
          {this.state.addressesLoaded
            ? <>
              {(this.state.addressTableCols && this.state.addressTableCols.length) &&
                <div className="company__companies">
                  <Table headLabels={ADDRESS_TABLE_HEADER_LABELS} data={this.state.addressTableCols}/>
                </div>
              }
            </>
            : <Loader mode="inverted"/>
          }
        </Accordion>
      </>
    );
  }
}

CompanyRelationListAccordion.contextType = ApiContext;

export default CompanyRelationListAccordion;