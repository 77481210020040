import {components, ContainerProps} from 'react-select';
import {OptionType, GroupTypeDefault} from './SelectTypes';
import classNames from "classnames";
import React from "react";

export default function Container(props: ContainerProps<OptionType, true, GroupTypeDefault>) {
  let {children, className, ...selectContainerProps} = props;
  let containerClassName = classNames({
    [className + '']: !!className,
    'menu-is-open': selectContainerProps.selectProps.menuIsOpen
  });

  return (
    <components.SelectContainer className={containerClassName} {...selectContainerProps}>
      {children}
    </components.SelectContainer>
  );
}