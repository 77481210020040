import React, {ChangeEvent} from 'react';
import InputText from "./InputText";
import Button from "./Button";
import CreateUserForm from "./CreateUserForm";
import PopUp from "./PopUp";
import ApiContext from "./Context/ApiContext";
import Logo from "./Logo";
import {AxiosResponse} from "axios";
import EnvironmentService from "../services/EnvironmentService";
import {LOCALSTORAGE_KEY_ERROR_NEXT_LOAD, LOCALSTORAGE_KEY_ERROR_NEXT_LOAD_DATE} from "../types/localStorage";
import moment from "moment";

type loginStates = {
  email: string
  showPopup: boolean,
  blockLogin: boolean,
  hasBeenSubmitted: boolean
}

class Login extends React.Component<{}, loginStates> {
  constructor(props: {}) {
    super(props);

    this.state = {
      email: '',
      showPopup: false,
      blockLogin: true,
      hasBeenSubmitted: false,
    };

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onClickLogin = this.onClickLogin.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
    this.login = this.login.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  onChangeInput(e: ChangeEvent<HTMLInputElement>) {
    let blockLogin = false;
    if (!e.target.value.trim().length)
      blockLogin = true;
    if (!e.target.validity.valid)
      blockLogin = true;

    this.setState({
      email: e.target.value,
      blockLogin: blockLogin
    })
  }

  login() {
    if (this.state.email === '') {
      return;
    }

    if (EnvironmentService.loginTestEnabled()) {
      this.context.loginTest(this.state.email);
      return;
    }

    this.context.getLoginUrl(this.state.email).then((response: AxiosResponse<{ url: string }>) => {
      if (response && response.data.url) {
        window.location.replace(response.data.url);
      }
    })
  }

  componentDidMount() {
    let errorMessage = localStorage.getItem(LOCALSTORAGE_KEY_ERROR_NEXT_LOAD);
    let errorMessageDate = localStorage.getItem(LOCALSTORAGE_KEY_ERROR_NEXT_LOAD_DATE);
    if (errorMessage && errorMessageDate) {
      if (moment(new Date()).diff(errorMessageDate, 'd') < 1)
        this.context.alertManager.error(localStorage.getItem(LOCALSTORAGE_KEY_ERROR_NEXT_LOAD));
      setTimeout(function () {
        localStorage.removeItem(LOCALSTORAGE_KEY_ERROR_NEXT_LOAD);
        localStorage.removeItem(LOCALSTORAGE_KEY_ERROR_NEXT_LOAD_DATE);
      }, 8000);
    }
  }

  onClickRegister() {
    this.setState({
      showPopup: true
    });
  }

  onKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter' && !this.state.blockLogin) {
      this.login();
    }
  }

  onClickLogin() {
    this.login();
  }

  onClickCancel() {
    this.setState({
      showPopup: false
    });
  }

  render() {
    let additionalButtonClasses = '';
    if (this.state.hasBeenSubmitted && this.state.blockLogin) {
      additionalButtonClasses += 'emtpy';
    }

    return (
      <div className="login__wrapper">
        <PopUp onClickCloseIcon={this.onClickCancel} showPopup={this.state.showPopup}>
          <CreateUserForm onClickCancel={this.onClickCancel}/>
        </PopUp>
        <div className="login__left">
          <div className="login__intro">
            <h1>
              Welcome to PTW’s Customer Portal
            </h1>
            <p className="intro__text">
              Benefit from a single point of support for all your PTW
              products. <br/>
              Join our customer portal to:
            </p>
            <ul className="ui">
              <li>Manage all your PTW products in one place.</li>
              <li>Stay up-to-date on firmware and software releases.</li>
              <li>Keep track of the calibration status of your PTW detectors.
              </li>
              <li>Access calibration certificates and technical documentation.
              </li>
              <li>Easily submit service and support requests.</li>
            </ul>
            <p>
              Not yet registered? <a onClick={this.onClickRegister.bind(this)} rel="noopener noreferrer" href="/#">Sign up now.</a>
            </p>
          </div>
        </div>
        <div className="login__right">
          <div className="right__inner">
            <div className="contentbox white-bg--highlighted">
              <h3>Login</h3>
              <div className="login__input__wrapper">
                <InputText type={'email'} className={additionalButtonClasses} onKeyDown={this.onKeyDown} onChange={this.onChangeInput} mode="login" placeholder="E-Mail" required/>
                <span className="login__input__icon"></span>
              </div>
              <Button disabled={this.state.blockLogin} onClick={this.onClickLogin}>
                <span>Login</span>
              </Button>
            </div>
            {false &&
              <a href="/#" className={"login__forgot__pw"}>Forgot password?</a>
            }
          </div>
          {false && // TODO: uncomment when used
            <span className="login__info__btn">?</span>
          }
        </div>
        <div className="login__logo">
          <Logo/>
        </div>
      </div>
    )
  }
}

Login.contextType = ApiContext;

export default Login;
