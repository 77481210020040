import React from "react";
import {Link} from "react-router-dom";

class GroupLinks extends React.Component {
  render () {
    return (
      <div>
        <h1>Links</h1>
        <div>
          <Link to="#" className="link">Support request</Link>
        </div>
        <div>
          <Link to="#" className="link link--small">Support request</Link>
        </div>
      </div>
    )
  }
}

export default GroupLinks;
