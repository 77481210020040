import React, {ChangeEvent} from "react";
import ApiContext from "../Context/ApiContext";
import BackButton from "../BackButton";
import InputText from "../InputText";
import {tableData} from "../../types/table";
import {
  companyRelation
} from "../../types/company";
import user from "../../types/user";
import Loader from "../Loader";
import LoadingIndicator from "../LoadingIndicator";
import PageService, {PAGE_COMPANY_OVERVIEW, PAGE_HOME} from "../../services/PageService";
import {AxiosResponse} from "axios";
import {TYPING_DELAY} from "../../types/filter";
import Select from "../Select/Select";
import {OptionType} from "../Select/SelectTypes";
import {ActionMeta, ValueType} from "react-select/src/types";
import FilterService from "../../services/FilterService";
import {COMPANY_LIST_TABLE_HEADERS} from "./constants";
import CompanyRelationListAccordion from "./CompanyRelationListAccordion";

const ITEMS_PER_PAGE = 20;

const COMPANY_STATUS_OPTIONS: OptionType[] = [
  {value: '', label: 'Select status'},
  {value: '1', label: 'active'},
  {value: '0', label: 'inactive'}
]
const COMPANY_STATUS_OPTION_DEFAULT = COMPANY_STATUS_OPTIONS[0];

const COMPANY_ALL_OPTIONS: OptionType[] = [
  {value: '0', label: 'Only companies with devices'},
  {value: '1', label: 'All companies'}
]
const COMPANY_ALL_OPTION_DEFAULT = COMPANY_ALL_OPTIONS[0];

type companyOverviewState = {
  companyRelations: companyRelation[]
  loaded: boolean
  filtered: boolean
  limit: number
  companyRelationCount: number
  companyRelationMax: number
  offset: number
  filterSearch: string
  filterActive: OptionType | null
  filterCompanyAll: OptionType | null
  loadedMore: boolean
  typingSearchTimeout?: ReturnType<typeof setTimeout>
  users: user[]
};

type companyOverviewProps = {
  user: user | undefined
};

type companyFilterConfig = {
  limit: number
  companyRelationCount: number
  filterSearch: string
  filterActive: OptionType | null
  filterCompanyAll: OptionType | null
  lastSaved?: Date | string
};

class CompanyOverview extends React.Component<companyOverviewProps, companyOverviewState> {
  constructor(props: companyOverviewProps) {
    super(props);

    let filter: companyFilterConfig = {
      filterSearch: '',
      filterActive: COMPANY_STATUS_OPTION_DEFAULT,
      filterCompanyAll: COMPANY_ALL_OPTION_DEFAULT,
      limit: ITEMS_PER_PAGE,
      companyRelationCount: ITEMS_PER_PAGE
    };

    let savedFilterConfig = this.getFilterCookie();
    if (savedFilterConfig) {
      filter = savedFilterConfig;
    }

    this.state = {
      companyRelations: [],
      loaded: false,
      filtered: false,
      filterSearch: filter.filterSearch,
      filterActive: filter.filterActive && typeof filter.filterActive === 'object' ? filter.filterActive : COMPANY_STATUS_OPTION_DEFAULT,
      filterCompanyAll: filter.filterCompanyAll && typeof filter.filterCompanyAll === 'object' ? filter.filterCompanyAll : COMPANY_ALL_OPTION_DEFAULT,
      limit: filter.limit, // sets initially loaded company relations and number of "load more" relations
      companyRelationCount: (filter.companyRelationCount > filter.limit) ? filter.companyRelationCount : filter.limit, // companyRelationCount has initially to be equal to limit NOT 0
      companyRelationMax: 0,
      offset: 0,
      loadedMore: true,
      users: []
    }

    this.updateCompanyRelation = this.updateCompanyRelation.bind(this);
    this.onChangeSearchInput = this.onChangeSearchInput.bind(this);
    this.onChangeActiveSelect = this.onChangeActiveSelect.bind(this);
    this.onChangeWithDevicesSelect = this.onChangeWithDevicesSelect.bind(this);
  }

  componentDidMount(): void {
    this.handleCompanyRequest(false, ITEMS_PER_PAGE);
  }

  setFilterCookie() {
    const filterConf: companyFilterConfig = {
      limit: this.state.limit,
      companyRelationCount: this.state.companyRelationCount,
      filterSearch: this.state.filterSearch,
      filterActive: this.state.filterActive,
      filterCompanyAll: this.state.filterCompanyAll,
      lastSaved: new Date()
    };
    localStorage.setItem(FilterService.filterStoreIds.CompanyOverview, JSON.stringify(filterConf));
  }

  /**
   * Return filter config from local storage.
   * If saved config is too old -> delete it and don't return it
   */
  getFilterCookie() {
    let config = localStorage.getItem(FilterService.filterStoreIds.CompanyOverview);
    if (config) {
      let configParsed = JSON.parse(config);
      if (configParsed.hasOwnProperty('lastSaved')) {
        let lastSaved = new Date(configParsed.lastSaved);
        lastSaved.setDate(lastSaved.getDate() + 1);
        if (lastSaved > new Date()) {
          delete configParsed.lastSaved;
          return configParsed;
        }
      }
    }
    localStorage.removeItem(FilterService.filterStoreIds.CompanyOverview);
  }

  handleCompanyRequest(filter?: boolean, limit?: number) {
    if (this.props.user?.company?.id) {
      const params = {
        limit: limit ? limit : this.state.limit,
        offset: this.state.offset
      }

      if (this.state.filterSearch && this.state.filterSearch !== '') {
        const filterSearch = this.state.filterSearch.trim();
        if (filterSearch !== '') {
          Object.assign(params, {
            'filter[company-relations][search]': filterSearch
          });
        }
      }

      if (this.state.filterActive && this.state.filterActive.value !== '') {
        Object.assign(params, {
          'filter[active][eq]': this.state.filterActive.value === '0' ? false : this.state.filterActive.value
        });
      }

      if (this.state.filterCompanyAll) {
        Object.assign(params, {
          'all': this.state.filterCompanyAll.value
        });
      }

      this.context.getCompanyRelations(this.props.user.company.id, params)
        .then((relationsObj: { count: number, data: companyRelation[] }) => {
          let newRelations = relationsObj.data;
          this.setState({
            companyRelations: filter ? newRelations : this.state.companyRelations.concat(newRelations),
            companyRelationMax: relationsObj.count,
            companyRelationCount: this.state.companyRelations.length + newRelations.length,
            loaded: true,
            loadedMore: true,
            filtered: true
          });
        })
        .catch((response: AxiosResponse) => {
          this.setState({
            loaded: true,
            loadedMore: true,
            filtered: true,
            companyRelationCount: this.state.companyRelations.length
          });
        })
      ;
    }
  }

  onClickLoadMore() {
    this.setState({
      loadedMore: false,
      offset: this.state.companyRelationCount,
      companyRelationCount: this.state.companyRelationCount + this.state.limit,
    }, () => {
      this.setFilterCookie();
      this.handleCompanyRequest();
    });
  }

  updateCompanyInState(companyRelation: companyRelation, stateIndex: number) {
    let newRelations = this.state.companyRelations;
    newRelations[stateIndex] = companyRelation;
    this.setState({companyRelations: newRelations, loaded: false}, () => {
      this.setState({loaded: true});
    });
  }

  updateCompanyRelation(eventChecked: boolean, companyRelation: companyRelation, stateIndex: number) {
    let isActive = eventChecked;
    let userIds: number[] = [];
    companyRelation.users.forEach((user) => {
      userIds.push(user.id);
    });

    companyRelation.active = isActive;
    this.updateCompanyInState(companyRelation, stateIndex);

    this.context.updateCompanyRelation(companyRelation.company.id, companyRelation.id, {
      active: isActive,
      users: userIds
    }).then((updatedCompanyRelation: companyRelation) => {
      this.updateCompanyInState(updatedCompanyRelation, stateIndex);
    }).catch(() => {
      companyRelation.active = !isActive;
      this.updateCompanyInState(companyRelation, stateIndex);
    });
  }

  setFilter() {
    this.setState({
      companyRelations: [],
      filtered: false,
      companyRelationCount: ITEMS_PER_PAGE,
      limit: ITEMS_PER_PAGE,
      offset: 0
    }, () => {
      this.setFilterCookie();
      this.handleCompanyRequest(true);
    });
  }

  onChangeSearchInput(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    let searchText = e.target.value;

    if (searchText !== this.state.filterSearch) {
      if (this.state.typingSearchTimeout)
        clearTimeout(this.state.typingSearchTimeout);
      this.setState({
        filterSearch: searchText,
        typingSearchTimeout: setTimeout(() => {
          this.setFilter();
        }, TYPING_DELAY)
      });
    }
  }

  onChangeActiveSelect(value: ValueType<OptionType, false> | null, action: ActionMeta<OptionType>): void {
    this.setState({
      filterActive: value,
    }, this.setFilter);
  }

  onChangeWithDevicesSelect(value: ValueType<OptionType, false> | null): void {
    this.setState({
      filterCompanyAll: value,
    }, this.setFilter);
  }

  render() {
    return (
      <div className="container" id="company-overview">
        <h1 className="pagetitle">{PageService.pagesConfigurations[PAGE_COMPANY_OVERVIEW].title}</h1>
        <BackButton to={PageService.pagesConfigurations[PAGE_HOME].path}>
          Back to {PageService.pagesConfigurations[PAGE_HOME].title}
        </BackButton>
        {this.state.loaded
          ? <div className="contentbox">
            <div className="companies__filter row small-gutter">
              <div className="col-12 col-sm-6 col-lg-4">
                <InputText
                  placeholder="Search Company"
                  mode="highlighted"
                  onChange={this.onChangeSearchInput}
                  value={this.state.filterSearch}
                  showReset={!!(this.state.filterSearch) && this.state.filterSearch !== ''}
                  onClickReset={() => {
                    this.setState({filterSearch: ''}, this.setFilter);
                  }}
                />
              </div>
              <div className="col-12 col-sm-6 col-lg-4">
                <Select
                  menuIsOpen={true}
                  options={COMPANY_STATUS_OPTIONS}
                  // @ts-ignore
                  onChange={this.onChangeActiveSelect}
                  value={this.state.filterActive}
                />
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <Select
                  menuIsOpen={true}
                  options={COMPANY_ALL_OPTIONS}
                  // @ts-ignore
                  onChange={this.onChangeWithDevicesSelect}
                  value={this.state.filterCompanyAll}
                />
              </div>
            </div>

            <div className="companies__list">
              {this.state.filtered
                ? (this.state.companyRelations && this.state.companyRelations.length)
                  ? <>
                    <div className="propertylist__header">
                      <div className="company__propertylist">
                        <div className="list__col list__col--cid">{COMPANY_LIST_TABLE_HEADERS.companyId}</div>
                        <div className="list__col list__col--active">{COMPANY_LIST_TABLE_HEADERS.active}</div>
                        <div className="list__col list__col--iid">{COMPANY_LIST_TABLE_HEADERS.internalId}</div>
                        <div className="list__col list__col--name">{COMPANY_LIST_TABLE_HEADERS.name}</div>
                      </div>
                    </div>

                    {this.state.companyRelations.map((companyRelation, index) => {
                      let relatedCompany = companyRelation.relatedCompany;
                      let companyTableCols: tableData = [];
                      if (relatedCompany.addresses && relatedCompany.addresses.length) {
                        relatedCompany.addresses.forEach(address => {
                          companyTableCols.push([
                            <>{address.name}</>,
                            <>{address.street}</>,
                            <>{address.zip} {address.city}</>,
                            <>{address.country}</>
                          ]);
                        });
                      }

                      return (
                        <CompanyRelationListAccordion
                          key={index}
                          companyRelation={companyRelation}
                          index={index}
                        />
                      );
                    })}

                    {
                      (this.state.companyRelations.length < this.state.companyRelationMax) &&
                      <div className="loading__indicator__wrapper">
                        <div onClick={this.onClickLoadMore.bind(this)}>
                          <LoadingIndicator loading={!this.state.loadedMore} text="Show more"/>
                        </div>
                      </div>
                    }

                  </>
                  : <h3>No Results</h3>
                : <Loader mode="overview"/>
              }

            </div>
          </div>
          : <Loader mode="overview"/>
        }
      </div>
    )
  }
}

CompanyOverview.contextType = ApiContext;

export default CompanyOverview;