import React, {ChangeEvent, ComponentState} from 'react'
import InputText from '../InputText'
import Button from '../Button'
import person from '../../types/Person'
import user from '../../types/user'
import Loader from '../Loader'
import ApiContext from '../Context/ApiContext'
import StatusActionButtons from '../StatusActionButtons'
import UserRoleService from '../../services/UserRoleService'
import {USER_ROLE_SERVICE_USER, userRole, userRoleEntry} from '../../types/userRole'
import Tabs from '../Tabs'
import TabContent from '../TabContent'
import TabCompanies from './TabCompanies'
import {AlertManager, withAlert} from 'react-alert'
import {apiResultCountable} from '../Provider/ApiProvider'
import {USER_STATUS_NEW, USER_STATUS_VALIDATED, USER_STATUS_VALIDATION_FAILED} from '../../types/userStatus'
import {EDIT_ACCOUNT_MODE} from './constants'
import {company} from '../../types/company'
import Autosuggest, {autosuggestSuggestion} from '../Autosuggest'

type userData = {
  firstName?: string,
  lastName?: string,
  email?: string,
  roles?: string[],
  company?: number
}

type editAccountProps = {
  onClickCancel: () => void
  user: user
  handleUserUpdate: (initial: boolean) => void
  roles?: string
  loginuser?: user
  editMode: EDIT_ACCOUNT_MODE
  alert: AlertManager
}

type editAccountStates = {
  email?: string
  firstName?: string
  lastName?: string
  status?: string
  customerNumber?: number
  roles?: userRole[] | string[]
  selectableRoles: userRoleEntry[]
  selectableRolesLoading: boolean
  person?: person
  isValid: boolean
  loaded: boolean
  statusChangersEnabled: boolean,
  company?: number,
  companyName?: string,
  companySuggestions: [],
  companySuggestLoading: boolean,
}

class EditAccount extends React.Component<editAccountProps, editAccountStates> {
  initialUserData: userData = {
    firstName: '',
    lastName: '',
    email: '',
    roles: [],
  }

  constructor(props: editAccountProps) {
    super(props)
    this.onChangeInput = this.onChangeInput.bind(this)
    this.onClickSubmit = this.onClickSubmit.bind(this)
    this.onChangeSelect = this.onChangeSelect.bind(this)
    this.onClickClose = this.onClickClose.bind(this)
    this.checkStatusChangeEnabled = this.checkStatusChangeEnabled.bind(this)
    this.onCompanySuggestSearch = this.onCompanySuggestSearch.bind(this)
    this.onCompanySuggestSubmit = this.onCompanySuggestSubmit.bind(this)

    this.initialUserData = {
      firstName: this.props.user.firstName,
      lastName: this.props.user.lastName,
      email: this.props.user.email,
      roles: this.props.user.roles,
      company: this.props.user.company?.id,
    }

    this.state = {
      ...this.initialUserData,
      status: this.props.user.status,
      customerNumber: this.props.user.customerNumber,
      selectableRoles: [],
      selectableRolesLoading: false,
      isValid: false,
      loaded: true,
      statusChangersEnabled: true,
      companyName: this.props.user.company?.name,
      companySuggestLoading: false,
      companySuggestions: [],
    }
  }

  componentDidMount() {
    if (this.props.loginuser) {
      this.setState({
        selectableRolesLoading: true,
      }, () => {
        if (this.props.loginuser) {
          this.context.getUserRoles(this.props.loginuser.id)
            .then((response: apiResultCountable<userRoleEntry>) => {
              this.setState({
                selectableRoles: response.data,
                selectableRolesLoading: false,
              })
            })
            .catch(() => {
              this.setState({
                selectableRolesLoading: false,
              })
            })
        }
      })
    }
  }

  checkStatusChangeEnabled() {
    const currentUserData: userData = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      roles: this.state.roles,
      company: this.state.company,
    }

    this.setState({
      statusChangersEnabled: JSON.stringify(currentUserData) === JSON.stringify(this.initialUserData),
    })
  }

  onChangeInput(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault()
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    this.setState({
      [e.target.id]: value,
    } as ComponentState, () => {
      this.checkStatusChangeEnabled()
    })
  }

  onChangeSelect(e: ChangeEvent<HTMLSelectElement>) {
    e.preventDefault()
    let status = this.state.status
    let roles = this.state.roles

    if (e && e.target.id === 'status')
      status = e.target.value

    if (e && e.target.id === 'role')
      roles = [e.target.value]

    this.setState({
      status: status,
      roles: roles,
    }, () => {
      this.checkStatusChangeEnabled()
    })
  }

  onClickSubmit(e: React.MouseEvent) {
    e.preventDefault()
    this.setState({
      loaded: false,
    })

    const accountInfo: userData = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      roles: this.state.roles,
      company: this.state.company,
    }

    this.context.updateUser(this.props.user?.id, accountInfo).then(user => {
      this.setState({
        loaded: true,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        status: user.status,
        roles: user.roles,
        customerNumber: user.customerNumber,
        company: user.company.id,
        companyName: user.company.name,
        companySuggestions: [],
      }, () => {
        this.props.handleUserUpdate(true)
        this.setState({
          loaded: true,
        })
        this.props.alert.success(`The account for ${this.state.firstName} ${this.state.lastName} was updated successfully.`)
      })
    }).catch(() => {
      this.setState({
        loaded: true,
      })
      this.props.alert.error(`Wasn't able to update the account for ${this.state.firstName} ${this.state.lastName}`)

      // @ts-ignore
      let popupContainer = document.getElementById('popup-edit-account')
      if (popupContainer)
        popupContainer.scrollTo(0, 0)
    })
  }

  onClickClose() {
    this.props.onClickCancel()
  }

  getSuggestionsFromCompanies(companies: company[]): autosuggestSuggestion[] {
    let suggestions: autosuggestSuggestion[] = []
    if (companies.length > 0) {
      companies.forEach(company => {
        suggestions.push({
          id: company.id,
          text: company.name,
        })
      })
    }
    return suggestions
  }

  onCompanySuggestSearch(sWord: string, hasMinCharakters: boolean) {
    if (sWord && hasMinCharakters) {
      this.setState({
        companySuggestLoading: hasMinCharakters,
        companySuggestions: [],
      }, () => {
        if (hasMinCharakters) {
          this.context.getCompanies({
            'filter[companies][search]': sWord.trim(),
            'limit': 50,
          })
            .then((result: { count: number, data: company[] }) => {
              this.setState({
                companySearchResults: result.data,
                companySuggestLoading: false,
                companySuggestions: this.getSuggestionsFromCompanies(result.data),
              }, () => {
                this.setState({
                  companySuggestLoading: false,
                })
              })
            })
            .catch(() => {
              this.setState({companySuggestLoading: false})
            })
        }
      })
    }
  }

  onCompanySuggestSubmit(suggestionId?: string | number) {
    this.setState({company: parseInt(suggestionId) ?? ''})
  }

  render() {
    let enableCompanyIdInput = false
    if (this.props.user?.status === USER_STATUS_NEW || this.props.user?.status === USER_STATUS_VALIDATION_FAILED)
      enableCompanyIdInput = true

    const companyName = this.state.companyName ?? 'No company was found'

    let userRoleValue: userRole = ''
    if (this.state.roles?.length)
      userRoleValue = this.state.roles[0]

    let emailEnabled = false
    if (this.state.status === USER_STATUS_NEW || this.state.status === USER_STATUS_VALIDATED)
      emailEnabled = true

    let editUserForm = <form>
      <StatusActionButtons
        disabled={!this.state.statusChangersEnabled}
        editMode={this.props.editMode}
        loginuser={this.props.loginuser}
        user={this.props?.user}
        mode="invert"
      />
      <div className="form-row">
        <div className="form-row--with__label">
          <label className="form__label">Customer number</label>
          <InputText
            name="customerNumber"
            id="customerNumber"
            disabled={!enableCompanyIdInput}
            readOnly={!enableCompanyIdInput}
            onChange={this.onChangeInput}
            value={this.state.customerNumber}
          />
        </div>
        <div className="form-row--with__label">
          <label className="form__label">Company Name</label>
          {this.props.loginuser && this.props.loginuser.roles.includes(USER_ROLE_SERVICE_USER)
            ? <Autosuggest
              loading={this.state.companySuggestLoading}
              onSearch={this.onCompanySuggestSearch}
              onSubmit={this.onCompanySuggestSubmit}
              suggestions={this.state.companySuggestions}
              placeholder={companyName}
              minCharacters={3}
              typingDelay={0}
            />
            : <InputText disabled={true} readOnly={true} value={companyName}/>
          }
        </div>
      </div>
      <div className="form-row">
        <div className="form-row--with__label">
          <label className="form__label">Role</label>
          <div className="form-group">

            {this.state.selectableRolesLoading
              ? <Loader mode="xs_inverted"/>
              : <select
                value={userRoleValue}
                className="form-control"
                id="role"
                name="role"
                onChange={this.onChangeSelect}
              >
                {this.state.selectableRoles.map((role, index) => {
                  return <option value={role.key} key={index}>{role.name}</option>
                })}
              </select>
            }

          </div>
        </div>

        <div className="form-row--with__label">
          <label className="form__label">Email</label>
          <InputText
            name="user"
            id="email"
            placeholder="E-Mail Adress*"
            type="email"
            disabled={!emailEnabled}
            onChange={this.onChangeInput}
            required
            value={this.state.email}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="form-row--with__label">
          <label className="form__label">Firstname</label>
          <InputText
            name="user"
            id="firstName"
            placeholder="Firstname*"
            onChange={this.onChangeInput}
            required
            value={this.state.firstName}
          />
        </div>

        <div className="form-row--with__label">
          <label className="form__label">Lastname</label>
          <InputText
            name="user"
            id="lastName"
            placeholder="Lastname*"
            onChange={this.onChangeInput}
            required
            value={this.state.lastName}
          />
        </div>
      </div>

      <div className="form-row form__bottom">
        <Button
          className="cancel__btn"
          type="button"
          onClick={this.props.onClickCancel}
          mode="invert"
        >Cancel</Button>
        <Button className="submit__btn" onClick={this.onClickSubmit}>Update account</Button>
      </div>

    </form>

    return (
      !this.state.loaded
        ? <Loader className="success" mode="inverted"/>
        : <div className="container popup__form">
          <h2 className="popup__form__title">Edit User</h2>
          {this.props.loginuser && this.props.loginuser.roles.some(role => UserRoleService.editUserCompanyRoles.includes(role))
            ? <Tabs activeMobile={true}>
              <TabContent identifier="general" title="General">
                {editUserForm}
              </TabContent>
              {this.props.user.company ?
                <TabContent identifier="companies" title="Associated Companies">
                  <TabCompanies user={this.props.user} editCompanyId={this.props.user?.company?.id}/>
                </TabContent>
                : <></>
              }
            </Tabs>
            : editUserForm
          }
        </div>
    )
  }
}

EditAccount.contextType = ApiContext
export default withAlert<editAccountProps>()(EditAccount)
