import React from 'react'
import classNames from "classnames"

type loadingIndicatorProps = {
  text: string,
  loading?: boolean
}

class LoadingIndicator extends React.Component<loadingIndicatorProps, {}> {
  render() {
    let classes=classNames({
      'button-reset': true,
      'loader': true,
      'loader--loading': this.props.loading
    });
    return (
      <button className={classes}>
        <div className="loader__inner">{this.props.text}</div>
      </button>
    )
  }
}

export default LoadingIndicator;
