import {stringKeyObject} from "../types/common";
import {
  userRole,
  USER_ROLE_REPRESENTATIVE_ADMIN,
  USER_ROLE_SUBSIDIARY_ADMIN,
  USER_ROLE_SERVICE_USER,
  USER_ROLE_ADMIN,
  USER_ROLE_SUBSIDIARY,
  USER_ROLE_REPRESENTATIVE,
  USER_ROLE_COMPANY_ADMIN
} from "../types/userRole";
import user from "../types/user";

export type pageConfig = {
  path: string,
  title: string,
  allowedUserRoles?: userRole[]
}

export const PAGE_HOME = 'home';
export const PAGE_COMPANY_OVERVIEW = 'companies';
export const PAGE_COMPANY_ADMINISTRATION = 'companyAdministration';
export const PAGE_MY_PRODUCTS = 'myProducts';
export const PAGE_PRODUCT_ADRESSES = 'productAddresses';
export const PAGE_SERVICE_CENTER = 'serviceCenter';
export const PAGE_UI = 'ui';
export const PAGE_MY_TEAM = 'myTeam';
export const PAGE_MY_ACCOUNT = 'myAccount';
export const PAGE_USER_MANAGEMENT = 'user-management';
export const PAGE_AUTHORIZE = 'authorize';

export type pageId =
  typeof PAGE_HOME
  | typeof PAGE_COMPANY_OVERVIEW
  | typeof PAGE_COMPANY_ADMINISTRATION
  | typeof PAGE_MY_PRODUCTS
  | typeof PAGE_PRODUCT_ADRESSES
  | typeof PAGE_SERVICE_CENTER
  | typeof PAGE_UI
  | typeof PAGE_MY_TEAM
  | typeof PAGE_MY_ACCOUNT
  | typeof PAGE_USER_MANAGEMENT
  | typeof PAGE_AUTHORIZE
  ;

export default class PageService {
  static pagesConfigurations: stringKeyObject<pageConfig> = {
    [PAGE_HOME]: {
      path: '/',
      title: 'home'
    },
    [PAGE_AUTHORIZE]: {
      path: '/authorize',
      title: ''
    },
    [PAGE_MY_PRODUCTS]: {
      path: '/myproducts',
      title: 'my Products'
    },
    [PAGE_MY_TEAM]: {
      path: '/myteam',
      title: 'my Team',
      allowedUserRoles: [
        USER_ROLE_COMPANY_ADMIN,
        USER_ROLE_SUBSIDIARY_ADMIN,
        USER_ROLE_REPRESENTATIVE_ADMIN
      ]
    },
    [PAGE_MY_ACCOUNT]: {
      path: '/myaccount',
      title: 'my Account'
    },
    [PAGE_USER_MANAGEMENT]: {
      path: '/user-management',
      title: 'User Management',
      allowedUserRoles: [
        USER_ROLE_ADMIN,
        USER_ROLE_SERVICE_USER,
      ]
    },
    [PAGE_SERVICE_CENTER]: {
      path: '/service-center',
      title: 'Service Center'
    },
    [PAGE_UI]: {
      path: '/ui',
      title: 'UI'
    },
    [PAGE_COMPANY_OVERVIEW]: {
      path: '/companies',
      title: 'Companies',
      allowedUserRoles: [
        USER_ROLE_ADMIN,
        USER_ROLE_SERVICE_USER,
        USER_ROLE_SUBSIDIARY_ADMIN,
        USER_ROLE_REPRESENTATIVE_ADMIN
      ]
    },
    [PAGE_COMPANY_ADMINISTRATION]: {
      path: '/company-administration',
      title: 'Company Administration',
      allowedUserRoles: [
        USER_ROLE_ADMIN,
        USER_ROLE_SERVICE_USER
      ]
    },
    [PAGE_PRODUCT_ADRESSES]: {
      path: '/product-addresses',
      title: 'Customer Products',
      allowedUserRoles: [
        USER_ROLE_ADMIN,
        USER_ROLE_SERVICE_USER,
        USER_ROLE_SUBSIDIARY_ADMIN,
        USER_ROLE_SUBSIDIARY,
        USER_ROLE_REPRESENTATIVE_ADMIN,
        USER_ROLE_REPRESENTATIVE
      ]
    }
  }

  static isAllowedForUser(pageId: pageId, userToCheck: user): boolean {
    if (PageService.pagesConfigurations.hasOwnProperty(pageId) && PageService.pagesConfigurations[pageId].hasOwnProperty('allowedUserRoles')) {
      // @ts-ignore
      return userToCheck.roles.some(role => PageService.pagesConfigurations[pageId].allowedUserRoles.includes(role));
    }
    return true;
  }

  static getCurrentDeviceOverviewPageConfig(): pageConfig {
    let pageConfigProductAddresses = PageService.pagesConfigurations[PAGE_PRODUCT_ADRESSES];
    if (window.location.href.includes(pageConfigProductAddresses.path))
      return pageConfigProductAddresses;
    return PageService.pagesConfigurations[PAGE_MY_PRODUCTS];
  }
}