type footerLink = {
  label: string,
  url: string
}

export const FOOTER_LINKS: footerLink[] = [
  {
    label: 'PTW Website',
    url: 'https://www.ptwdosimetry.com/en/'
  },
  {
    label: 'Imprint',
    url: 'https://www.ptwdosimetry.com/en/footer-navigation/imprint/'
  },
  {
    label: 'Terms and Conditions',
    url: 'https://www.ptwdosimetry.com/en/footer-navigation/terms-and-conditions/'
  },
  {
    label: 'Disclaimer',
    url: 'https://www.ptwdosimetry.com/en/footer-navigation/disclaimer/'
  },
  {
    label: 'Privacy Policy',
    url: 'https://www.ptwdosimetry.com/en/footer-navigation/privacy-policy/'
  },
];
