import React from "react";
import BackButton from "../BackButton";
import {RouteChildrenProps, match} from "react-router-dom";
import device from "../../types/device";
import DeviceHeader from "./DeviceHeader";
import Button from "../Button";
import NotFound404 from "../ErrorPages/NotFound404";
import Loader from "../Loader";
import ApiContext from "../Context/ApiContext";
import TabContent from "../TabContent";
import DeviceTabDownloads from "./DeviceTabDownloads";
import Tabs from "../Tabs";
import DeviceTabCertificates from "./DeviceTabCertificates";
import DeviceTabHistory from "./DeviceTabHistory";
import PageService from "../../services/PageService";

type componentDetailParams = {
  deviceId: string,
  componentId: string,
}

interface componentDetailProps extends RouteChildrenProps {
  match: match<componentDetailParams>
  deviceId: number
}

type componentDetailState = {
  loaded: boolean
  component: device | null,
  parentProductName: string
}

class ComponentDetail extends React.Component<componentDetailProps, componentDetailState> {
  constructor(props: componentDetailProps) {
    super(props);

    this.state = {
      loaded: false,
      component: null,
      parentProductName: ''
    }

    this.getDevice = this.getDevice.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.componentId) {
      this.getDevice();

      // get parent device title
      const deviceId = parseInt(this.props.match.params.deviceId);
      this.context.getDevice(deviceId).then((device: device) => {
        this.setState({
          parentProductName: device.description
        })
      });
    }
  }

  getDevice() {
    const componentId = parseInt(this.props.match.params.componentId);
    this.context.getDevice(componentId).then((device: device) => {
      this.setState({
        component: device,
        loaded: true
      })
    });
  }

  onChangeDate() {
    this.getDevice();
  }

  render() {
    const overviewPageConfig = PageService.getCurrentDeviceOverviewPageConfig();

    return (
      <div>
        <div className="container">
          <div className="products-detail">
            {this.state.loaded
              ? <>
                {this.state.component
                  ? <>
                    <BackButton to={overviewPageConfig.path + '/' + this.props.match.params.deviceId}>
                      Back to {this.state.parentProductName}
                    </BackButton>
                    <div className="contentbox">
                      <div className="productbox">
                        <DeviceHeader device={this.state.component}/>
                        <div className="productbox__body white-bg">
                          <div className="row">
                            <div className="col-12 col-lg-6">
                              {this.state.component?.software ? (
                                <h1 className="productbox__title productbox__title--software">{this.state.component?.description}</h1>
                              ) : (
                                <h1 className="productbox__title productbox__title--gear">{this.state.component?.description}</h1>
                              )}
                            </div>
                            <div className="col-12 col-lg-6">
                              {false &&
                                <Button mode="invert">Support request</Button>
                              }
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-12 col-lg-6 col-xxl-8">
                              <p>{this.state.component.longDescription}</p>
                            </div>
                            <div className="col-12 col-lg-6 col-xxl-4 products-detail__graybox">
                              <div className="products-detail__graybox--inner">
                                {this.state.component?.deviceId && (
                                  <div className="device__info">
                                    <div className="device__info--label">
                                      Device ID:
                                    </div>
                                    <div>
                                      {this.state.component.deviceId}
                                    </div>
                                  </div>
                                )}

                                {this.state.component?.resourceNumber && (
                                  <div className="device__info">
                                    <div className="device__info--label">
                                      Article No.:
                                    </div>
                                    <div>
                                      {this.state.component.resourceNumber}
                                    </div>
                                  </div>
                                )}

                                <br/>

                                {this.state.component?.softwareKey && (
                                  <div className="device__info">
                                    <div className="device__info--label">
                                      Software Key:
                                    </div>
                                    <div>
                                      {this.state.component.softwareKey}
                                    </div>
                                  </div>
                                )}

                                {this.state.component?.softwareVersion && (
                                  <div className="device__info">
                                    <div className="device__info--label">
                                      Software Version:
                                    </div>
                                    <div>
                                      {this.state.component.softwareVersion}
                                    </div>
                                  </div>
                                )}

                                {this.state.component?.softwareRevision && (
                                  <div className="device__info">
                                    <div className="device__info--label">
                                      CD Revision:
                                    </div>
                                    <div>
                                      {this.state.component.softwareRevision}
                                    </div>
                                  </div>
                                )}

                                {this.state.component?.serialNumber && (
                                  <div className="device__info">
                                    <div className="device__info--label">
                                      Serial No:
                                    </div>
                                    <div>
                                      {this.state.component.serialNumber}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <Tabs>
                            {
                              !this.state.component?.software &&
                              <TabContent identifier="calibration" title="Calibration">
                                <DeviceTabCertificates onChangeDate={this.onChangeDate.bind(this)} deviceId={this.state.component.id}/>
                              </TabContent>
                            }
                            {this.state.component?.historyCount > 0 &&
                              <TabContent identifier="history" title="History">
                                <DeviceTabHistory isSoftware={this.state.component?.software} deviceId={this.state.component.id}/>
                              </TabContent>
                            }
                            {this.state.component?.downloadCount > 0 &&
                              <TabContent identifier="downloads" title="Documents">
                                <DeviceTabDownloads deviceId={this.state.component.id}/>
                              </TabContent>
                            }
                          </Tabs>
                        </div>
                      </div>
                    </div>
                  </>
                  : <NotFound404/>
                }
              </>
              : <Loader mode="detail"/>
            }
          </div>
        </div>
      </div>
    )
  }
}

ComponentDetail.contextType = ApiContext;

export default ComponentDetail;
