import React from "react";
import {
  Route,
  HashRouter,
} from "react-router-dom";
import {tabContentProps} from "./TabContent";
import TabLink from "./TabLink";
import classNames from "classnames";

type tabsState = {
  activeTab: string,
}

type tabsProps = {
  activeMobile?: boolean
  className?: string
}

class Tabs extends React.Component<tabsProps, tabsState> {
  constructor(props: tabsProps) {
    super(props);

    this.state = {
      activeTab: ''
    };

    this.onClickLink = this.onClickLink.bind(this);
  }

  componentDidMount(): void {
    let initalActiveTab: string = '';

    if (this.props) {
      React.Children.map(this.props.children, (tabContent: React.ReactNode, i_) => {
        if (this.nodeIsTabContent(tabContent)) {
          if (!initalActiveTab)
            initalActiveTab = tabContent.props.identifier;
        }
      });
    }

    this.setState({
      activeTab: initalActiveTab
    })
  }

  nodeIsTabContent = (node: React.ReactNode): node is React.ReactElement<tabContentProps> => {
    const childElement = (node as React.ReactElement<tabContentProps>);
    if (childElement && this.props && this.props.children) {
      return (childElement.props && typeof childElement.props.identifier !== 'undefined' && typeof childElement.props.title !== 'undefined');
    }
    return false;
  };

  onClickLink(linkIdent_: string) {
    this.setState({
      activeTab: linkIdent_
    });
  }

  render() {
    let firstTabRendered = false;
    let firstLinkRendered = false;

    let className = classNames({
      'tab-navigation': true,
      'active-mobile': this.props.activeMobile,
      [this.props.className + '']: !!this.props.className,
    });

    return (
      this.props.children &&
      <div className={className}>
        <HashRouter hashType="noslash">
          <div className="tab-navigation__wrapper">
            <ul className="tab-navigation__list">
              {
                React.Children.map(this.props.children, (tabContent: React.ReactNode, i_) => {
                  if (this.nodeIsTabContent(tabContent)) {
                    let identifier = tabContent.props.identifier;
                    let title = tabContent.props.title;
                    let isFirst = !firstLinkRendered;
                    firstLinkRendered = true;

                    return (
                      <TabLink isDefault={isFirst} key={i_} onClick={() => this.onClickLink(identifier)} identifier={identifier} to={identifier}>
                        {title}
                      </TabLink>
                    )
                  }
                })
              }
            </ul>
          </div>
          <div className="tab-navigation__tabs">
            {
              React.Children.map(this.props.children, (tabContent: React.ReactNode, i_) => {
                if (this.nodeIsTabContent(tabContent)) {
                  const identifier = tabContent.props.identifier;
                  const children = tabContent.props.children;
                  let paths = ['/' + identifier];

                  // use first tab as "default" tab. add "/" as a valid path
                  // to render this path as a fallback if not other tab matches
                  if (!firstTabRendered) {
                    paths.push('/')
                    firstTabRendered = true;

                    return (
                      <Route key={i_} exact path={paths}>
                        <div className="tab-navigation__tab">
                          {children}
                        </div>
                      </Route>
                    )
                  }

                  return (
                    <Route key={i_} path={paths}>
                      <div className="tab-navigation__tab">
                        {children}
                      </div>
                    </Route>
                  )
                }
              })
            }
          </div>
        </HashRouter>
      </div>
    );
  }
}

export default Tabs;
