import React from "react";
import classNames from "classnames";

interface loaderProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  mode?: 'default' | 'inverted' | 'overview' | 'xs' | 'xs_inverted' | 'detail',
  wrapperClassName?: string
}

class Loader extends React.Component<loaderProps> {
  render() {
    let {mode} = this.props;
    let wrapperClasses = classNames({
      'loading__animation__wrapper': true,
      [this.props.wrapperClassName + '']: !!this.props.wrapperClassName
    });
    let buttonClasses = classNames({
      'loading__animation': true,
      [mode + '']: !!mode,
      [this.props.className + '']: !!this.props.className
    });

    return (
      <div className={wrapperClasses}>
        <div className={buttonClasses}></div>
      </div>
    )
  }

  static defaultOptions = {
    mode: 'default'
  };
}

export default Loader;
