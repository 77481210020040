import React, {ChangeEvent} from 'react';
import classNames from "classnames";

interface selectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  additionalClasses?: string,
}

class HtmlSelect extends React.Component<selectProps, {}> {
  constructor(props: selectProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e: ChangeEvent<HTMLSelectElement>) {
    if(this.props.onChange) {
      this.props.onChange(e);
    }
  }

  render() {
    let { additionalClasses, ...defaultProps } =  this.props;
    let classes = {
      'form-control': true,
      additionalClasses: !!additionalClasses
    };

    return (
      <div className="form-group">
        <select onChange={this.handleChange} className={classNames(classes)} {...defaultProps}>
          { this.props.children }
        </select>
      </div>
    )
  }
}

export default HtmlSelect;
