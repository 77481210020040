import React, {RefObject} from "react";
import DeviceEntry from "../Device/DeviceEntry";
import Accordion from "../Accordion";
import device from "../../types/device";
import ApiContext from "../Context/ApiContext";
import address from "../../types/address";
import {AlertManager, withAlert} from "react-alert";
import {stringKeyObject} from "../../types/common";
import {pageConfig} from "../../services/PageService";
import DeviceService from "../../services/DeviceService";
import AddressService from "../../services/AddressService";

type addressEntryProps = {
  address: address
  alert: AlertManager
  pageConfig: pageConfig
  getFilterParams?: () => stringKeyObject
  filterChangeIteration?: number
}

type DeviceAdressEntryStates = {
  devices: device[]
  hasLoaded: boolean
  loading: boolean
  filterChangeIteration?: number
}

class DeviceAddressEntry extends React.Component<addressEntryProps, DeviceAdressEntryStates> {
  accordion: RefObject<HTMLDivElement>;

  constructor(props: addressEntryProps) {
    super(props);

    this.state = {
      devices: [],
      hasLoaded: false,
      loading: false,
      filterChangeIteration: props.filterChangeIteration
    };

    this.accordion = React.createRef<HTMLDivElement>();

    this.loadDevices = this.loadDevices.bind(this);
  }

  componentDidMount() {
    if (this.accordion.current && DeviceService.getCurrentDeviceAddressId() === this.props.address.id.toString())
      this.accordion.current.scrollIntoView({behavior: 'smooth'});
  }

  loadDevices() {
    if (!this.state.hasLoaded) {
      this.setState({
        loading: true
      }, () => {
        this.context.getAddressRelatedDevices(this.props.address.id, this.props.getFilterParams ? this.props.getFilterParams() : {})
          .then((devicesObj: { count: number, data: device[] }) => {
            this.setState({
              devices: devicesObj.data,
              loading: false,
              hasLoaded: true
            });
          })
          .catch(() => {
            this.setState({
              loading: false
            });
          })
        ;
      });
    }
  }

  render() {
    return (
      <Accordion
        mode="slim"
        onOpen={this.loadDevices}
        loading={this.state.loading}
        data-address-id={this.props.address.id}
        isOpen={DeviceService.getCurrentDeviceAddressId() === this.props.address.id.toString()}
        ref={this.accordion}
        title={
          <div className="company__propertylist">
            <div className="list__col list__col--cid" data-title="PTW Company ID">{this.props.address.company && this.props.address.company.companyId}</div>
            <div className="list__col list__col--iid" data-title="Internal Company ID">
              {this.props.address.company && this.props.address.company.internalId ? this.props.address.company.internalId : 'N/A'}
            </div>
            <div className="list__col list__col--address" data-title="Company Address">
              {AddressService.getAddressFullNameString(this.props.address)}
            </div>
          </div>
        }>
        {this.state.devices.map((device, index) => {
          return (
            <DeviceEntry
              device={device}
              path={this.props.pageConfig.path}
              key={index}
            />
          );
        })}
        {(this.state.hasLoaded && this.props.filterChangeIteration && this.state.filterChangeIteration && this.props.filterChangeIteration > this.state.filterChangeIteration) &&
          this.setState({hasLoaded: false, filterChangeIteration: this.props.filterChangeIteration}, () => {
            if ((this.accordion.current && this.accordion.current.getAttribute('aria-expanded') === 'true'))
              this.loadDevices();
          })
        }
      </Accordion>
    );
  }
}

DeviceAddressEntry.contextType = ApiContext;

export default withAlert<addressEntryProps>()(DeviceAddressEntry);