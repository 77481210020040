import React, {
  ChangeEvent
} from 'react';
import Loader from "./Loader";
import InputText from "./InputText";
import user from "../types/user";
import PersonCard from "./PersonCard";
import BackButton from "./BackButton";
import Button from "./Button";
import PopUp from "./PopUp";
import CreateAccount from "./CreateAccount";
import LoadingIndicator from "./LoadingIndicator";
import ApiContext from "./Context/ApiContext";
import {OptionType} from "./Select/SelectTypes";
import {USER_ROLE_COMPANY_ADMIN, USER_ROLE_USER} from "../types/userRole";
import UserRoleService from "../services/UserRoleService";
import Select from "./Select/Select";
import {TYPING_DELAY} from "../types/filter";
import {ValueType} from "react-select/src/types";
import {EDIT_ACCOUNT_MODE_TEAM} from "./EditAccount/constants";

const FILTER_STATUS_OPTIONS: OptionType[] = [
  {value: '', label: 'Select status'},
  {value: 'active', label: 'active'},
  {value: 'inactive', label: 'inactive'}
];

const FILTER_ROLE_OPTIONS: OptionType[] = [
  {value: '', label: 'Select role'},
  {value: USER_ROLE_COMPANY_ADMIN, label: UserRoleService.getRoleLabel(USER_ROLE_COMPANY_ADMIN)},
  {value: USER_ROLE_USER, label: UserRoleService.getRoleLabel(USER_ROLE_USER)}
];

const ITEMS_PER_PAGE = 5;

type myTeamProps = {
  user: user | undefined
}

type myTeamState = {
  team: user[]
  loaded: boolean
  loadedMore: boolean
  faqMax: number
  statusFilter: OptionType | null
  roleFilter: OptionType | null
  search: string
  timeout: any
  filtered: boolean
  showPopup: boolean
  teamCount: number
  limit: number
  offset: number
  teamLength: number
}

class MyTeam extends React.Component<myTeamProps, myTeamState> {
  constructor(props: myTeamProps) {
    super(props);

    this.state = {
      team: [],
      loaded: false,
      loadedMore: true,
      faqMax: ITEMS_PER_PAGE,
      statusFilter: FILTER_STATUS_OPTIONS[0],
      roleFilter: FILTER_ROLE_OPTIONS[0],
      search: '',
      timeout: '',
      filtered: false,
      showPopup: false,
      teamCount: ITEMS_PER_PAGE, // has to be equal to limit, is set @componentDidMount
      limit: ITEMS_PER_PAGE,
      offset: 0,
      teamLength: ITEMS_PER_PAGE
    };

    this.handleTeamRequest = this.handleTeamRequest.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangeSelectRole = this.onChangeSelectRole.bind(this);
    this.onChangeSelectStatus = this.onChangeSelectStatus.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.onClickCreateAccount = this.onClickCreateAccount.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
  }

  componentDidMount(): void {
    this.setState({
      teamCount: this.state.limit
    })
    this.handleTeamRequest();
  }

  handleTeamRequest(initial?: boolean) {
    if (initial) {
      this.setState({
        offset: 0,
        loaded: false
      });
    }
    const params = {
      limit: this.state.limit,
      offset: initial ? 0 : this.state.offset,
    };

    if (this.state.roleFilter && this.state.roleFilter.value !== '') {
      Object.assign(params, {
        'filter[users][roles]': this.state.roleFilter.value
      });
    }

    if (this.state.statusFilter && this.state.statusFilter.value !== '') {
      Object.assign(params, {
        'filter[users][status]': this.state.statusFilter.value
      });
    }

    let search = this.state.search.trim();
    if (search !== '') {
      Object.assign(params, {
        'filter[users][firstName]': search,
        'filter[users][lastName]': search,
        'filter[users][email]': search
      });
    }

    this.context.getAllUsersForCompany(this.props.user?.company?.id, params).then((teamObj: { count: number, data: user[] }) => {
      this.setState({
        team: initial ? teamObj.data : this.state.team.concat(teamObj.data),
        teamLength: teamObj.count,
        loadedMore: true,
        loaded: true
      })
    }, () => {
      this.setState({
        loaded: true,
        loadedMore: true
      })
    });
  }

  setFilter(triggerRequest: boolean = true) {
    this.setState({
      team: [],
      filtered: false,
      loaded: false,
    }, () => {
      // callback after asynchron state changes needed for select filter changes
      if (triggerRequest) {
        this.handleTeamRequest();
      }
    });
  }

  onChangeInput(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    let searchText = e.target.value;
    if (searchText !== this.state.search) {
      clearTimeout(this.state.timeout);
      this.setState({
        search: e.target.value,
        timeout: setTimeout(() => {
          this.setFilter();
        }, TYPING_DELAY)
      });
    }
  }

  onClickCreateAccount() {
    this.setState({
      showPopup: true
    });
  }

  onClickCancel() {
    this.setState({
      showPopup: false
    });
  }

  onChangeSelectRole(value: ValueType<OptionType, false> | null): void {
    this.setState({
      roleFilter: value,
    }, this.setFilter);
  }

  onChangeSelectStatus(value: ValueType<OptionType, false> | null): void {
    this.setState({
      statusFilter: value,
    }, this.setFilter);
  }

  onClickLoadMore() {
    this.setState({
      loadedMore: false,
      offset: this.state.offset + this.state.limit,
      teamCount: this.state.teamCount + this.state.limit
    }, this.handleTeamRequest);
  }

  render() {
    return (
      <div className="teamoverview__wrapper">
        <PopUp onClickCloseIcon={this.onClickCancel} showPopup={this.state.showPopup}>
          <CreateAccount handleTeamRequest={this.handleTeamRequest} user={this.props.user}
                         onClickCancel={this.onClickCancel}/>
        </PopUp>
        <div id="teamoverview" className="container">
          <h1 className="pagetitle">My Team</h1>
          <BackButton to="/">
            Back to home
          </BackButton>
          <div className="contentbox">
            <div className="team__filter__wrapper">
              <InputText
                placeholder="Search"
                mode="highlighted"
                id="search"
                onChange={this.onChangeInput}
                value={this.state.search}
                showReset={!!(this.state.search) && this.state.search !== ''}
                onClickReset={() => {
                  this.setState({search: ''}, this.setFilter);
                }}
              />

              <Select
                options={FILTER_STATUS_OPTIONS}
                value={this.state.statusFilter}
                // @ts-ignore
                onChange={this.onChangeSelectStatus}
                menuIsOpen={true}
              />

              <Select
                options={FILTER_ROLE_OPTIONS}
                value={this.state.roleFilter}
                // @ts-ignore
                onChange={this.onChangeSelectRole}
                menuIsOpen={true}
              />

              <Button className="team__create__account__btn" onClick={this.onClickCreateAccount}>
                <span>Create account</span>
              </Button>
            </div>
            {this.state.loaded ? (
              <>
                {this.state.team ? (
                  <>
                    {this.state.team.map((teamMember, i_) => {
                      return (
                        <PersonCard mode={EDIT_ACCOUNT_MODE_TEAM} updateUser={() => this.handleTeamRequest(true)}
                                    user={this.props.user} key={i_} teamMember={teamMember}
                                    title={teamMember.firstName}/>
                      )
                    })}
                  </>
                ) : (
                  <h3>No Team found.</h3>
                )}
              </>
            ) : (
              <Loader mode="overview"/>
            )
            }
            {this.state.team.length > 0 && (
              this.state.team.length < this.state.teamLength &&
              <div className="loading__indicator__wrapper">
                <div onClick={this.onClickLoadMore.bind(this)}>
                  <LoadingIndicator loading={!this.state.loadedMore}
                                    text="Show more"/>
                </div>
              </div>
            )
            }
          </div>
        </div>
      </div>
    )
  }
}

MyTeam.contextType = ApiContext;

export default MyTeam;
