import React from "react";
import {companyRelation} from "../../types/company";
import ApiContext from "../Context/ApiContext";
import {AlertManager, withAlert} from "react-alert";
import Loader from "../Loader";

type relationDeleteProps = {
  relation: companyRelation
  className: string
  onDelete?: () => void
  onDeleted?: (relation: companyRelation) => void
  alert: AlertManager
};

type relationDeleteState = {
  relation?: companyRelation
  deleting: boolean
};

class RelationDelete extends React.Component<relationDeleteProps, relationDeleteState> {
  constructor(props: relationDeleteProps) {
    super(props);
    this.state = {
      relation: props.relation,
      deleting: false
    }
    this.deleteRelation = this.deleteRelation.bind(this);
  }

  deleteRelation() {
    this.setState({deleting: true}, () => {
      if (this.state.relation) {
        if (this.props.onDelete)
          this.props.onDelete();
        this.context.deleteCompanyRelation(this.state.relation.company.id, this.state.relation.relatedCompany.id)
          .then(() => {
            if (this.props.onDeleted)
              this.props.onDeleted(this.props.relation);
            this.setState({
              deleting: false,
              relation: undefined
            });
            this.props.alert.success('Deleted');
          })
          .catch(() => {
            this.setState({
              deleting: false
            });
          })
        ;
      }
    })
  }

  render() {
    return (
      this.state.deleting
        ? <Loader mode="xs_inverted"/>
        : <div className={this.props.className} onClick={this.deleteRelation}>
          <span className="sr-only">delete</span>
        </div>
    );
  }
}

RelationDelete.contextType = ApiContext;

export default withAlert<relationDeleteProps>()(RelationDelete);