import React from "react";
import '../Scss/critical.scss';
import './../Scss/style.scss';
import Master from "./Master";
import {useAlert} from "react-alert";

const App: React.FC = () => {
  let alert=useAlert();

  return (
    <div className="App">
      <Master mode={"default"} alertManager={alert}/>
    </div>
  );
};

export default App;
