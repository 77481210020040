import React from "react";
import device from "../../types/device";
import DateVisual from "../DateVisual";
import classNames from "classnames";

type deviceHeaderProps = {
  device: device
  userCalibrationDate?: string | undefined
  companyProps?: {
    companyId: string
    internalId: string
    address: string
  }
}

class DeviceHeader extends React.Component<deviceHeaderProps> {

  isMyProductDetailPage() : boolean {
    let urlArray = window.location.pathname.split('/');
    return urlArray.includes('myproducts');
  };

  render() {
    let classes = classNames({
      'productbox__calibration-indicator': true,
      'productbox__calibration-indicator--green': this.props.device?.calibration?.status === 'CALIBRATION_OKAY',
      'productbox__calibration-indicator--yellow': this.props.device?.calibration?.status === 'CALIBRATION_DUE',
      'productbox__calibration-indicator--red': this.props.device?.calibration?.status === 'CALIBRATION_REQUIRED' || this.props.device?.calibration?.status === 'CALIBRATION_UNKNOWN',
    });
    return (
      <div className="productbox__header">
        <div className="header__left">
          <div className="header__tagline">
            <span className="header__tagline--date">Buy date: <DateVisual
              utc={this.props.device?.dateOfPurchase}/></span>
            <span>Order No.: {this.props.device?.orderNumber}</span>
            {this.props.companyProps && <>
              <span>PTW Company ID: {this.props.companyProps.companyId}</span>
              {this.isMyProductDetailPage() ?
                <>
                  <span>{this.props.companyProps.internalId ? 'Customer ID:' + this.props.companyProps.internalId : ''}</span>
              </> :
                <>
                  <span>{this.props.companyProps.internalId ? 'Internal Company ID:' + this.props.companyProps.internalId : ''}</span>
                </>
              }
              <span>Address: {this.props.companyProps.address}</span>
            </>}
          </div>
        </div>
        <div className="header__right">
          {!this.props.device?.software && this.props.device?.calibration && this.props.device?.calibration.status !== 'CALIBRATION_UNKNOWN' &&
            <div className={classes}>
              {!this.props.userCalibrationDate
                ? <>
              <span>Next action: <DateVisual
                utc={this.props.device?.calibration?.userCalibrationDate ? this.props.device?.calibration?.userCalibrationDate : this.props.device?.calibration?.calibrationDate}/>
              </span>
                </>
                : <>
              <span>Next action: <DateVisual utc={this.props.userCalibrationDate}/>
              </span>
                </>
              }
            </div>
          }
        </div>
      </div>
    )
  }
}

export default DeviceHeader;
