import React from "react";
import {Link} from "react-router-dom";

class GroupTable extends React.Component {
  render () {
    let downloadLink =
      <Link to="#" className="link-download">
        <span>Download Link</span>
      </Link>;

    return (
      <div>
        <h1>Tabelle</h1>
        <div className="table-wrapper">
          <table className="table">
            <thead>
            <tr>
              <td>Art. No.</td>
              <td>Valid from - until</td>
              <td>Order No.</td>
              <td>Version</td>
              <td>Event</td>
              <td>Text</td>
              <td>Delivery Note</td>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>S080045.B</td>
              <td>2018-07-16 – now</td>
              <td>AU1703071-5</td>
              <td>3.4.1</td>
              <td>Software Update</td>
              <td></td>
              <td>
                { downloadLink }
              </td>
            </tr>
            <tr>
              <td>S080045.C</td>
              <td>2017-12-17 – 2018-07-16</td>
              <td>AU1703071-1</td>
              <td>3.4</td>
              <td>Software Update</td>
              <td></td>
              <td>
                { downloadLink }
              </td>
            </tr>
            <tr>
              <td>S080001</td>
              <td>2016-04-08 – 2017-12-17</td>
              <td>AU600949</td>
              <td>7.42</td>
              <td>Produkt Lieferung</td>
              <td>Initial Lieferung</td>
              <td>
                { downloadLink }
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default GroupTable;
