import React from "react";
import device from "../../types/device";
import Table from "../Table";
import {tableHeadLabels, tableData} from "../../types/table";
import DateVisual from "../DateVisual";
import {BrowserRouter, Link} from "react-router-dom";
import Loader from "../Loader";
import ApiContext from "../Context/ApiContext";
import {pageConfig} from "../../services/PageService";

type deviceTabComponentsProps = {
  deviceId: number,
  overviewPageConfig: pageConfig
}

type deviceTabComponentsState = {
  components: device[],
  loaded: boolean
}

class DeviceTabComponents extends React.Component<deviceTabComponentsProps, deviceTabComponentsState> {
  constructor(props: deviceTabComponentsProps) {
    super(props);

    this.state = {
      loaded: false,
      components: []
    }
  }

  componentDidMount(): void {
    if (this.props.deviceId) {
      this.context.getComponents(this.props.deviceId).then((devicesObj: { count: number, data: device[] }) => {
        this.setState({
          loaded: true,
          components: devicesObj.data
        })
      })
    }
  }

  render() {
    let headLabels: tableHeadLabels = [
      <>Art. No.</>,
      <>Description</>,
      <>Buy date</>,
      <>Order No.</>,
      <></>,
    ];

    let tData: tableData = [];
    this.state.components.forEach((component_) => {
      tData.push([
        <>{component_.resourceNumber}</>,
        <>{component_.description}</>,
        <DateVisual utc={component_.dateOfPurchase}/>,
        <>{component_.orderNumber}</>,
        <BrowserRouter forceRefresh={true} basename={this.props.overviewPageConfig.path + '/' + this.props.deviceId}>
          <Link to={component_.id.toString()} className="link">Show details</Link>
        </BrowserRouter>
      ]);
    });

    return (
      this.state.loaded ? (
        <Table headLabels={headLabels} data={tData}/>
      ) : (
        <Loader className="component" mode="inverted"/>
      )
    )
  }
}

DeviceTabComponents.contextType = ApiContext;

export default DeviceTabComponents;
