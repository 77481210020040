export const USER_ROLE_ADMIN = 'ROLE_ADMIN';
export const USER_ROLE_COMPANY_ADMIN = 'ROLE_COMPANY_ADMIN';
export const USER_ROLE_USER = 'ROLE_USER';
export const USER_ROLE_SERVICE_USER = 'ROLE_SERVICE_USER';
export const USER_ROLE_SUBSIDIARY_ADMIN = 'ROLE_SUBSIDIARY_ADMIN';
export const USER_ROLE_SUBSIDIARY = 'ROLE_SUBSIDIARY';
export const USER_ROLE_REPRESENTATIVE_ADMIN = 'ROLE_REPRESENTATIVE_ADMIN';
export const USER_ROLE_REPRESENTATIVE = 'ROLE_REPRESENTATIVE';

export type userRole =
  typeof USER_ROLE_ADMIN
  | typeof USER_ROLE_COMPANY_ADMIN
  | typeof USER_ROLE_USER
  | typeof USER_ROLE_SERVICE_USER
  | typeof USER_ROLE_SUBSIDIARY_ADMIN
  | typeof USER_ROLE_SUBSIDIARY
  | typeof USER_ROLE_REPRESENTATIVE_ADMIN
  | typeof USER_ROLE_REPRESENTATIVE
  | string
  | '';

export interface userRoleEntry {
  id: number,
  key: userRole,
  name: string
}