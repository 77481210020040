import React from "react";
import classNames from "classnames";
import buttonTypes from "../types/button";
import user from "../types/user";
import ApiContext from "./Context/ApiContext";
import {stringKeyObject} from "../types/common";
import userStatus, {
  USER_STATUS_ACTIVE,
  USER_STATUS_DELETED,
  USER_STATUS_INACTIVE,
  USER_STATUS_VALIDATED,
  USER_STATUS_NEW,
  USER_STATUS_VALIDATION_FAILED
} from "../types/userStatus";
import {EDIT_ACCOUNT_MODE, EDIT_ACCOUNT_MODE_TEAM} from "./EditAccount/constants";

interface StatusActionButtonsProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  mode?: buttonTypes
  user?: user
  loginuser?: user
  editMode: EDIT_ACCOUNT_MODE
}

type userStatusAction = [string, userStatus];

type StatusActionButtonsState = {
  [USER_STATUS_ACTIVE]: userStatusAction[],
  [USER_STATUS_INACTIVE]: userStatusAction[],
  [USER_STATUS_NEW]: userStatusAction[],
  [USER_STATUS_VALIDATED]: userStatusAction[],
  [USER_STATUS_VALIDATION_FAILED]: userStatusAction[],
  [USER_STATUS_DELETED]: userStatusAction[],
  undefined: userStatusAction[],
}

const USER_ACTIONS: stringKeyObject<userStatusAction> = {
  activate: ['Activate', USER_STATUS_ACTIVE],
  deactivate: ['Deactivate', USER_STATUS_INACTIVE],
  delete: ['Delete', USER_STATUS_DELETED],
  decline: ['Decline', USER_STATUS_DELETED],
  authorise: ['Authorise', USER_STATUS_ACTIVE],
  validate: ['Start Validation', USER_STATUS_VALIDATED],
  retry: ['Retry', USER_STATUS_VALIDATED]
}

class StatusActionButtons extends React.Component<StatusActionButtonsProps, StatusActionButtonsState> {
  constructor(props: StatusActionButtonsProps) {
    super(props);

    // status with associated button1[title, parameter], button2[title, parameter]
    if (this.props.editMode === EDIT_ACCOUNT_MODE_TEAM) {
      this.state = {
        [USER_STATUS_ACTIVE]: [USER_ACTIONS.deactivate],
        [USER_STATUS_INACTIVE]: [USER_ACTIONS.activate],
        [USER_STATUS_NEW]: [],
        [USER_STATUS_VALIDATED]: [],
        [USER_STATUS_VALIDATION_FAILED]: [],
        [USER_STATUS_DELETED]: [],
        undefined: []
      }
    } else {
      this.state = {
        [USER_STATUS_ACTIVE]: [USER_ACTIONS.deactivate],
        [USER_STATUS_INACTIVE]: [USER_ACTIONS.activate, USER_ACTIONS.delete],
        [USER_STATUS_NEW]: [USER_ACTIONS.decline, USER_ACTIONS.validate],
        [USER_STATUS_VALIDATED]: [USER_ACTIONS.decline, USER_ACTIONS.authorise],
        [USER_STATUS_VALIDATION_FAILED]: [USER_ACTIONS.decline, USER_ACTIONS.retry],
        [USER_STATUS_DELETED]: [],
        undefined: []
      }
    }

    this.triggerUserAction = this.triggerUserAction.bind(this);
  }

  triggerUserAction(event: React.MouseEvent, status: string) {
    event.preventDefault();
    this.context.updateUser(this.props.user?.id, {status: status}).then(() => {
      window.location.reload();
    });
  }

  render() {
    let {mode, editMode, user, loginuser, className, ...defaultProps} = this.props;
    const userStatus = user?.status ? user.status : 'undefined';
    let buttonClasses = classNames({
      'button': true,
      'action__button': true,
      'button button--moreinv': mode === 'invert',
      [className + '']: !!className
    });

    if (!this.state.hasOwnProperty(userStatus) || !this.state[userStatus] || !this.state[userStatus].length) {
      return (
        <></>
      )
    }

    return (
      <div className="user__status__actions">
        <div className="form-row">
          <div className="form-row--with__label">
            <label className="form__label">Actions</label>
            <div className="form-group">
              {
                this.state[userStatus].map((buttons, index) => {
                  return (
                    // array[0] = Button title,
                    // array[1] = status parameter
                    <button key={index} onClick={event => this.triggerUserAction(event, buttons[1])} className={buttonClasses} {...defaultProps}>{buttons[0]}</button>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  static defaultOptions = {
    mode: 'default'
  };
}

StatusActionButtons.contextType = ApiContext;

export default StatusActionButtons;
