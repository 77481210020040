import React, {ChangeEvent} from 'react';
import BackButton from "../BackButton";
import device from "../../types/device";
import Loader from "../Loader";
import LoadingIndicator from "../LoadingIndicator";
import InputText from "../InputText";
import DaterangePicker from "../DaterangePicker";
import Moment from 'react-moment';
import SalesmanContact from "../SalesmanContact";
import PopUp from "../PopUp";
import user from "../../types/user";
import ApiContext from "../Context/ApiContext";
import moment from "moment";
import classNames from "classnames";
import DeviceEntry from "./DeviceEntry";
import PageService, {PAGE_MY_PRODUCTS} from "../../services/PageService";
import DeviceService from "../../services/DeviceService";
import {OptionType} from "../Select/SelectTypes";
import Select from "./../Select/Select";
import {ValueType} from "react-select/src/types";
import FilterService, {
  NAMED_FILTER_DEVICE_ADDRESSES,
  namedFilterFieldDeviceAddresses,
  namedFilterParamConfig
} from "../../services/FilterService";
import Button from "../Button";
import {SORT_DIRECTION_ASC, sortDirection} from "../../types/filter";
import {stringKeyObject} from "../../types/common";

type productOverviewProps = {
  user: user | undefined,
}

type prodcutOverviewState = {
  devices: device[],
  loaded: boolean,
  filtered: boolean,
  limit: number,
  offset: number,
  loadedMore: boolean,
  devicesMax: number,
  deviceCount: number,
  search: string,
  sortBy: OptionType | null,
  ordering: string,
  buyDate: string,
  nextCalibration: OptionType | null,
  timeout: any,
  typingDoneDelay: number,
  startDate: Date | undefined,
  endDate: Date | undefined,
  key: string,
  showDaterangePicker: boolean,
  showDateRangeLabel: boolean,
  showPopup: boolean,
  deviceOfInterest: device | undefined,
  triggerDateReset: boolean,
  excelLoading: boolean
}

const SORT_BY_LABEL_PREFIX = 'Sort by: ';
const SORT_BY_OPTIONS: OptionType[] = [
  {
    value: 'dateOfPurchase',
    label: SORT_BY_LABEL_PREFIX + 'Buy date'
  },
  {
    value: 'calibration.calibrationDate',
    label: SORT_BY_LABEL_PREFIX + 'Next action'
  }
];
const SORT_BY_OPTION_DEFAULT = SORT_BY_OPTIONS[0];

const CALIBRATION_LABEL_PREFIX = 'Next action: ';
const CALIBRATION_OPTIONS: OptionType[] = [
  {value: '', label: CALIBRATION_LABEL_PREFIX + 'all'},
  {value: '7', label: CALIBRATION_LABEL_PREFIX + '1 week'},
  {value: '30', label: CALIBRATION_LABEL_PREFIX + '1 month'},
  {value: '90', label: CALIBRATION_LABEL_PREFIX + '3 months'},
  {value: '180', label: CALIBRATION_LABEL_PREFIX + '6 months'},
  {value: '360', label: CALIBRATION_LABEL_PREFIX + '1 year'},
];
const CALIBRATION_OPTION_DEFAULT = CALIBRATION_OPTIONS[0];

const ITEMS_PER_PAGE = 10;

const FILTER_DEFAULT_VALUES = {
  search: '',
  companies: [],
  addresses: [],
  buyDateStart: undefined,
  buyDateEnd: undefined,
  nextCalibration: CALIBRATION_OPTIONS[0],
  sortBy: SORT_BY_OPTIONS[0],
  limit: ITEMS_PER_PAGE,
  itemCount: ITEMS_PER_PAGE
};

const SORT_BY_DIRECTIONS: stringKeyObject<sortDirection> = {
  dateOfPurchase: 'DESC',
  'calibration.calibrationDate': 'DESC'
};

class DeviceOverview extends React.Component<productOverviewProps, prodcutOverviewState> {
  static pageConfig = PageService.pagesConfigurations[PAGE_MY_PRODUCTS];

  constructor(props: productOverviewProps) {
    super(props);

    let filter = {
      search: '',
      sortBy: SORT_BY_OPTION_DEFAULT,
      startDate: undefined,
      endDate: undefined,
      nextCalibration: CALIBRATION_OPTION_DEFAULT,
      limit: ITEMS_PER_PAGE,
      deviceCount: ITEMS_PER_PAGE, // has to be equal to limit, is set @componentDidMount,
    };

    let filterCookie = this.getFilterCookie();
    if (filterCookie && typeof filterCookie === 'object') {
      filter = {
        ...filter,
        ...filterCookie
      };
    }

    this.state = {
      showDateRangeLabel: !!filter.startDate,
      key: 'selection',
      devices: [],
      loadedMore: true,
      loaded: false,
      filtered: true,
      ordering: 'DESC',
      buyDate: '',
      timeout: '',
      typingDoneDelay: 600,
      devicesMax: 0,
      showDaterangePicker: false,
      showPopup: false,
      deviceOfInterest: undefined,
      // set saved filter
      search: filter.search,
      sortBy: filter.sortBy && typeof filter.sortBy === 'object' ? filter.sortBy : SORT_BY_OPTION_DEFAULT,
      startDate: filter.startDate,
      endDate: filter.endDate,
      nextCalibration: filter.nextCalibration && typeof filter.nextCalibration === 'object' ? filter.nextCalibration : CALIBRATION_OPTION_DEFAULT,
      limit: filter.limit, // sets initially loaded devices and number of "load more" devices
      offset: 0,
      triggerDateReset: false,
      deviceCount: (filter.deviceCount > filter.limit) ? filter.deviceCount : filter.limit, // deviceCount has initially to be equal to limit NOT 0
      excelLoading: false,
    };

    this.onChangeInputSearch = this.onChangeInputSearch.bind(this);
    this.handleDeviceRequest = this.handleDeviceRequest.bind(this);
    this.onClickLoadMore = this.onClickLoadMore.bind(this);
    this.onChangeSortBy = this.onChangeSortBy.bind(this);
    this.onChangeNextCalibration = this.onChangeNextCalibration.bind(this);
    this.changeDateRange = this.changeDateRange.bind(this);
    this.onSetDatePicker = this.onSetDatePicker.bind(this);
    this.onClearDatePicker = this.onClearDatePicker.bind(this);
    this.getFilterCookie = this.getFilterCookie.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
    this.onClickContact = this.onClickContact.bind(this);
    this.scrollToLastProduct = this.scrollToLastProduct.bind(this);
    this.exportExcel = this.exportExcel.bind(this);
  }

  componentDidMount(): void {
    this.handleDeviceRequest(false, this.state.deviceCount, true);
  }

  changeDateRange(startDate: Date, endDate: Date): void {
    this.setState({
      startDate: startDate,
      endDate: endDate
    })
  }

  handleDeviceRequest(filter?: boolean, limit?: number, isFirstload: boolean = false) {
    const params = {
      limit: limit ? limit : this.state.limit,
      offset: this.state.offset
    };

    // add sort
    if (this.state.sortBy) {
      Object.assign(params, {
        ['sort[' + this.state.sortBy.value + ']']: this.state.ordering
      });
    }
    Object.assign(params, {
      'sort[description]': 'ASC'
    });

    // add search word filter
    const sWord = this.state.search.trim();
    if (sWord !== '') {
      Object.assign(params, {
        // description == title
        'filter[device_overview][description]': sWord,
        // longDescription == description
        'filter[device_overview][longDescription]': sWord,
        'filter[device_overview][serialNumber]': sWord,
        'filter[device_overview][deviceId]': sWord
      });
    }

    // add calibration filter
    if (this.state.nextCalibration && this.state.nextCalibration.value !== CALIBRATION_OPTION_DEFAULT.value) {
      Object.assign(params, {
        'filter[calibration.calibrationDate][lte]': moment().add(this.state.nextCalibration.value, 'days').toDate(),
        'filter[calibration.calibrationDate][gte]': moment().toDate()
      });
    }

    // add purchase date filter
    if (this.state.startDate !== undefined && this.state.endDate !== undefined) {
      Object.assign(params, {
        'filter[device_overview][from]': this.state.startDate,
        'filter[device_overview][until]': this.state.endDate
      });
    }

    this.context.getDevices(params).then((devicesObj: { count: number, data: device[] }) => {
      this.setState({
        devices: filter ? devicesObj.data : this.state.devices.concat(devicesObj.data),
        devicesMax: devicesObj.count,
        loaded: true,
        loadedMore: true,
        filtered: true
      }, () => {
        if (isFirstload) {
          this.scrollToLastProduct();
          DeviceService.deleteCurrentDeviceConfig();
        }
      })
    }, () => {
      this.setState({
        loaded: true,
        loadedMore: true,
        filtered: true
      })
    }).catch(() => {
      this.setState({
        loaded: true,
        loadedMore: true,
        filtered: true
      })
    });
  }

  scrollToLastProduct() {
    let currentDeviceId = DeviceService.getCurrentDeviceId();
    if (currentDeviceId) {
      let scrollTarget = document.querySelector('[data-device-id="' + currentDeviceId + '"]');
      if (scrollTarget)
        scrollTarget.scrollIntoView();
    }
  }

  onClickLoadMore() {
    this.setState({
      loadedMore: false,
      offset: this.state.deviceCount,
      deviceCount: this.state.deviceCount + this.state.limit,
    }, () => {
      this.setFilterCookie();
      this.handleDeviceRequest();
    });
  }

  onClickContact(device_: device) {
    this.setState({
      showPopup: true,
      deviceOfInterest: device_
    });
  }

  onClickCancel() {
    this.setState({
      showPopup: false
    });
  }

  onChangeInputSearch(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    let search = e.target.value

    this.setState({
      search: search
    })

    clearTimeout(this.state.timeout);
    this.setFilter();
    this.setState({
      timeout: setTimeout(() => {
        this.setFilter(true);
      }, this.state.typingDoneDelay)
    });
  }

  onChangeSortBy(value: ValueType<OptionType, false> | null): void {
    this.setState({
      sortBy: value
    }, () => this.setFilter(true));
  }

  onChangeNextCalibration(value: ValueType<OptionType, false> | null): void {
    this.setState({
      nextCalibration: value,
    }, () => this.setFilter(true));
  }

  setFilter(triggerRequest?: boolean) {
    this.setState({
      devices: [],
      filtered: false,
      deviceCount: this.state.limit,
      offset: 0,
    }, () => {
      // callback after asynchron state changes needed for select filter changes
      this.setFilterCookie();
      if (triggerRequest)
        this.handleDeviceRequest(true);
    });
  }

  onClickDatePicker(e: React.MouseEvent) {
    e.stopPropagation();
    this.setState({
      showDaterangePicker: true
    });
  }

  onClickCalendar(e: React.MouseEvent) {
    e.stopPropagation();
  }

  onSetDatePicker() {
    if (this.state.showDaterangePicker) {
      this.setState({
        showDaterangePicker: false,
        showDateRangeLabel: true
      }, () => this.setFilter(true));
    }
  }

  onClearDatePicker() {
    this.setState({
      showDaterangePicker: false,
      showDateRangeLabel: false,
      startDate: undefined,
      endDate: undefined
    }, () => this.setFilter(true));
  }

  onClickOverview() {
    this.setState({
      showDaterangePicker: false
    });
  }

  setFilterCookie() {
    const filterConf = {
      search: this.state.search.trim(),
      sortBy: this.state.sortBy,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      nextCalibration: this.state.nextCalibration,
      deviceCount: this.state.deviceCount,
      limit: this.state.limit
    };
    localStorage.setItem(FilterService.filterStoreIds.DeviceOverview, JSON.stringify(filterConf));
  }

  setDeviceId(id_: string) {
    localStorage.setItem('deviceId', JSON.stringify({deviceId: id_}));
  }

  getNamedFilterParamConfig(field: namedFilterFieldDeviceAddresses): namedFilterParamConfig {
    let value = '';
    switch (field) {
      case 'search':
        value = this.getFilterSearch();
        break;
      case 'calibrationUntil':
        value = this.state.nextCalibration && this.state.nextCalibration.value !== FILTER_DEFAULT_VALUES.nextCalibration.value ? FilterService.formatDate(moment().add(this.state.nextCalibration.value, 'days').toDate()) : '';
        break;
      case 'from':
        value = ""+this.state.startDate;

        break;
      case 'until':
        value = ""+this.state.endDate;
        break;
    }
    return {
      name: NAMED_FILTER_DEVICE_ADDRESSES,
      field: field,
      value: value
    }
  }

  getFilterSearch(): string {
    let filterSearch = this.state.search ? this.state.search.trim() : FILTER_DEFAULT_VALUES.search;
    if (filterSearch !== FILTER_DEFAULT_VALUES.search)
      return filterSearch;
    return FILTER_DEFAULT_VALUES.search;
  }

  getSortByDirection(): sortDirection {
    if (this.state.sortBy && SORT_BY_DIRECTIONS.hasOwnProperty(this.state.sortBy.value))
      return SORT_BY_DIRECTIONS[this.state.sortBy.value];
    return SORT_DIRECTION_ASC;
  }

  exportExcel() {
    this.setState({excelLoading: true}, () => {
      let params = FilterService.getFilterParams(
        this.getNamedFilterParamConfig('search'),
        this.getNamedFilterParamConfig('calibrationUntil'),
        this.getNamedFilterParamConfig('from'),
        this.getNamedFilterParamConfig('until'),
      );

      if (this.state.sortBy && this.state.sortBy.value !== '') {
        Object.assign(params, FilterService.getSortParams({
          field: this.state.sortBy.value,
          direction: this.getSortByDirection()
        }));
      }

      this.context.exportDevicesExcel(params)
        .then((result: Blob) => {
          const file = new Blob([result], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
          window.open(URL.createObjectURL(file));
        })
        .finally(() => {
          this.setState({excelLoading: false});
        })
      ;
   })
  }

  getFilterCookie() {
    let config = localStorage.getItem(FilterService.filterStoreIds.DeviceOverview);
    return config ? JSON.parse(config) : '';
  }

  render() {
    let datePickerClasses = {
      'form-control': true,
      'active': (this.state.startDate && this.state.endDate),
    };

    return (
      <>
        <PopUp onClickCloseIcon={this.onClickCancel} showPopup={this.state.showPopup}>
          <SalesmanContact
            user={this.props.user}
            device={this.state.deviceOfInterest}
            deviceRequest={true}
            onClickCancel={this.onClickCancel}
          />
        </PopUp>
        <div className="container">
          <div className="products-overview" onClick={this.onClickOverview.bind(this)}>
            <BackButton to="/">
              Back to home
            </BackButton>
            {this.state.loaded
              ? <div className="contentbox">
                <div className="filter__wrapper">
                  <div className="row small-gutter">
                    <div className="col-12 col-sm-6 col-lg-3">
                      <InputText
                        placeholder="Search"
                        mode="highlighted"
                        id="search"
                        onChange={this.onChangeInputSearch}
                        value={this.state.search}
                        onClickReset={() => {
                          this.setState({search: ''}, () => this.setFilter(true));
                        }}
                        showReset={!!(this.state.search) && this.state.search !== ''}
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <Select
                        placeholder="Search"
                        id="sortBy"
                        menuIsOpen={true}
                        options={SORT_BY_OPTIONS}
                        // @ts-ignore
                        onChange={this.onChangeSortBy}
                        selectLabel={SORT_BY_OPTION_DEFAULT.label}
                        value={this.state.sortBy}
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <div className="form-group">
                        <div className="daterange__picker__wrapper">
                          <div id="dateRangeFilter" className={classNames(datePickerClasses)}
                               onClick={this.onClickDatePicker.bind(this)}>
                            {this.state.showDateRangeLabel ?
                              <>
                                <Moment format="YYYY-MM-DD">{this.state.startDate}</Moment> - <Moment
                                format="YYYY-MM-DD">{this.state.endDate}</Moment>
                              </>
                              : 'Buy date'
                            }
                          </div>
                          <div className="calendar__wrapper" onClick={this.onClickCalendar.bind(this)}>
                            <DaterangePicker
                              showPopup={this.state.showDaterangePicker}
                              startDate={this.state.startDate}
                              endDate={this.state.endDate}
                              changeDateRange={this.changeDateRange}
                              onClearDatePicker={this.onClearDatePicker}
                              onSetDatePicker={this.onSetDatePicker}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3 nextCalibrationFilterWrapper">
                      <Select
                        placeholder="Search"
                        id="nextCalibration"
                        menuIsOpen={true}
                        options={CALIBRATION_OPTIONS}
                        // @ts-ignore
                        onChange={this.onChangeNextCalibration}
                        value={this.state.nextCalibration}
                        onClickReset={() =>
                          this.setState({
                            nextCalibration: CALIBRATION_OPTION_DEFAULT
                          }, () => this.setFilter(true))
                        }
                        showReset={!!(this.state.nextCalibration) && this.state.nextCalibration.value !== CALIBRATION_OPTION_DEFAULT.value}
                      />
                    </div>
                  </div>
                </div>
                <div className="btn-export-devices__wrapper">
                  <Button
                    onClick={this.exportExcel}
                    disabled={this.state.excelLoading}
                    className="btn-export-devices"
                  >
                    export to Excel file
                  </Button>
                </div>
                {this.state.filtered
                  ? <>
                    {this.state.devices.map((device, index) => {
                      return (
                        <DeviceEntry
                          device={device}
                          path={DeviceOverview.pageConfig.path}
                          key={index}
                        />
                      )
                    })}

                    {this.state.devices.length > 0
                      ? this.state.deviceCount < this.state.devicesMax &&
                      <div className="loading__indicator__wrapper">
                        <div onClick={this.onClickLoadMore}>
                          <LoadingIndicator loading={!this.state.loadedMore}
                                            text="Show more"/>
                        </div>
                      </div>
                      : <h3>No devices found.</h3>
                    }
                  </>
                  : <Loader className="overview__filter"/>

                }
              </div>
              : <Loader mode="overview"/>
            }
          </div>
        </div>
      </>
    )
  }
}

DeviceOverview.contextType = ApiContext;

export default DeviceOverview;
