import React from "react";
import LoadingIndicator from '../LoadingIndicator'
import Collapse from '../Collapse'
import {Link} from "react-router-dom";
import GroupHeadlines from "./UiKitHeadlines";
import GroupTypo from "./UiKitTypo";
import GroupForms from "./UiKitForms";
import GroupLinks from "./UiKitLinks";
import GroupLists from "./UiKitLists";
import GroupTable from "./UiKitTable";
import Tabs from "../Tabs";
import TabContent from "../TabContent";
import Button from "../Button";
import {AlertManager, withAlert} from 'react-alert';
import ApiContext from "../Context/ApiContext";
import Accordion from "../Accordion";

type uiKitState = {
  accordionOpen: boolean,
};
type uiKitProps = {
  alert: AlertManager
};

class UiKit extends React.Component<uiKitProps, uiKitState> {
  constructor(props: uiKitProps) {
    super(props);

    this.state = {
      accordionOpen: false,
    };

    this.onToggleAccordion=this.onToggleAccordion.bind(this);
    this.onShowAlertDefault=this.onShowAlertDefault.bind(this);
    this.onShowAlertLink=this.onShowAlertLink.bind(this);
    this.onNotFoundError=this.onNotFoundError.bind(this);
    this.onServerError=this.onServerError.bind(this);
  }

  onShowAlertDefault(e: React.MouseEvent) {
    e.preventDefault();
    this.props.alert.error('This is a test message. If you can read this, DKL is awesome!');
    this.props.alert.info('This is a test message. If you can read this, DKL is awesome!');
    this.props.alert.success('This is a test message. If you can read this, DKL is awesome!');
  }
  onShowAlertLink(e: React.MouseEvent) {
    e.preventDefault();
    this.props.alert.error((
      <div>
        This is a test with HTML &gt;&gt; <a href='https://www.google.de' target='_blank' rel="noopener noreferrer">Link</a>
      </div>
    ));
  }
  onToggleAccordion(e: React.MouseEvent) {
    e.preventDefault();
    this.setState({
      accordionOpen: !this.state.accordionOpen
    });
  }
  onNotFoundError(e: React.MouseEvent) {
    e.preventDefault();
    this.context.getAllUsersForCompany(-5);
  }
  onServerError(e: React.MouseEvent) {
    e.preventDefault();
    this.context.login();
  }

  render () {
    return (
      <div className="container" id="uikit">
        <div className="row">
          <div className="col-12 col-sm-4">
            <div className="section white-bg">
              <h1>Alert tests</h1>
              <Button type="button" onClick={this.onShowAlertDefault.bind(this)}>Alert test (info, error, success)</Button>
              <br/>
              <Button type="button" onClick={this.onShowAlertLink.bind(this)}>Alert test (link)</Button>
            </div>
          </div>

          <div className="col-12 col-sm-8">
            <div className="section white-bg">
              <h1>API error Tester</h1>
              <Button type="button" onClick={this.onNotFoundError.bind(this)}>404 (getAllUsersForCompany(-5))</Button>
              <Button type="button" onClick={this.onServerError.bind(this)}>500 (login(noUsername))</Button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6">
            <div className="section headlines">
              <GroupHeadlines/>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="section font-sizes">
              <GroupTypo/>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-sm-6">
            <div className="section white-bg headlines">
              <GroupHeadlines/>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="section white-bg font-sizes">
              <GroupTypo/>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-sm-6">
            <div className="section form-elements">
              <GroupForms/>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="section form-elements white-bg">
              <GroupForms/>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-sm-6">
            <div className="section links white-bg">
              <GroupLinks/>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="section links white-bg">
              <GroupLists/>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="section white-bg col-12">
            <GroupTable/>
          </div>
        </div>

        <div className="row">
          <div className="section white-bg col-12">
            <h1>Download Badges</h1>
            <Link to="#" className="download" title="Download">
              <div className="row">
                <div className="col-12 col-sm-auto download__entry__format">
                  <div className="format--pdf"></div>
                </div>
                <div className="col download__content__wrapper">
                  <div className="download__title">DATEINAME (DATEIGRÖSSE)</div>
                </div>
                <div className="col-12 col-sm-auto">
                  <div className="download__link"></div>
                </div>
              </div>
            </Link>
          </div>
        </div>

        <div className="row">
          <div className="section white-bg col-12">
            <h1>Tab-Navigation</h1>
            <div className="tab-navigation">
              <Tabs>
                <TabContent identifier="headlines" title="Headlines">
                  <GroupHeadlines/>
                </TabContent>
                <TabContent identifier="form-controls" title="Form Controls">
                  <GroupForms/>
                </TabContent>
                <TabContent identifier="typo" title="Typography">
                  <GroupTypo/>
                </TabContent>
              </Tabs>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="section col-12">
            <h1>Dashboard</h1>
            <div className="contentbox contentbox--has-loader">
              <div className="accordion" onClick={this.onToggleAccordion}>
                <div className="accordion__head">
                  <div className="accordion__tagline">22-10-19</div>
                  <div className="accordion__title">(new) 2019 Dietrich Harder Master’s Thesis Award for Yuri Simeonov
                  </div>
                  <div className="accordion__toggle accordion__toggle--plus"></div>
                  <div className="accordion__toggle accordion__toggle--minus"></div>
                </div>
                <Collapse className="collapse" collapsed={this.state.accordionOpen}>
                  <div className="accordion__body">
                    <p>Yuri Simeonov was awarded the 2019 “Dietrich Harder Master’s Thesis Award” at the 50th annual
                      meeting of the German Society for Medical Physics (DGMP)</p>
                    <p>in Stuttgart on 20 September, for his outstanding thesis in the field of medical radiation
                      physics.</p>
                    <p>In his thesis titled “3D range-modulator for scanned particle therapy: development, Monte
                      Carlo simulations and experimental evaluation”, Mr. Simeonov describes the design, development
                      and manufacturing of a 3D range-modulator for scanned particle therapy and investigates its
                      potential for clinical use.</p>
                    <p>The new <Link to="#">modulator allows to obtain a highly conformal dose</Link> distribution in
                      the target volume using one energy, thus reducing treatment time significantly.</p>
                  </div>
                </Collapse>
              </div>

              <div className="accordion" data-toggle="collapse"
                   role="button" aria-expanded="false" aria-controls="collapseExample1">
                <div className="accordion__head">
                  <div className="accordion__tagline">22-10-19</div>
                  <div className="accordion__title">2019 Dietrich Harder Master’s Thesis Award for Yuri Simeonov
                  </div>
                  <div className="accordion__toggle accordion__toggle--plus"></div>
                  <div className="accordion__toggle accordion__toggle--minus"></div>
                </div>
                <div className="collapse" id="collapseExample1">
                  <div className="accordion__body">
                    <p>Yuri Simeonov was awarded the 2019 “Dietrich Harder Master’s Thesis Award” at the 50th annual
                      meeting of the German Society for Medical Physics (DGMP)</p>
                    <p>in Stuttgart on 20 September, for his outstanding thesis in the field of medical radiation
                      physics.</p>
                    <p>In his thesis titled “3D range-modulator for scanned particle therapy: development, Monte
                      Carlo simulations and experimental evaluation”, Mr. Simeonov describes the design, development
                      and manufacturing of a 3D range-modulator for scanned particle therapy and investigates its
                      potential for clinical use.</p>
                    <p>The new <Link to="#">modulator allows to obtain a highly conformal dose</Link> distribution in
                      the target volume using one energy, thus reducing treatment time significantly.</p>
                  </div>
                </div>
              </div>

              <LoadingIndicator text="Mehr News anzeigen"/>

            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h1>Products</h1>
            <div className="contentbox">
              <div className="productbox">
                <div className="productbox__header">
                  <div className="header__left">
                    <div className="header__tagline">Buy date: 2019-05-02 Order No.: R192516</div>
                  </div>
                  <div className="header__right">
                    <div className="productbox__calibration-indicator productbox__calibration-indicator--green">
                      <span>Next action: 2019-10-18</span>
                    </div>
                    <div className="productbox__comment-indicator">(3)</div>
                    <div className="productbox__files-indicator">(2)</div>
                  </div>
                </div>
                <div className="productbox__body white-bg">
                  <div className="row">
                    <div className="productbox__title productbox__title--software col-12 col-sm-auto">
                      Semiflex 3D-Kammer 0,07 cm³
                    </div>
                    <div className="productbox__additionaltext col-12 col-sm">
                      Serial No. TN31021#142643
                    </div>
                    <div className="productbox__actions col-12 col-sm-auto">
                      <div className="productbox__request">
                        <Link to="#" className="button button--moreinv">Support request</Link>
                      </div>
                      <div className="productbox__detail">
                        <Link to="#" className="button">show details</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="productbox">
                <div className="productbox__header">
                  <div className="header__left">
                    <div className="header__tagline">Buy date: 2019-05-02 Order No.: R192516</div>
                  </div>
                  <div className="header__right">
                    <div className="productbox__calibration-indicator productbox__calibration-indicator">
                      <span>Next action: 2019-10-18</span>
                    </div>
                    <div className="productbox__comment-indicator">(3)</div>
                    <div className="productbox__files-indicator">(2)</div>
                  </div>
                </div>
                <div className="productbox__body white-bg">
                  <div className="row">
                    <div className="productbox__title productbox__title--gear col-12 col-sm-auto">
                      Semiflex 3D-Kammer 0,07 cm³
                    </div>
                    <div className="productbox__additionaltext col-12 col-sm">
                      Serial No. TN31021#142643
                    </div>
                    <div className="productbox__actions col-12 col-sm-auto">
                      <div className="productbox__request">
                        <Link to="#" className="button button--moreinv">support request</Link>
                      </div>
                      <div className="productbox__detail">
                        <Link to="#" className="button">show details</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section contentbox">
          <h1>Accordions</h1>
          <Accordion title="Accordion default">
            Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Maecenas nec odio et ante tincidunt tempus. Aliquam lobortis. Fusce pharetra convallis urna. Phasellus leo dolor, tempus non, auctor et, hendrerit quis, nisi.
            Vestibulum suscipit nulla quis orci. Phasellus accumsan cursus velit. Sed lectus. Etiam rhoncus. In consectetuer turpis ut velit.
          </Accordion>
          <Accordion title="Accordion FAQ" mode="faq">
            Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Maecenas nec odio et ante tincidunt tempus. Aliquam lobortis. Fusce pharetra convallis urna. Phasellus leo dolor, tempus non, auctor et, hendrerit quis, nisi.
            Vestibulum suscipit nulla quis orci. Phasellus accumsan cursus velit. Sed lectus. Etiam rhoncus. In consectetuer turpis ut velit.
          </Accordion>
          <Accordion title="Accordion slim" mode="slim">
            Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Maecenas nec odio et ante tincidunt tempus. Aliquam lobortis. Fusce pharetra convallis urna. Phasellus leo dolor, tempus non, auctor et, hendrerit quis, nisi.
            Vestibulum suscipit nulla quis orci. Phasellus accumsan cursus velit. Sed lectus. Etiam rhoncus. In consectetuer turpis ut velit.
          </Accordion>
        </div>

      </div>
    )
  }
}

UiKit.contextType = ApiContext;

export default withAlert()(UiKit);
